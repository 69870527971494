import { Component, Input, OnInit } from '@angular/core';
import { trigger, transition, useAnimation } from "@angular/animations";
import { fadeIn, fadeOut, } from "../../presentation.animations";
import { ConfigurationService } from 'src/app/services/configuration.service';
@Component({
  selector: 'app-initiatives-description',
  templateUrl: './initiatives-description.component.html',
  styleUrls: ['./initiatives-description.component.scss'],
  animations: [
    trigger('carouselAnimation', [
      transition("void => *", [useAnimation(fadeIn, { params: { time: '500ms' } })]),
      transition("* => void", [useAnimation(fadeOut, { params: { time: '500ms' } })]),
    ])
  ]
})
export class InitiativesDescriptionComponent implements OnInit {
  @Input() initiatives: any;
  public slides: any;
  public carouselInterval: number;
  public numbers: Array<number>;
  public num: number;
  public curr: number;
  private timerInterval: any;
  public loadingSlides: boolean;

  constructor(private config: ConfigurationService) {
    this.curr = 0;
    this.loadingSlides = true;
  }

  ngOnInit(): void {
    this.numbers = Array.from({ length: (this.initiatives || []).length }, (x, i) => i);
    var configuration = this.config.getConfiguration();
    this.carouselInterval = configuration.SlideTime / this.numbers.length;
    this.num = 0;
    this.nextSlide();
    this.setTimer();
    this.loadingSlides = false;
  }

  setTimer() {
    this.timerInterval = setInterval(() => { this.nextSlide() }, this.carouselInterval * 1000);
  }

  nextSlide() {
    if (this.curr === this.numbers.length) {
      this.curr = 0;
    }
    this.num = this.numbers[this.curr]
    this.curr++;
  }

  goToSlide(n: number): void {
    this.num = n;
    this.curr = this.numbers.indexOf(n) + 1;
    clearInterval(this.timerInterval);
    this.setTimer();
  }

  ngOnDestroy() {
    clearInterval(this.timerInterval);
  }

}
