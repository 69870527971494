<div class="challenges">
    <div *ngIf="section === 'list'">
        <div class="challenges_admin_header">
            <p class="challenges_admin_header-title">Retos</p>
            <div class="challenges_admin_header-add" (click)="goToNew()" *ngIf="hasRole('write')">
                <span>Subir reto</span>
            </div>
        </div>
        <div class="challenges_section">
            <app-admin-challenges-list></app-admin-challenges-list>
        </div>
    </div>
    <div *ngIf="section === 'add' || section === 'modify' || section === 'manage'" style="height: 100%;">
        <div class="challenges_admin_header">
            <p class="challenges_admin_header-title">
                <span class="challenges_admin_header-breadcrumb" (click)="backToList()">
                    Retos
                </span>
                <span class="challenges_admin_header-breadcrumb-slash"> | </span>
                <span *ngIf="section === 'add'">Nuevo Reto</span>
                <span *ngIf="section === 'modify'">Modificar Reto</span>
                <span *ngIf="section === 'manage' && challengeToManage"> {{challengeToManage.tittle}}</span>
            </p>
        </div>
        <div class="challenges_section" [ngClass]="{'active_section': section === 'add' || section === 'modify'}">
            <app-admin-challenges-crud *ngIf="section === 'add'"></app-admin-challenges-crud>
            <app-admin-challenges-crud *ngIf="section === 'modify'" [isModify]="true" [challenge]="challengeToModify">
            </app-admin-challenges-crud>
        </div>
        <div class="challenges_section" [ngClass]="{'active_section': section === 'manage'}">
            <loading *ngIf="loadingChallenge"></loading>
            <app-admin-challenges-manage [challenge]="challengeToManage"
                *ngIf="section === 'manage' && challengeToManage && !loadingChallenge"></app-admin-challenges-manage>
        </div>
    </div>
</div>