import { Component, OnInit } from '@angular/core';
import { trigger, transition, useAnimation } from "@angular/animations";
import { fadeIn, fadeOut, } from "./presentation.animations";
import { DataService } from './data.service'
import { ConfigurationService } from 'src/app/services/configuration.service';
import { EventBusService } from 'src/app/services/eventBus.service';
import { Router } from '@angular/router';

@Component({
  selector: 'presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.scss'],
  animations: [
    trigger('carouselAnimation', [
      transition("void => *", [useAnimation(fadeIn, { params: { time: '1000ms' } })]),
      transition("* => void", [useAnimation(fadeOut, { params: { time: '1000ms' } })]),
    ])
  ]
})
export class PresentationComponent implements OnInit {
  public slides: any;
  public carouselInterval: number;
  public reloadInterval: number;
  //public numbers: Array<number> = Array.from({ length: 6 }, (x, i) => i);
  public numbers: Array<number> = [0, 1, 2, 3];
  public num: number;
  public curr: number;
  private timerInterval: any;
  private refreshInterval: any;
  private transitionTime: number;
  public loadingSlides: boolean;

  constructor(private data: DataService, private config: ConfigurationService, private eventbus: EventBusService, private router: Router) {
    this.slides = {
      saving: null,
      energy: null,
    };
    var configuration = this.config.getConfiguration();
    this.carouselInterval = configuration.SlideTime;
    this.reloadInterval = configuration.LoadInfoSlideTime;
    this.transitionTime = 3000;
  }

  ngOnInit(): void {
    this.loadingSlides = true;
    this.curr = 0;
    this.num = this.numbers[this.curr];
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
    var reloadTime = this.reloadInterval * 60 * 60 * 1000;
    this.refreshInterval = setInterval(() => {
      this.ngOnDestroy();
      this.ngOnInit();
    }, reloadTime);
    this.data.getPresentationData().subscribe((res: any) => {
      this.slides = res;
      this.nextSlide();
      this.setTimer();
      this.loadingSlides = false;
    });
    function launchFullScreen(element) {
      if (element.requestFullScreen) {
        element.requestFullScreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullScreen) {
        element.webkitRequestFullScreen();
      }
    }
    // Launch fullscreen for browsers that support it!
    launchFullScreen(document.documentElement);
  }

  setTimer() {
    this.timerInterval = setInterval(() => { this.nextSlide() }, this.carouselInterval * 1000);
  }

  sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  nextSlide() {
    if (this.curr === this.numbers.length) {
      this.curr = 0;
    }
    if (this.timerInterval)
      this.eventbus.emit({ name: "nextSlide", value: this.numbers[this.curr] });
    this.num = null;
    this.sleep(!this.timerInterval ? 0 : this.transitionTime).then(() => {
      this.num = this.numbers[this.curr]
      this.curr++;
    });
  }

  goToSlide(n: number): void {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
    this.eventbus.emit({ name: "nextSlide", value: n });
    this.num = null;
    this.sleep(!this.timerInterval ? 0 : this.transitionTime).then(() => {
      this.num = n;
      this.curr = this.numbers.indexOf(n) + 1;
      this.setTimer();
    });
  }

  ngOnDestroy() {
    clearInterval(this.timerInterval);
    clearInterval(this.refreshInterval);
  }
}

