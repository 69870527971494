import { Component, OnInit } from '@angular/core';
import { DataService } from './data.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  body: any;
  texts: any;

  constructor(private router: Router, private data: DataService) {
    this.body = document.querySelectorAll("body")[0];
    this.texts = {
      tittle: '',
      description: '',
      question: '',
      button: ''
    };
  }

  ngOnInit(): void {
    this.data.getTextsFromSql().subscribe((res: any) => {
      this.texts = res
    });
    this.body.classList.add('no-media');
  }

  goToSenda(): void {
    this.router.navigate(['/website/senda']);
  }

  ngOnDestroy(): void {
    this.body.classList.remove('no-media');
  }
}
