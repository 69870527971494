import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-initiatives-countdown',
  templateUrl: './initiatives-countdown.component.html',
  styleUrls: ['./initiatives-countdown.component.scss']
})
export class InitiativesCountdownComponent implements OnInit {
  days: number;
  hours: number;
  minutes: number;
  dDisplay: string;
  hDisplay: string;
  mDisplay: string;
  startDate: Date;
  endDate: Date;
  nowDate: Date = new Date();
  diff: number;
  constructor() { }

  ngOnInit(): void {
    this.startDate = new Date(2020, 10, 20, 0, 0, 0);
    this.endDate = new Date(2020, 11, 25, 0, 0, 0);

    var difDates = this.endDate.getTime() - this.startDate.getTime();
    var difNow = this.nowDate.getTime() - this.startDate.getTime();

    var secondsDates = Math.round(difDates / 1000);
    var secondsNow = Math.round(difNow / 1000);
    var dhmDates = this.secondsToDhm(secondsDates);
    var dhmNow = this.secondsToDhm(secondsNow);

    this.days = dhmNow.days;
    this.hours = dhmNow.hours;
    this.minutes = dhmNow.minutes;
    this.dDisplay = dhmNow.days === 1 ? "día" : "días";
    this.hDisplay = dhmNow.hours === 1 ? "hora, " : "horas";
    this.mDisplay = dhmNow.minutes === 1 ? "minuto, " : "minutos";

    var daysValue = document.getElementById("daysPath");
    var hoursValue = document.getElementById("hoursPath");
    var minutesValue = document.getElementById("minutesPath");

    daysValue.setAttribute("stroke-dasharray", 100 - (dhmNow.days * 100 / dhmDates.days) + ', ' + 100);
    hoursValue.setAttribute("stroke-dasharray", 100 - (dhmNow.hours * 100 / 24) + ', ' + 100);
    minutesValue.setAttribute("stroke-dasharray", 100 - (dhmNow.minutes * 100 / 60) + ', ' + 100);
  }

  secondsToDhm(seconds) {
    seconds = Number(seconds);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);

    return { days: d, hours: h, minutes: m }
  }
}
