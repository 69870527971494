<div class="dashboard">
    <p class="dashboard-title">
        Dashboard
    </p>

    <div class="widget-content">
        <div class="widget-1" *ngIf="hasRole('Advices', 'read')">
            <app-admin-dashboard-widget [type]="'advices'" (click)="goToSection('advices')">
            </app-admin-dashboard-widget>
        </div>

        <div class="widget-2" *ngIf="hasRole('Challenges', 'read')">
            <app-admin-dashboard-widget [type]="'challenges'" (click)="goToSection('challenges')">
            </app-admin-dashboard-widget>
        </div>

        <div class="widget-3" *ngIf="hasRole('Initiatives', 'read')">
            <app-admin-dashboard-widget [type]="'initiatives'" (click)="goToSection('initiatives')">
            </app-admin-dashboard-widget>
        </div>

        <div class="widget-4" *ngIf="hasRole('Users', 'read')">
            <app-admin-dashboard-widget [type]="'users'" (click)="goToSection('users')">
            </app-admin-dashboard-widget>
        </div>

        <div class="widget-5" *ngIf="hasRole('School', 'read')">
            <app-admin-dashboard-widget [type]="'school'" (click)="goToSection('school')">
            </app-admin-dashboard-widget>
        </div>

        <div class="widget-6" *ngIf="hasRole('Class', 'read')">
            <app-admin-dashboard-widget [type]="'class'" (click)="goToSection('class')">
            </app-admin-dashboard-widget>
        </div>

        <div class="widget-7" *ngIf="hasRole('Equivalence', 'read')">
            <app-admin-dashboard-widget [type]="'equivalence'" (click)="goToSection('equivalence')">
            </app-admin-dashboard-widget>
        </div>
    </div>
</div>