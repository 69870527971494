<div class="evalutation-content-body">
    <div *ngIf="evaluationPage === 0" class="slide">
        <div class="page-1">
            <div class="evalutation-section-title">
                <p class="evalutation-section-title">En relación al contenido</p>
            </div>

            <div class="quiz">
                <div class="question">
                    <div class="question-label">
                        <p>Los contenidos facilitados me han parecido claros</p>
                    </div>

                    <div class="points-box">
                        <div class="point" *ngFor="let num of
                        [1, 2, 3, 4, 5]" (click)="setValue(0, num)">
                            <div [ngClass]="{'selected': form.answers[0] >= num}"></div>
                            <span>{{num}}</span>
                        </div>
                    </div>
                    <div class="indicators">
                        <span>Muy deficiente</span>
                        <span>Excelente</span>
                    </div>
                </div>

                <div class="question">
                    <div class="question-label">
                        <p>El material de apoyo me ha sido de utilidad</p>
                    </div>
                    <div class="points-box">
                        <div class="point" *ngFor="let num of
                        [1, 2, 3, 4, 5]" (click)="setValue(1, num)">
                            <div [ngClass]="{'selected': form.answers[1] >= num}"></div>
                            <span>{{num}}</span>
                        </div>
                    </div>
                    <div class="indicators">
                        <span>Muy deficiente</span>
                        <span>Excelente</span>
                    </div>
                </div>

                <div class="question">
                    <div class="question-label">
                        <p>Me ha resultado una dinámica divertida y diferente</p>
                    </div>
                    <div class="points-box">
                        <div class="point" *ngFor="let num of
                        [1, 2, 3, 4, 5]" (click)="setValue(2, num)">
                            <div [ngClass]="{'selected': form.answers[2] >= num}"></div>
                            <span>{{num}}</span>
                        </div>
                    </div>
                    <div class="indicators">
                        <span>Muy deficiente</span>
                        <span>Excelente</span>
                    </div>
                </div>

                <div class="question">
                    <div class="question-label">
                        <p>En general el contenido se adapta al nivel de la clase</p>
                    </div>
                    <div class="points-box">
                        <div class="point" *ngFor="let num of
                        [1, 2, 3, 4, 5]" (click)="setValue(3, num)">
                            <div [ngClass]="{'selected': form.answers[3] >= num}"></div>
                            <span>{{num}}</span>
                        </div>
                    </div>
                    <div class="indicators">
                        <span>Muy deficiente</span>
                        <span>Excelente</span>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div *ngIf="evaluationPage === 1" class="slide">
        <div class="page-2">
            <div class="evalutation-section-title">
                <p class="evalutation-section-title">En relación con el alumno</p>
            </div>

            <div class="quiz">
                <div class="question">
                    <div class="question-label">
                        <p>Participación del grupo en el debate post video</p>
                    </div>
                    <div class="points-box">
                        <div class="point" *ngFor="let num of
                        [1, 2, 3, 4, 5]" (click)="setValue(4, num)">
                            <div [ngClass]="{'selected': form.answers[4] >= num}"></div>
                            <span>{{num}}</span>
                        </div>
                    </div>
                    <div class="indicators">
                        <span>Muy deficiente</span>
                        <span>Excelente</span>
                    </div>
                </div>

                <div class="question">
                    <div class="question-label">
                        <p>Grado de compromiso con los hábitos sugeridos</p>
                    </div>
                    <div class="points-box">
                        <div class="point" *ngFor="let num of
                        [1, 2, 3, 4, 5]" (click)="setValue(5, num)">
                            <div [ngClass]="{'selected': form.answers[5] >= num}"></div>
                            <span>{{num}}</span>
                        </div>
                    </div>
                    <div class="indicators">
                        <span>Muy deficiente</span>
                        <span>Excelente</span>
                    </div>
                </div>

                <div class="question">
                    <div class="question-label">
                        <p>Interés de la clase por el tema tratado</p>
                    </div>
                    <div class="points-box">
                        <div class="point" *ngFor="let num of
                        [1, 2, 3, 4, 5]" (click)="setValue(6, num)">
                            <div [ngClass]="{'selected': form.answers[6] >= num}"></div>
                            <span>{{num}}</span>
                        </div>
                    </div>
                    <div class="indicators">
                        <span>Muy deficiente</span>
                        <span>Excelente</span>
                    </div>
                </div>

                <div class="question">
                    <div class="question-label">
                        <p>¿Cómo de motivados han estado los niños a lo largo del mes?</p>
                    </div>
                    <div class="points-box">
                        <div class="point" *ngFor="let num of
                        [1, 2, 3, 4, 5]" (click)="setValue(7, num)">
                            <div [ngClass]="{'selected': form.answers[7] >= num}"></div>
                            <span>{{num}}</span>
                        </div>
                    </div>
                    <div class="indicators">
                        <span>Muy deficiente</span>
                        <span>Excelente</span>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div *ngIf="evaluationPage === 2" class="slide">
        <div class="page-3">
            <div class="evalutation-section-title">
                <p class="evalutation-section-title">En relación con el alumno</p>
            </div>

            <div class="quiz">
                <div class="observation">
                    <div class="observation-label">
                        <p>Observaciones adicionales</p>
                    </div>

                    <div class="text-area">
                        <textarea [(ngModel)]="form.answers[8]" name="description" placeholder="Describir aquí..."
                            maxlength="300"></textarea>
                    </div>
                </div>

                <div class="observation">
                    <div class="observation-label">
                        <p>Adjuntar trabajos</p>
                    </div>

                    <div class="upload-box">
                        <div class="upload-box-icon">
                            <input type="file" (change)="uploadFile($event)" />
                            <i class="material-icons">highlight_alt</i>
                            <span>Seleccionar archivo</span>
                        </div>
                        <div class="upload-box-file">
                            <div class="file-row" *ngFor="let pdf of form.archives;let i=index">
                                <p *ngIf="pdf.archiveId !== 0"><a href="{{pdf.archive}}"
                                        target="_blank">{{pdf.name}}</a></p>
                                <p *ngIf="pdf.archiveId === 0">{{pdf.name}}</p>
                                <i class="material-icons delete" (click)="deletePdfFile(i)">delete</i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="save-button">
                    <div class="save-button-box" (click)="saveForm()" [ngClass]="{'disabled': savingForm}">
                        <loading *ngIf="savingForm" [isButton]="true"></loading>
                        <p>Guardar</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="evalutation-content-footer">
    <span><span (click)="prevEvaluationPage()" *ngIf="evaluationPage !== 0">
            <i class="material-icons">keyboard_arrow_left</i> Anterior </span></span>
    <div class="evalutation-content-pages">
        <span (click)="goToEvaluationPage(0)" [ngClass]="{'selectedEvaluationPage': evaluationPage === 0}">1</span>
        <span (click)="goToEvaluationPage(1)" [ngClass]="{'selectedEvaluationPage': evaluationPage === 1}">2</span>
        <span (click)="goToEvaluationPage(2)" [ngClass]="{'selectedEvaluationPage': evaluationPage === 2}">3</span>

    </div>
    <span><span *ngIf="evaluationPage < 2" (click)="nextEvaluationPage()">
            Siguiente <i class="material-icons">keyboard_arrow_right</i></span>
    </span>
</div>