import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment}  from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private url: string = "api/Presentation/GetPresentationLoadInfo";
  constructor(private http: HttpClient) { }

  getPresentationData(): any {
    return this.http.get(environment.api + this.url);
  }
}
