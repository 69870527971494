import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from '../components/login/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authService.currentUserValue;
        if (currentUser) {
            if (!route.data.role) {
                return true;
            }
            else {
                var dict = currentUser.permissionsDictionary || {};
                if (route.data.role === 'admin') {
                    if (this.authService.adminRoles.includes((currentUser || {}).rolId + "")) {
                        return true;
                    } else {
                        this.router.navigate(['/']);
                        return false;
                    }
                } else {
                    if ((dict[route.params.section.charAt(0).toUpperCase() + route.params.section.slice(1)] || []).includes(route.data.role)) {
                        return true;
                    } else {
                        this.router.navigate(['/']);
                        return false;
                    }
                }
            }
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}