<loading *ngIf="loading"></loading>
<div *ngIf="!loading" class="equivalences-crud">
    <p class="title-crud" *ngIf="!isModify">Añadir nueva equivalencia</p>
    <p class="title-crud" *ngIf="isModify">Modificar equivalencia #{{equivalence.equivalenceId}}</p>
    <div class="equivalences-form">
        <div class="container">
            <div class="left-box">
                <p class="label-text">Nombre del objeto <span>(*obligatorio)</span><br>
                    <span class="subtitle">Indique el nombre de un objeto para realizar su equivalencia</span>
                </p>
                <div class="input-equivalence">
                    <div class="input-equivalence-icon">
                        <i class="material-icons">
                            published_with_changes</i>
                    </div>
                    <div class="input-equivalence-text">
                        <input [(ngModel)]="tittle" type="text" autocomplete="off"
                            placeholder="Escriba aquí el nombre del objeto... " maxlength="80" />
                    </div>
                </div>
                <p class="label-text">Descripción de la equivalencia <span>(*obligatorio)</span> <br>
                    <span class="subtitle">Describa la equivalencia con el objeto e introduce el número usando el
                        comando &#x0005B;&#x0007B;keynum&#x0007D;&#x0005D; en el texto </span>
                </p>
                <div class="input-description">
                    <textarea [(ngModel)]="description" name="description"
                        placeholder="Haga una descripción para que los usuarios puedan entender de qué se trata..."
                        maxlength="300"></textarea>
                </div>
            </div>
            <div class="right-box">
                <p class="label-text">Número de equivalencia del objeto <span>(*obligatorio)</span><br><span
                        class="subtitle">Indique a qué equivale una unidad del objeto</span></p>
                <div class="input-equivalence">
                    <div class="input-equivalence-icon">
                        <i>nº</i>
                    </div>
                    <div class="input-equivalence-text">
                        <input [(ngModel)]="unitPower" type="text" autocomplete="off"
                            placeholder="Introduzca aquí el valor..." maxlength="80" />
                    </div>
                </div>
                <p class="label-icon">Imagen del objeto <span>(*obligatorio)</span><br><span class="subtitle">Cargue la
                        imagen correspondiente al objeto</span></p>
                <div class="img-space">
                    <ng-container *ngIf="isImageSaved; else elseTemplate">
                        <img [src]="icon" />
                    </ng-container>
                    <ng-template #elseTemplate>
                        <div class="input-icon">
                            <input type="file" (change)="uploadIcon($event)" />
                            <i class="material-icons">highlight_alt</i>
                            <span>Seleccionar imagen</span>
                        </div>
                    </ng-template>
                    <a class="btn-delete" (click)="removeImage()" *ngIf="isImageSaved">Eliminar imagen</a>
                    <span class="error-msg">{{imageError}}</span>
                </div>
            </div>
        </div>

        <div class="add-equivalences"
            [ngClass]="{'disabled': !tittle || !description || !unitPower || !isImageSaved || savingEquivalence}">
            <div *ngIf="!isModify" class="submit" (click)="createEquivalence()">
                <loading *ngIf="savingEquivalence" [isButton]="true"></loading>
                <p class="text-button">Añadir equivalencia</p>
            </div>
            <div *ngIf="isModify" class="submit" (click)="modifyEquivalence()">
                <loading *ngIf="savingEquivalence" [isButton]="true"></loading>
                <p class="text-button">Modificar equivalencia</p>
            </div>
        </div>
    </div>
</div>