import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/components/login/authentication.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  @Input() isPresentation: boolean;
  @Input() name: string;
  @Input() icon: string;
  constructor(private router: Router, private authenticationService: AuthService) { }

  ngOnInit(): void { }

  goToAdmin(): void {
    this.router.navigate(['/admin']);
  }
}
