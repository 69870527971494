import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'weather-next-week',
  templateUrl: './weather-next-week.component.html',
  styleUrls: ['./weather-next-week.component.scss']
})
export class WeatherNextWeekComponent implements OnInit {
  @Input() nextWeek: any;
  imgarray : Array<string>;
  constructor() {
    this.imgarray = [
      "../../../../../assets/weather-icons/022-sun.svg",
      "../../../../../assets/weather-icons/001-cloudy.svg",
      "../../../../../assets/weather-icons/049-rain.svg",
      "../../../../../assets/weather-icons/004-storm.svg",
      "../../../../../assets/weather-icons/036-snowflake.svg",
      "../../../../../assets/weather-icons/017-wind.svg"
    ]
  }

  ngOnInit(): void {
  }

}
