<div class="landing">
    <app-header></app-header>
    <div class="landing_content">
        <p class="landing_tittle">
            {{texts.tittle}}
        </p>
        <p class="description">
            {{texts.description}}
        </p>
        <span class="question">
            {{texts.question}}
        </span>
        <div class="start_button" (click)="goToSenda()">
            <span>
                {{texts.button}}
            </span>
        </div>
    </div>
</div>