import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EventBusService } from 'src/app/services/eventBus.service';
import { DataService } from '../data.service';

@Component({
  selector: 'app-admin-schools-list',
  templateUrl: './admin-schools-list.component.html',
  styleUrls: ['./admin-schools-list.component.scss']
})
export class AdminSchoolsListComponent implements OnInit {
  public schools: Array<any>;
  public paginatedSchools: Array<any>;
  public loading: boolean;
  listeners: Array<any> = [];

  constructor(private data: DataService, private eventbus: EventBusService, private toastr: ToastrService) {
    this.loading = true;
    this.schools = undefined;
  }

  ngOnInit(): void {
    this.getSchools();
    this.listeners.push(this.eventbus.on("deleteschoolEmitter", (data) => {
      this.deleteSchool(data);
    }));
    this.listeners.push(this.eventbus.on("deleteSelectedschoolEmitter", (data) => {
      var ids = data.map(a => a.schoolId);
      this.deleteSchools(ids);
    }));
  }

  deleteSchool(school): void {
    this.data.deleteSchool(school.schoolId).subscribe((res: any) => {
      this.toastr.success('Clase eliminada correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
      this.getSchools();
    },
      (error) => {
        this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' })
      });
  }

  deleteSchools(schools): void {
    this.data.deleteSchools(schools).subscribe((res: any) => {
      this.toastr.success('Clases eliminadas correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
      this.getSchools();
    },
      (error) => {
        this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' })
      });
  }

  getSchools(): void {
    this.data.getSchoolsList().subscribe((res: any) => {
      this.schools = res;
      this.loading = false;
    },
      (error) => {
        this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' })
      });
  }

  ngOnDestroy() {
    this.listeners.forEach(x => x.unsubscribe());
  }
}
