<div class="piggy-bank" *ngIf="isPresentation || isWidget" [ngClass]="{'componentInWidget': isWidget}">
    <div class="piggy-container">
        <svg viewBox="0 0 512 399.69">
            <defs>
                <linearGradient [attr.id]="isWidget ? 'grad1' : 'grad2'" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" style="stop-color:#D897A8;stop-opacity:1" />
                    <stop [attr.offset]="100 - piggyBank.percentage + '%'" style="stop-color:#D897A8;stop-opacity:1" />
                    <stop [attr.offset]="100 - piggyBank.percentage + '%'" style="stop-color:#fff;stop-opacity:1" />
                    <stop offset="100%" style="stop-color:#fff;stop-opacity:1" />
                </linearGradient>
            </defs>
            <g id="Capa_1" data-name="Capa 1">
                <path
                    d="M510.72,168.85a9.46,9.46,0,0,0-9.72-9.2,9.72,9.72,0,0,0-2,.27c-8.56,1.51-16.66,5.29-25.06,7.43a178.7,178.7,0,0,0-52.37-112c-4.9-19.59-15.87-36.53-27.35-53.09A5.94,5.94,0,0,0,387.1.52C378,5.4,368.75,10.28,360,15.84A185.59,185.59,0,0,0,312,4.66c-45-4.38-86.4-3.17-125.9,19.11C125.2,58.1,79.12,123.38,83.61,194.09c-9.08,4.34-19.58,5.88-27.07-2A50.14,50.14,0,0,1,52,186.42c9.12-7.83,15.69-24.54,1-27.92a14,14,0,0,0-16.62,10.69,1.16,1.16,0,0,0,0,.18A21.79,21.79,0,0,0,38,182.25a27.69,27.69,0,0,1-5.45-.41C18,179.31,14,167.31,4.5,158.41a2.56,2.56,0,0,0-3.62.05,2.48,2.48,0,0,0-.62,1.06c-1.86,10,6.67,19.9,14.44,25.19a37.49,37.49,0,0,0,28.16,6.07c.46.65.93,1.28,1.4,1.88,11.22,14.43,26.23,13.91,39.77,6.48a151,151,0,0,0,2.85,18.18c8.71,39.11,31.63,76.7,65.07,99.35,1.82,1.24,3.67,2.41,5.53,3.58a9.94,9.94,0,0,0,.27,1.72c2.95,11.44,7.68,23,6.69,35-.73,8.75-2.66,19.22,0,27.81,5.46,17.46,28,15.71,42.44,13.7a105.25,105.25,0,0,0,25.1-6.37c4.53-1.82,9.52-3.54,11.88-8.2,3.4-6.73,2.83-17.14,3.61-24.41.33-3.07.71-6.14,1.11-9.21q7.49.87,15,1.34a305.6,305.6,0,0,0,44.16-.43,42.74,42.74,0,0,1-.06,5.77c-.73,8.74-2.66,19.22,0,27.81,5.46,17.45,28,15.71,42.43,13.7a106.17,106.17,0,0,0,25.11-6.37c4.53-1.82,9.52-3.54,11.88-8.2,3.4-6.73,2.83-17.15,3.61-24.41,1.15-10.69,2.78-21.35,4-32,27.4-14.44,50.33-35.9,63.89-66.29.67-1.51,1.31-3,1.94-4.53,17.53,4.1,35.26.17,43.59-19.55C513.17,215.74,512.92,191.55,510.72,168.85ZM47.83,179.11c-1.6-3.84-2-7.56.48-9.73,5.77-5,3.52,4.76,1.9,7.17A11.58,11.58,0,0,1,47.83,179.11Z"
                    [attr.fill]='isWidget ? "url(#grad1)" : "url(#grad2)"' />
            </g>
            <g id="Capa_2" data-name="Capa 2">
                <path
                    d="M435.2,118.8c-6.52-1.26-15.29,1.94-20.37,14.65l10.31,4.13c2.09-5.23,5.3-8.39,8-7.87s5.4,4.58,5.4,11.36h11.11C449.61,129.41,443.82,120.46,435.2,118.8Z"
                    style="fill:#c58193" />
                <path id="Trazado_114" data-name="Trazado 114"
                    d="M494.94,186.59c-3.67,11.4-3.84,23.58-7.89,35-2.75,7.74,10.91,10.8,11.76,1.59,1.1-11.88,5.22-24.79,1.78-36.58a3,3,0,0,0-5.65,0Z"
                    style="fill:#c58193" />
                <path id="Trazado_115" data-name="Trazado 115"
                    d="M310.06,49.07l-3.92-17.8s-58.32-19.81-97.92,9l1,14.82A107.51,107.51,0,0,1,310.06,49.07Z"
                    style="fill:#c58193" />
            </g>
        </svg>
        <div class="piggy-content">
            <p class="piggy-title">{{!isWidget ? "Nuestra hucha" : "Hucha virtual"}}</p>
            <p class="piggy-money">{{piggyBank.amount}}<span>,{{piggyBank.cents}}€</span></p>
        </div>
    </div>
</div>