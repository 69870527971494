<loading *ngIf="loading"></loading>
<div *ngIf="!loading" class="advices-crud">
    <p class="title-crud" *ngIf="!isModify">Subir nuevo consejo</p>
    <p class="title-crud" *ngIf="isModify">Modificar consejo #{{advice.id}}</p>
    <div class="advices-form">
        <p class="label-name">Nombre del consejo <span>(*obligatorio)</span>
        </p>
        <div class="input-name">
            <div class="input-name-icon">
                <i class="material-icons">article</i>
            </div>
            <div class="input-name-text">
                <input [(ngModel)]="tittle" type="text" name="tittle" autocomplete="off"
                    placeholder="Escriba aquí el nombre del consejo..." maxlength="80" />
            </div>
        </div>
        <p class="label-description">Descripción <span>(*obligatorio)</span></p>
        <div class="input-description">
            <textarea [(ngModel)]="description" name="description"
                placeholder="Haga una descripción para que los usuarios puedan entender de qué se trata..."
                maxlength="300"></textarea>
        </div>
        <p class="label-icon">Icono <span>(*obligatorio)</span></p>
        <div class="img-space">
            <ng-container *ngIf="isImageSaved; else elseTemplate">
                <img [src]="icon" />
            </ng-container>
            <ng-template #elseTemplate>
                <div class="input-icon">
                    <input type="file" (change)="uploadIcon($event)" />
                    <i class="material-icons">highlight_alt</i>
                    <span>Seleccionar icono</span>
                </div>
            </ng-template>
            <a class="btn-delete" (click)="removeImage()" *ngIf="isImageSaved">Eliminar icono</a>
            <span class="error-msg">{{imageError}}</span>
        </div>
        <div class="add-advices" [ngClass]="{'disabled': !tittle || !description || !isImageSaved || savingAdvice}">
            <div *ngIf="!isModify" class="submit" (click)="createAdvice()">
                <loading *ngIf="savingAdvice" [isButton]="true"></loading>
                <p class="text-button">Subir consejo</p>
            </div>
            <div *ngIf="isModify" class="submit" (click)="modifyAdvice()">
                <loading *ngIf="savingAdvice" [isButton]="true"></loading>
                <p class="text-button">Modificar consejo</p>
            </div>
        </div>
    </div>
</div>