import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from 'src/app/services/eventBus.service';
import { DataService } from '../data.service';
import * as _ from 'lodash';
import { ImagesService } from 'src/app/services/images.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-users-crud',
  templateUrl: './admin-users-crud.component.html',
  styleUrls: ['./admin-users-crud.component.scss']
})
export class AdminUsersCrudComponent implements OnInit {
  @Input() user: any;
  @Input() isModify: boolean;
  loading: boolean = true;
  roles: Array<any> = [];
  schools: Array<any> = [];
  lastName: string;
  userName: string;
  login: string;
  password: string;
  email: string;
  keyIdentity: string;
  school: any;
  rol: any;
  children: number;
  savingUser: boolean = false;
  constructor(private data: DataService, private eventbus: EventBusService, private images: ImagesService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.data.getSchools().subscribe((res: any) => {
      this.schools = res;
      if (this.user) {
        this.school = this.schools.filter(a => a.schoolId === this.user.schoolId)[0];
      }
    },
      (error) => {
        this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' })
      });
    this.data.getRoles().subscribe((res: any) => {
      this.roles = res;
      if (this.user) {
        this.rol = this.roles.filter(a => a.rolId === this.user.rolId)[0];
      }
    },
      (error) => {
        this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' })
      });
    if (!this.isModify) {
      this.loading = false;
    }
    if (this.user) {
      this.lastName = this.user.lastName;
      this.userName = this.user.userName;
      this.login = this.user.login;
      this.password = this.user.password;
      this.email = this.user.email;
      this.keyIdentity = this.user.keyIdentity;
      this.school = this.user.school;
      this.rol = this.user.rol;
      this.children = this.user.children;
    }
  }

  ngOnChanges(changes) {
    if (((changes || {}).user || {}).currentValue) {
      this.lastName = changes.user.currentValue.lastName;
      this.userName = changes.user.currentValue.userName;
      this.login = changes.user.currentValue.login;
      this.password = changes.user.currentValue.password;
      this.email = changes.user.currentValue.email;
      if (this.schools.length) {
        this.school = this.schools.filter(a => a.schoolId === changes.user.currentValue.schoolId)[0];
      }
      this.keyIdentity = changes.user.currentValue.keyIdentity;
      if (this.roles.length) {
        this.rol = this.roles.filter(a => a.rolId === changes.user.currentValue.rolId)[0];
      }
      this.children = changes.user.currentValue.children;
      this.loading = false;
    }
  }

  createUser(): void {
    if (!(!this.userName || !this.login || !this.password || !this.rol || !this.email || this.savingUser ||
      (this.rol && this.rol.rolId == '3' && !this.keyIdentity) ||
      (this.rol && this.rol.rolId == '4' && !this.children && this.children != 0) ||
      (this.rol && (this.rol.rolId == '2' || this.rol.rolId == '4') && !this.school))
    ) {
      this.savingUser = true;
      this.data.createUser(this.lastName, this.userName, this.login, this.password, this.email, this.school, this.rol, this.children, this.keyIdentity).subscribe(
        (response) => {
          this.toastr.success('La clase se ha creado correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
          this.savingUser = false;
          this.backAndReloadUsersList();
        },
        (error) => {
          this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' });
          this.savingUser = false;
        });
    }
  }

  modifyUser(): void {
    if (!(!this.userName || !this.login || !this.password || !this.rol || !this.email || this.savingUser ||
      (this.rol && this.rol.rolId == '3' && !this.keyIdentity) ||
      (this.rol && this.rol.rolId == '4' && !this.children && this.children != 0) ||
      (this.rol && (this.rol.rolId == '2' || this.rol.rolId == '4') && !this.school))
    ) {
      this.savingUser = true;
      this.user.lastName = this.lastName;
      this.user.userName = this.userName;
      this.user.login = this.login;
      this.user.password = this.password;
      this.user.email = this.email;
      if (this.school) {
        this.user.school = this.school.name;
        this.user.schoolId = this.school.schoolId;
      }
      this.user.rol = this.rol.rolDesc;
      this.user.rolId = this.rol.rolId;
      this.user.children = this.children;
      this.user.keyIdentity = this.keyIdentity;
      this.data.modifyUser(this.user).subscribe((res: any) => {
        this.toastr.success('La clase se ha modificado correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
        this.savingUser = false;
        this.backAndReloadUsersList();
      },
        (error) => {
          this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' });
          this.savingUser = false;
        });
    }
  }

  backAndReloadUsersList(): void {
    this.eventbus.emit({ name: "backAndReloadUsersList", value: "list" })
  }
}
