import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PdfService } from 'src/app/services/pdf.service';
import { DataService } from '../data.service';

@Component({
  selector: 'app-admin-challenges-manage',
  templateUrl: './admin-challenges-manage.component.html',
  styleUrls: ['./admin-challenges-manage.component.scss']
})
export class AdminChallengesManageComponent implements OnInit {
  @Input() challenge: any;
  challenges: any;
  percentage: number = 0;
  sliderEnabled: boolean = true;
  loadingChallenges: boolean;
  loadingForm: boolean;
  classes: Array<any>;
  form: any;
  dropdownClassOpened: boolean;
  currentClass: any;

  constructor(private data: DataService, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.loadingChallenges = true;
    this.loadingForm = true;
    this.data.getClasses().subscribe((res: any) => {
      this.classes = res;
      this.currentClass = res[0];
      this.getForm((res[0] || {}).classId);
    });
    this.data.getChallengesList().subscribe((res: any) => {
      this.challenges = res;
      this.loadingChallenges = false;
    });
    // Add the listener:
    window.addEventListener('click', this.onClickListener, false);
    this.router.events.subscribe((val) => {
      // Remove it:
      window.removeEventListener('click', this.onClickListener, false);
      window.addEventListener('click', this.onClickListener, false);
    });
  }

  onClickListener = (e) => {
    var buttonProfile = document.getElementsByClassName('evalutation-header-selector-value')[0];
    var buttonSpanSelector = document.getElementsByClassName('evalutation-header-selector-value-name')[0];
    var buttonISelector = document.getElementsByClassName('evalutation-header-selector-value-i')[0];
    var dropdownSelector = document.getElementsByClassName('class-dropdown')[0];
    var dropdownElementSelector = document.getElementsByClassName('class-dropdown-element');
    if (
      e.target != buttonProfile &&
      e.target != buttonSpanSelector &&
      e.target != buttonISelector &&
      e.target != dropdownSelector &&
      e.target != dropdownElementSelector) {
      this.dropdownClassOpened = false;
    }
  }

  getForm(clase) {
    this.data.getForm(clase, this.challenge.challengeId).subscribe(
      (res: any) => {
        this.form = res;
        this.percentage = this.form.percentage;
        this.loadingForm = false;
      }, (error) => {
        this.loadingForm = false;
      });
  }

  loadClassData(clase: any): void {
    this.loadingForm = true;
    this.dropdownClassOpened = false;
    if (clase.classId === (this.currentClass || {}).classId) {
      this.loadingForm = false;
      return;
    }
    this.currentClass = clase;
    this.getForm(this.currentClass.classId);
  }

  switchClassDropdown(): void {
    this.dropdownClassOpened = !this.dropdownClassOpened;
  }

  sliderValueChanged(ev): void {
    this.percentage = ev;
  }

  sliderValueSet(ev): void {
    this.sliderEnabled = false;
    this.data.updatePercentage(this.currentClass.classId, this.challenge.challengeId, ev).subscribe(
      (res: any) => {
        this.sliderEnabled = true;
        this.toastr.success('Porcentaje actualizado correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
      }, (error) => {
        this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' })
      });
  }
}
