import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from 'src/app/services/eventBus.service';
import { DataService } from '../data.service';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-classes-crud',
  templateUrl: './admin-classes-crud.component.html',
  styleUrls: ['./admin-classes-crud.component.scss']
})
export class AdminClassesCrudComponent implements OnInit {
  @Input() classe: any;
  @Input() isModify: boolean;
  loading: boolean = true;
  schools: Array<any> = [];
  school: any;
  course: string;
  class: string;
  savingClass: boolean = false;
  constructor(private data: DataService, private eventbus: EventBusService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.data.getSchools().subscribe((res: any) => {
      this.schools = res;
      if (this.classe) {
        this.school = this.schools.filter(a => a.schoolId === this.classe.schoolId)[0];
      }
    },
      (error) => {
        this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' })
      });
    if (!this.isModify) {
      this.loading = false;
    }
  }

  ngOnChanges(changes) {
    if (((changes || {}).classe || {}).currentValue) {
      if (this.schools.length) {
        this.school = this.schools.filter(a => a.schoolId === changes.classe.currentValue.schoolId)[0];
      }
      this.course = changes.classe.currentValue.course;
      this.class = changes.classe.currentValue.class;
      this.loading = false;
    }
  }

  createClass(): void {
    if (this.school && this.course && !this.savingClass) {
      this.savingClass = true;
      this.data.createClass(this.school.schoolId, this.course, this.class).subscribe(
        (response) => {
          this.toastr.success('La clase se ha creado correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
          this.savingClass = false;
          this.backAndReloadClassesList();
        },
        (error) => {
          this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' });
          this.savingClass = false;
        });
    }
  }

  modifyClass(): void {
    if (this.school && this.course && !this.savingClass) {
      this.savingClass = true;
      this.classe.school = this.school.name;
      this.classe.schoolId = this.school.schoolId;
      this.classe.course = this.course;
      this.classe.class = this.class;
      this.data.modifyClass(this.classe).subscribe((res: any) => {
        this.toastr.success('La clase se ha modificado correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
        this.savingClass = false;
        this.backAndReloadClassesList();
      },
        (error) => {
          this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' });
          this.savingClass = false;
        });
    }
  }

  backAndReloadClassesList(): void {
    this.eventbus.emit({ name: "backAndReloadClassesList", value: "list" })
  }
}
