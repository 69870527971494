import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { environment}  from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private urlGet: string = "api/ClassSchool/getclass";
  private urlList: string = "api/ClassSchool/getclasslist";
  private urlCreate: string = "api/ClassSchool/createclass";
  private urlUpdate: string = "api/ClassSchool/updateclass";
  private urlDelete: string = "api/ClassSchool/deleteclass";
  private urlDeleteMultiple: string = "api/ClassSchool/deleteclasslist";
  private schoolsUrl: string = "api/ClassSchool/getschoollist";
  constructor(private http: HttpClient, private config: ConfigurationService) { }

  getSchools(): any {
    var configuration = this.config.getConfiguration();
    return this.http.get(environment.api + this.schoolsUrl + "?groupIdentity=" + configuration.group);
  }

  getClass(id): any {
    return this.http.get(environment.api + this.urlGet + '?classId=' + id);
  }

  getClassesList(): any {
    return this.http.get(environment.api + this.urlList);
  }

  createClass(school, course, classe): any {
    return this.http.post(environment.api + this.urlCreate,
      {
        "schoolId": school,
        "course": course,
        "class": classe
      }
    )
  }

  modifyClass(classe): any {
    return this.http.post(environment.api + this.urlUpdate,
      classe
    )
  }

  deleteClass(id: string): any {
    return this.http.post(environment.api + this.urlDelete + "?classid=" + id,
      {}
    )
  }

  deleteClasses(ids: Array<string>): any {
    var deleteString = "";
    ids.forEach(id => { deleteString += (!deleteString ? "?" : "&") + "classid=" + id });
    return this.http.post(environment.api + this.urlDeleteMultiple + deleteString,
      {}
    )
  }
}
