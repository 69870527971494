import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from 'src/app/services/eventBus.service';

@Component({
  selector: 'challenges-board',
  templateUrl: './challenges-board.component.html',
  styleUrls: ['./challenges-board.component.scss']
})
export class ChallengesBoardComponent implements OnInit {
  @Input() challenges: any;
  @Input() isPresentation: boolean;
  @Input() isAdmin: boolean;
  constructor(private eventbus: EventBusService) { }

  ngOnInit(): void {  }

  showChallengeModal(id: number): void {
    this.eventbus.emit({ name: "showChallengeModal", value: id });
  }

  changeChallenge(challenge: number): void {
    this.eventbus.emit({ name: "manageChallengeEmitter", value: { challenge } })
  }
}
