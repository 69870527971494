import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reconnect',
  templateUrl: './reconnect.component.html',
  styleUrls: ['./reconnect.component.scss']
})

export class ReconectComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
