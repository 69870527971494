<loading *ngIf="loading"></loading>
<div *ngIf="!loading" class="challenges-crud">
    <p class="title-crud" *ngIf="!isModify">Subir nuevo reto</p>
    <p class="title-crud" *ngIf="isModify">Modificar reto #{{challenge.challengeId}}</p>
    <div class="challenges-form">
        <p class="label-name">Nombre del reto <span>(*obligatorio)</span>
        </p>
        <div class="input-name">
            <div class="input-name-icon">
                <i class="icon-ribbon"></i>
            </div>
            <div class="input-name-text">
                <input [(ngModel)]="tittle" type="text" name="tittle" autocomplete="off"
                    placeholder="Escriba aquí el nombre del consejo..." maxlength="80" />
            </div>
        </div>
        <p class="label-description">Descripción <span>(*obligatorio)</span></p>
        <div class="input-description">
            <textarea [(ngModel)]="description" name="description"
                placeholder="Haga una descripción para que los usuarios puedan entender de qué se trata..."
                maxlength="300"></textarea>
        </div>

        <div class="container-upload">
            <div class="date-section">
                <div class="date">
                    <div class="date-select">
                        <p class="label-icon">Fecha inicio <span>(*obligatorio)</span></p>
                        <div class="date-select-input">
                            <input type="date" [ngModel]="startDate" (ngModelChange)="startDate = $event">
                            <div class="date-select-input-icon">
                                <i class="material-icons">date_range</i>
                            </div>
                            <div class="date-select-input-value">
                                <span>{{!startDate ? 'dd/mm/aaaa' : startDate | date:'dd/MM/yyyy'}}</span>
                                <i class="material-icons">keyboard_arrow_down</i>
                            </div>
                        </div>
                    </div>
                    <div class="date-select">
                        <p class="label-icon">Fecha fin <span>(*obligatorio)</span></p>
                        <div class="date-select-input">
                            <input type="date" [ngModel]="endDate" (ngModelChange)="endDate = $event">
                            <div class="date-select-input-icon">
                                <i class="material-icons">date_range</i>
                            </div>
                            <div class="date-select-input-value">
                                <span>{{!endDate ? 'dd/mm/aaaa' : endDate | date:'dd/MM/yyyy'}}</span>
                                <i class="material-icons">keyboard_arrow_down</i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="date">
                    <div class="date-select">
                        <p class="label-icon">Insignia completada <span>(*obligatorio)</span></p>
                        <div class="img-space">
                            <ng-container *ngIf="isImageDoneSaved; else elseDoneTemplate">
                                <img [src]="imageDone" />
                            </ng-container>
                            <ng-template #elseDoneTemplate>
                                <div class="input-icon">
                                    <input type="file" (change)="uploadDoneImage($event)" />
                                    <i class="material-icons">highlight_alt</i>
                                    <span>Seleccionar insignia</span>
                                </div>
                            </ng-template>
                            <a class="btn-delete" (click)="removeDoneImage()" *ngIf="isImageDoneSaved">Eliminar
                                insignia</a>
                            <span class="error-msg">{{imageDoneError}}</span>
                        </div>
                    </div>
                    <div class="date-select">
                        <p class="label-icon">Insignia no completada <span>(*obligatorio)</span></p>
                        <div class="img-space">
                            <ng-container *ngIf="isImageUndoneSaved; else elseUndoneTemplate">
                                <img [src]="imageUndone" />
                            </ng-container>
                            <ng-template #elseUndoneTemplate>
                                <div class="input-icon">
                                    <input type="file" (change)="uploadUndoneImage($event)" />
                                    <i class="material-icons">highlight_alt</i>
                                    <span>Seleccionar insignia</span>
                                </div>
                            </ng-template>
                            <a class="btn-delete" (click)="removeUndoneImage()" *ngIf="isImageUndoneSaved">Eliminar
                                insignia</a>
                            <span class="error-msg">{{imageUndoneError}}</span>
                        </div>
                    </div>
                </div>
            </div>



            <div class="upload-section">
                <div class="video-add">
                    <p class="label-video">Enlace del vídeo</p>
                    <div class="input-video">
                        <div class="input-video-icon">
                            <i class="material-icons">play_arrow</i>
                        </div>
                        <div class="input-video-text">
                            <input [(ngModel)]="videoLink" type="text" name="videoLink" autocomplete="off"
                                placeholder="Escriba aquí la url del vídeo..." />
                        </div>
                    </div>
                </div>
                <div class="file-add">
                    <p class="label-icon">Cargar archivo</p>
                    <div class="img-space">
                        <ng-container *ngIf="pdfpath || pdf; else elsePdfTemplate">
                            <div class="file-row">
                                <span *ngIf="pdfpath">{{pdfpath.name}}</span>
                                <span *ngIf="pdf"><a href="{{pdf}}" target="_blank">{{pdfSplitted}}</a></span>
                            </div>
                        </ng-container>
                        <ng-template #elsePdfTemplate>
                            <div class="input-icon">
                                <input type="file" (change)="uploadFile($event)" />
                                <i class="material-icons">highlight_alt</i>
                                <span>Seleccionar archivo</span>
                            </div>
                        </ng-template>
                        <a class="btn-delete" (click)="deletePdfFile()" *ngIf="pdfpath || pdf">Eliminar
                            archivo</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="add-challenges"
            [ngClass]="{'disabled': !tittle || !description || !startDate || !endDate || !isImageUndoneSaved || !isImageDoneSaved || savingChallenge}">
            <div *ngIf="!isModify" class="submit" (click)="createChallenge()">
                <loading *ngIf="savingChallenge" [isButton]="true"></loading>
                <p>Subir reto</p>
            </div>
            <div *ngIf="isModify" class="submit" (click)="modifyChallenge()">
                <loading *ngIf="savingChallenge" [isButton]="true"></loading>
                <p>Modificar reto</p>
            </div>
        </div>
    </div>
</div>