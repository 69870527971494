import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment}  from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private urlGet: string = "api/Inititative/getinititative";
  private urlList: string = "api/Inititative/getinitiatives";
  private urlCreate: string = "api/Inititative/createinitiative";
  private urlUpdate: string = "api/Inititative/updateinitiative";
  private urlDelete: string = "api/Inititative/deleteinititative";
  private urlDeleteMultiple: string = "api/Inititative/deleteinititatives";
  constructor(private http: HttpClient) { }

  getInitiative(id): any {
    return this.http.get(environment.api + this.urlGet + '?initiativeId=' + id);
  }

  getInitiativesList(): any {
    return this.http.get(environment.api + this.urlList);
  }

  createInitiative(tittle, description, icon, endDate, amount): any {
    return this.http.post(environment.api + this.urlCreate,
      {
        "tittle": tittle,
        "description": description,
        "icon": icon,
        "endDate": endDate || new Date(),
        "amount": amount,
        "userId": 0,
        "groupId": 0
      }
    )
  }

  modifyInitiative(initiative): any {
    if (!initiative.iconUpdated) {
      initiative.icon = "";
    }
    return this.http.post(environment.api + this.urlUpdate,
      initiative
    )
  }

  deleteInitiative(id: string): any {
    return this.http.post(environment.api + this.urlDelete + "?initiativeid=" + id,
      {}
    )
  }

  deleteInitiatives(ids: Array<string>): any {
    var deleteString = "";
    ids.forEach(id => { deleteString += (!deleteString ? "?" : "&") + "initiativesid=" + id });
    return this.http.post(environment.api + this.urlDeleteMultiple + deleteString,
      {}
    )
  }
}
