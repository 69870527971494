import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private urlGet: string = "api/Advice/getadvice";
  private urlList: string = "api/Advice/getadvices";
  private urlCreate: string = "api/Advice/createadvice";
  private urlUpdate: string = "api/Advice/updateadvice";
  private urlDelete: string = "api/Advice/deleteadvice";
  private urlDeleteMultiple: string = "api/Advice/deleteadvices";
  constructor(private http: HttpClient) { }

  getAdvice(id): any {
    return this.http.get(environment.api + this.urlGet + '?adviceId=' + id);
  }

  getAdvicesList(): any {
    return this.http.get(environment.api + this.urlList);
  }

  createAdvice(tittle, description, icon): any {
    return this.http.post(environment.api + this.urlCreate,
      {
        "tittle": tittle,
        "description": description,
        "icon": icon,
        "userId": 0,
        "groupId": 0
      }
    )
  }

  modifyAdvice(advice): any {
    if (!advice.iconUpdated) {
      advice.icon = "";
    }
    return this.http.post(environment.api + this.urlUpdate,
      advice
    )
  }

  deleteAdvice(id: string): any {
    return this.http.post(environment.api + this.urlDelete + "?adviceid=" + id,
      {}
    )
  }

  deleteAdvices(ids: Array<string>): any {
    var deleteString = "";
    ids.forEach(id => { deleteString += (!deleteString ? "?" : "&") + "adviceid=" + id });
    return this.http.post(environment.api + this.urlDeleteMultiple + deleteString,
      {}
    )
  }
}
