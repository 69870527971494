<div *ngIf="items && totalPages > 1" class="list-page">
  <div *ngIf="page > 1" (click)="back()" class="back">
    <i class="material-icons">chevron_left</i>
  </div>
  <div class="number" *ngFor="let num of [].constructor(totalPages); let i = index" (click)="goToPage(i+1)">
    <span [ngClass]="{'active': page === i + 1}">{{i + 1}}</span>
  </div>
  <div *ngIf="page < totalPages" (click)="next()" class="next">
    <i class="material-icons">chevron_right</i>
  </div>
</div>