<div class="register">
    <div class="register-content">
        <div class="register-box" *ngIf="!createdUser">
            <div class="register-header">
                <p class="register-header-title">{{texts.registerTitle}}</p>
                <p class="register-header-subtitle">{{texts.registerText}}</p>
            </div>
            <div class="register-form">
                <div class="inputs-box">
                    <div class="left-column">
                        <div class="input">
                            <p class="input-title">Nombre <span>(*obligatorio)</span></p>
                            <div class="input-text">
                                <input [(ngModel)]="userName" type="text" autocomplete="off"
                                    placeholder="Escriba aquí su nombre... " maxlength="80" />
                            </div>
                        </div>
                        <div class="input">
                            <p class="input-title">Apellidos</p>
                            <div class="input-text">
                                <input [(ngModel)]="lastName" type="text" autocomplete="off"
                                    placeholder="Escriba aquí sus apellidos... " maxlength="80" />
                            </div>
                        </div>
                        <div class="input">
                            <p class="input-title">E-mail <span>(*obligatorio)</span></p>
                            <div class="input-text">
                                <input [(ngModel)]="email" type="text" autocomplete="off"
                                    placeholder="Escriba aquí su dirección de correo electrónico... " maxlength="80" />
                            </div>
                        </div>
                        <div class="input">
                            <p class="input-title">Número de hijos <span>(*obligatorio)</span></p>
                            <div class="input-select">
                                <div class="input-text-select">
                                    <select [(ngModel)]="children">
                                        <option [ngValue]=undefined disabled selected>Indique el número de hijos
                                        </option>
                                        <option *ngFor="let h of [0,1,2,3,4,5]" [ngValue]="h">{{h}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="right-column">
                        <div class="input">
                            <p class="input-title">Usuario <span>(*obligatorio)</span></p>
                            <div class="input-text">
                                <input [(ngModel)]="login" type="text" autocomplete="new-password"
                                    placeholder="Escriba aquí el nombre de usuario... " maxlength="80" />
                            </div>
                        </div>
                        <div class="input">
                            <p class="input-title">Contraseña <span>(*obligatorio)</span></p>
                            <div class="input-text">
                                <input [(ngModel)]="password" type="password" autocomplete="new-password"
                                    placeholder="Cree una contraseña... " maxlength="80" />
                            </div>
                        </div>
                        <div class="input">
                            <p class="input-title">Confirmar contraseña <span>(*obligatorio)</span></p>
                            <div class="input-text">
                                <input [(ngModel)]="repassword" type="password" autocomplete="new-password"
                                    placeholder="Repita la contraseña... " maxlength="80" />
                            </div>
                        </div>
                        <div class="input">
                            <p class="input-title">Colegio <span>(*obligatorio)</span></p>
                            <div class="input-select">
                                <div class="input-text-select">
                                    <select [(ngModel)]="school">
                                        <option [ngValue]=undefined disabled selected>Seleccione un colegio</option>
                                        <option *ngFor="let s of schools" [ngValue]="s">{{s.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="alert alert-danger" role="alert"
                    *ngIf="error || (password && repassword && password != repassword)">
                    <span *ngIf="password && repassword && password != repassword">Las contraseñas no coinciden</span>
                    {{error}}
                </div>
                <div class="submit-box" [ngClass]="{
                        'disabled': !userName || !login || !password || !repassword || password != repassword 
                        || !school || !email || (!children && children != 0) || savingUser }">
                    <div class="submit" (click)="register()">
                        <loading *ngIf="savingUser" [isButton]="true"></loading>
                        <p>Registrarte</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="register-box" *ngIf="createdUser">
            <div class="register-header">
                <p class="register-header-title">{{texts.registerResultTitle}}</p>
                <p class="register-header-subtitle">{{texts.registerResultText}}</p>
            </div>
            <div class="submit-box">
                <div class="submit" (click)="close()">
                    <p>Entendido</p>
                </div>
            </div>
        </div>
    </div>
</div>