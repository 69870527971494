import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from 'src/app/services/eventBus.service';
import { DataService } from '../data.service';
import * as _ from 'lodash';
import { ImagesService } from 'src/app/services/images.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-schools-crud',
  templateUrl: './admin-schools-crud.component.html',
  styleUrls: ['./admin-schools-crud.component.scss']
})
export class AdminSchoolsCrudComponent implements OnInit {
  @Input() school: any;
  @Input() isModify: boolean;
  loading: boolean = true;
  name: string;
  description: string;
  students: number;
  instalationSize: string;
  refClient: number;
  locations: Array<any> = [];
  location: any;
  icon: string;
  imageError: string;
  isImageSaved: boolean;
  iconUpdated: boolean = false;
  savingSchool: boolean = false;
  constructor(private data: DataService, private eventbus: EventBusService, private images: ImagesService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.data.getLocations().subscribe((res: any) => {
      this.locations = res;
      if (this.school) {
        this.location = this.locations.filter(a => a.locationId === this.school.locationId)[0];
      }
    },
      (error) => {
        this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' })
      });
    if (!this.isModify) {
      this.loading = false;
    }
    if (this.school) {
      this.name = this.school.name;
      this.description = this.school.description;
      this.students = this.school.students;
      this.instalationSize = this.school.instalationSize;
      this.refClient = this.school.refClient;
      this.icon = this.school.icon;
      if (this.icon) {
        this.isImageSaved = true;
      }
      this.loading = false;
    }
  }

  ngOnChanges(changes) {
    if (((changes || {}).school || {}).currentValue) {
      this.name = changes.school.currentValue.name;
      this.description = changes.school.currentValue.description;
      this.students = changes.school.currentValue.students;
      this.instalationSize = changes.school.currentValue.instalationSize;
      this.refClient = changes.school.currentValue.refClient;
      if (this.locations.length) {
        this.location = this.locations.filter(a => a.locationId === changes.school.currentValue.locationId)[0];
      }
      this.icon = changes.school.currentValue.icon;
      if (this.icon) {
        this.isImageSaved = true;
      }
      this.loading = false;
    }
  }

  createSchool(): void {
    if (this.name && this.description && this.icon && (this.students || this.students == 0) && this.refClient &&
      this.location && !this.savingSchool) {
      this.savingSchool = true;
      this.data.createSchool(this.name, this.description, this.icon['changingThisBreaksApplicationSecurity'],
        this.students, this.refClient, this.instalationSize, this.location).subscribe(
          (response) => {
            this.toastr.success('El colegio se ha creado correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
            this.savingSchool = false;
            this.backAndReloadSchoolsList();
          },
          (error) => {
            this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' });
            this.savingSchool = false;
          });
    }
  }

  modifySchool(): void {
    if (this.name && this.description && this.icon && (this.students || this.students == 0) && this.refClient &&
      this.location && !this.savingSchool) {
      this.savingSchool = true;
      this.school.name = this.name;
      this.school.description = this.description;
      this.school.locationId = this.location.locationId;
      this.school.location = this.location.name;
      this.school.students = this.students;
      this.school.instalationSize = this.instalationSize;
      this.school.refClient = this.refClient;
      this.school.icon = this.icon['changingThisBreaksApplicationSecurity'];
      this.school.iconUpdated = this.iconUpdated;
      this.data.modifySchool(this.school).subscribe((res: any) => {
        this.toastr.success('El colegio se ha modificado correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
        this.savingSchool = false;
        this.backAndReloadSchoolsList();
      },
        (error) => {
          this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' });
          this.savingSchool = false;
        });
    }
  }

  backAndReloadSchoolsList(): void {
    this.eventbus.emit({ name: "backAndReloadSchoolsList", value: "list" })
  }

  uploadIcon(fileInput: any) {
    this.images.imageLoad(fileInput).then(res => {
      this.imageError = res['error'];
      this.isImageSaved = res['saved'];
      this.icon = res['data'];
      this.iconUpdated = true;
    });
  }

  removeImage() {
    this.iconUpdated = true;
    this.icon = null;
    this.isImageSaved = false;
  }
}
