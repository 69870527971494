import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'website-menu',
  templateUrl: './website-menu.component.html',
  styleUrls: ['./website-menu.component.scss']
})
export class WebsiteMenuComponent implements OnInit {
  currentSection: string;
  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.currentSection = params.get('section') || "";
    });
  }

  goToSection(section: string): void {
    this.currentSection = section;
    if (section === 'presentacion') {
      this.router.navigate(['/presentacion']);
    } else {
      this.router.navigate(['/website/' + section]);
    }
  }

  goToLanding(): void {
    this.router.navigate(['/']);
  }
}
