<loading *ngIf="loading"></loading>
<table *ngIf="challenges" [type]="'challenges'" [title]="'Listado de retos'" [hasSearch]="false" [hasSelection]="false"
    [data]="challenges" [itemsPerPage]="6" [columns]="[
    {name: 'Insignia', value: 'image', class: 'icon', type:'image', width: '5'}, 
    {name: 'Nombre', value: 'tittle', class: 'name', width: '15'}, 
    {name: 'Descripción', value: 'description', class: 'description', width: '35'}, 
    {name: 'Fecha inicio', value: 'startDate', class: 'startDate', type:'date', width: '10'}, 
    {name: 'Fecha fin', value: 'endDate', class: 'endDate', type:'date', width: '10'}, 
    {name: 'Acciones', value: 'action', class: 'action', width: '12'}
    ]">
</table>