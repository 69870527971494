import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../login/authentication.service';
import { DataService } from './data.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  returnUrl: string;
  loading: boolean = true;
  schools: Array<any> = [];
  texts: any;
  lastName: string;
  userName: string;
  login: string;
  password: string;
  repassword: string;
  email: string;
  school: any;
  children: number;
  savingUser: boolean = false;
  createdUser: boolean = false;
  error: string;
  constructor(private data: DataService, private route: ActivatedRoute, private router: Router, private auth: AuthService) { }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    if (this.auth.currentUserValue) {
      // already logged in so redirect
      this.router.navigate([this.returnUrl]);
    } else {
      this.data.getSchools().subscribe((res: any) => {
        this.schools = res.map(x => { return { "schoolId": x.schoolId, "name": x.name } });
      });
      this.data.getTexts().subscribe((res: any) => {
        this.texts = res;
      });
    }
  }

  register(): void {
    if (this.userName && this.login && this.password && this.repassword && this.password == this.repassword &&
      this.email && this.school && (this.children || this.children == 0) && !this.savingUser) {
      this.savingUser = true;
      this.data.createUser(this.lastName, this.userName, this.login, this.password, this.email, this.school, this.children).subscribe(
        (response) => {
          this.savingUser = false;
          this.createdUser = true;
        },
        (error) => {
          this.error = error;
          this.savingUser = false;
        });
    }
  }

  close(): void {
    this.router.navigate([this.returnUrl]);
  }
}
