import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from './data.service';

@Component({
  selector: 'app-website',
  templateUrl: './website.component.html',
  styleUrls: ['./website.component.scss']
})
export class WebsiteComponent implements OnInit {
  schools: any;
  websiteSection: string;
  isScrolled: boolean = false;
  constructor(private route: ActivatedRoute, private data: DataService) { }

  ngOnInit(): void {
    this.data.getSchools().subscribe((res: any) => {
      this.schools = res;
    });
    this.route.paramMap.subscribe(params => {
      this.websiteSection = params.get('section') || "";
    });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    var verticalOffset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (verticalOffset > 50) {
      this.isScrolled = true;
    } else {
      this.isScrolled = false;
    }
  }
}
