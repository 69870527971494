import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';
import { ReconectComponent } from './shared/reconnect/reconnect.component';
import { LandingComponent } from './components/landing/landing.component'
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { AdminComponent } from './components/admin/admin.component';
import { AutologinComponent } from './components/login/autologin.component';
import { PresentationComponent } from './components/presentation/presentation.component';
import { WebsiteComponent } from './components/website/website.component';
import { ValidateComponent } from './components/login/validate.component';

const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'reconnect', component: ReconectComponent },
  { path: 'login', component: LoginComponent },
  { path: 'validar', component: ValidateComponent },
  { path: 'registro', component: RegisterComponent },
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard], data: { role: 'admin' } },
  { path: 'admin/:section', component: AdminComponent, canActivate: [AuthGuard], data: { role: 'read' } },
  { path: 'admin/:section/add', component: AdminComponent, canActivate: [AuthGuard], data: { role: 'write' } },
  { path: 'admin/:section/modify/:id', component: AdminComponent, canActivate: [AuthGuard], data: { role: 'update' } },
  { path: 'admin/:section/manage/:id', component: AdminComponent, canActivate: [AuthGuard], data: { role: 'manage' } },
  { path: 'kiosko', component: AutologinComponent },
  { path: 'presentacion', component: PresentationComponent, canActivate: [AuthGuard] },
  { path: 'website/:section', component: WebsiteComponent },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
