import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'saving-advices',
  templateUrl: './saving-advices.component.html',
  styleUrls: ['./saving-advices.component.scss']
})
export class SavingAdvicesComponent implements OnInit {
  @Input() isPresentation: boolean;
  @Input() advices: {};
  @Input() icon: string;
  imgarray: Array<string>;
  constructor() { }

  ngOnInit(): void {
  }

}
