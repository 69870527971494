<ng-container *ngIf="isPresentation">
    <div class="saving-advices" *ngFor="let advice of advices;let i=index">
        <div class="bubble">
            <img src="{{advice.icon}}" onerror="this.src='../../../../../assets/img/placeholder.png'">
            <p><span>"</span>{{advice.description}}<span>"</span></p>
        </div>
        <img src="{{icon}}" onerror="this.src='../../../../../assets/img/placeholder.png'">
    </div>
</ng-container>
<ng-container *ngIf="!isPresentation">
    <div class="saving-advices-website" *ngFor="let advice of advices;let i=index">
        <img src="{{advice.icon}}" onerror="this.src='../../../../../assets/img/placeholder.png'">
        <p><span>"</span>{{advice.description}}<span>"</span></p>
    </div>
</ng-container>