<div class="grow" *ngFor="let rank of ranking">
    <div class="description">
        <div class="position">
            <span>#{{rank.number}}</span>
        </div>

        <img class="school-icon" src="{{rank.icon}}" onerror="this.src='../../../../../assets/img/placeholder.png'">
        <p class="school-name">{{rank.name}}</p>
    </div>
    <div *ngIf="type === 'saving'" class="percentage">
        <span>{{rank.percentage}}%</span>
    </div>
    <div *ngIf="type === 'challenges'" class="badges">
        <div class="badge" *ngFor="let img of rank.images">
            <img src="{{img}}" onerror="this.src='../../../../../assets/img/placeholder.png'">
        </div>
    </div>
</div>