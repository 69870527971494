import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from 'src/app/services/eventBus.service';
import { DataService } from '../data.service';
import * as _ from 'lodash';
import { ImagesService } from 'src/app/services/images.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-initiatives-crud',
  templateUrl: './admin-initiatives-crud.component.html',
  styleUrls: ['./admin-initiatives-crud.component.scss']
})
export class AdminInitiativesCrudComponent implements OnInit {
  @Input() initiative: any;
  @Input() isModify: boolean;
  loading: boolean = true;
  tittle: string;
  description: string;
  endDate: string;
  amount: number;
  icon: string;
  imageError: string;
  isImageSaved: boolean;
  iconUpdated: boolean = false;
  savingInititative: boolean = false;
  constructor(private data: DataService, private eventbus: EventBusService, private images: ImagesService, private toastr: ToastrService) { }

  ngOnInit(): void {
    if (!this.isModify) {
      this.loading = false;
    }
    if (this.initiative) {
      this.isModify = true;
      this.tittle = this.initiative.tittle;
      this.description = this.initiative.description;
      this.endDate = this.initiative.endDate;
      this.amount = this.initiative.amount;
      this.icon = this.initiative.icon;
      if (this.icon) {
        this.isImageSaved = true;
      }
      this.loading = false;
    }
  }

  ngOnChanges(changes) {
    if (((changes || {}).initiative || {}).currentValue) {
      this.tittle = changes.initiative.currentValue.tittle;
      this.description = changes.initiative.currentValue.description;
      this.endDate = changes.initiative.currentValue.endDate;
      this.amount = changes.initiative.currentValue.amount;
      this.icon = changes.initiative.currentValue.icon;
      if (this.icon) {
        this.isImageSaved = true;
      }
      this.loading = false;
    }
  }

  createInitiative(): void {
    if (this.tittle && this.description && this.isImageSaved && this.amount && !this.savingInititative) {
      this.savingInititative = true;
      this.data.createInitiative(this.tittle, this.description, this.icon['changingThisBreaksApplicationSecurity'],
       this.endDate, this.amount).subscribe(
        (res: any) => {
          this.toastr.success('La iniciativa se ha creado correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
          this.savingInititative = false;
          this.backAndReloadInitiativesList();
        },
        (error) => {
          this.savingInititative = false;
          this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' })
        });
    }
  }

  modifyInitiative(): void {
    if (this.tittle && this.description && this.isImageSaved && this.amount && !this.savingInititative) {
      this.savingInititative = true;
      this.initiative.tittle = this.tittle;
      this.initiative.description = this.description;
      this.initiative.endDate = this.endDate;
      this.initiative.amount = this.amount;
      this.initiative.icon = this.icon['changingThisBreaksApplicationSecurity'];
      this.initiative.iconUpdated = this.iconUpdated;
      this.data.modifyInitiative(this.initiative).subscribe(
        (res: any) => {
          this.toastr.success('La iniciativa se ha modificado correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
          this.savingInititative = false;
          this.backAndReloadInitiativesList();
        },
        (error) => {
          this.savingInititative = false;
          this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' })
        });
    }
  }

  backAndReloadInitiativesList(): void {
    this.eventbus.emit({ name: "backAndReloadInitiativesList", value: "list" })
  }

  uploadIcon(fileInput: any) {
    this.images.imageLoad(fileInput).then(res => {
      this.imageError = res['error'];
      this.isImageSaved = res['saved'];
      this.icon = res['data'];
      this.iconUpdated = true;
    });
  }

  removeImage() {
    this.iconUpdated = true;
    this.icon = null;
    (this.initiative || {}).icon = null;
    this.isImageSaved = false;
  }
}
