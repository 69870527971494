<div *ngIf="!isLoggedIn" (click)="goToLogin()" class="profile-login">
    <i class="material-icons">login</i>
    <span>Iniciar sesión</span>
</div>
<div class="profile-button" (click)="switchDropdown()" *ngIf="isLoggedIn">
    <img src="{{userimage}}" onerror="this.src='../../../assets/img/icon/avatar-profile.svg'"
        class="profile_avatar profile-button-img">
    <p class="profile-button-name">{{username}}</p>
    <i class="profile-button-i material-icons">
        expand_more
    </i>
</div>
<div [hidden]="!dropdownOpened" class="profile-dropdown">
    <div class="profile-dropdown-element" (click)="goToAdmin()" *ngIf="hasRole()">
        <span class="profile-name-span">Mi cuenta</span>
    </div>
    <div class="profile-dropdown-element" (click)="logout()">
        <span class="profile-name-span">Cerrar sesión</span>
    </div>
</div>