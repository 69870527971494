<div class="admin">
    <div class="sidebar">
        <div class="logo">
            <img src="../../../assets/img/icon/logo-senda-white.svg" alt="" (click)="goToHome()">
        </div>
        <div class="menu">
            <div class="menu-buttons">
                <div class="button home" (click)="goToSection('')" [ngClass]="{'selected': selectedSection == ''}">
                    <div class="box-icon">
                        <i class="icon-home"></i>
                    </div>
                    <p>Inicio</p>
                </div>

                <div class="button tips" (click)="goToSection('advices')" *ngIf="hasRole('Advices', 'read')"
                    [ngClass]="{'selected': selectedSection == 'advices'}">
                    <div class="box-icon">
                        <i class="icon-tips"></i>
                    </div>
                    <p>Consejos</p>
                </div>

                <div class="button tips" (click)="goToSection('challenges')" *ngIf="hasRole('Challenges', 'read')"
                    [ngClass]="{'selected': selectedSection == 'challenges'}">
                    <div class="box-icon">
                        <i class="icon-ribbon"></i>
                    </div>
                    <p>Retos</p>
                </div>

                <div class="button tips" (click)="goToSection('initiatives')" *ngIf="hasRole('Initiatives', 'read')"
                    [ngClass]="{'selected': selectedSection == 'initiatives'}">
                    <div class="box-icon">
                        <i class="icon-bulb"></i>
                    </div>
                    <p>Iniciativas</p>
                </div>

                <div class="button tips" (click)="goToSection('users')" *ngIf="hasRole('Users', 'read')"
                    [ngClass]="{'selected': selectedSection == 'users'}">
                    <div class="box-icon">
                        <i class="material-icons">
                            admin_panel_settings</i>
                    </div>
                    <p>Usuarios</p>
                </div>

                <div class="button tips" (click)="goToSection('school')" *ngIf="hasRole('School', 'read')"
                    [ngClass]="{'selected': selectedSection == 'school'}">
                    <div class="box-icon">
                        <i class="material-icons">
                            account_balance</i>
                    </div>
                    <p>Colegios</p>
                </div>

                <div class="button tips" (click)="goToSection('class')" *ngIf="hasRole('Class', 'read')"
                    [ngClass]="{'selected': selectedSection == 'class'}">
                    <div class="box-icon">
                        <i class="material-icons">
                            supervised_user_circle</i>
                    </div>
                    <p>Clases</p>
                </div>

                <div class="button tips" (click)="goToSection('equivalence')" *ngIf="hasRole('Equivalence', 'read')"
                    [ngClass]="{'selected': selectedSection == 'equivalence'}">
                    <div class="box-icon">
                        <i class="material-icons">
                            published_with_changes</i>
                    </div>
                    <p>Equivalencias</p>
                </div>
            </div>
        </div>
    </div>

    <div class="wrapper">
        <div class="header">
            <div class="settings">
                <profile></profile>
            </div>
        </div>

        <div class="wrapper-content">
            <div *ngIf="selectedSection === ''">
                <app-admin-dashboard (goToSectionEmitter)="goToSection($event)"></app-admin-dashboard>
            </div>
            <div *ngIf="selectedSection === 'initiatives'">
                <app-admin-initiatives></app-admin-initiatives>
            </div>
            <div *ngIf="selectedSection === 'advices'">
                <app-admin-advices></app-admin-advices>
            </div>
            <div *ngIf="selectedSection === 'challenges'">
                <app-admin-challenges></app-admin-challenges>
            </div>
            <div *ngIf="selectedSection === 'users'">
                <app-admin-users></app-admin-users>
            </div>
            <div *ngIf="selectedSection === 'school'">
                <app-admin-schools></app-admin-schools>
            </div>
            <div *ngIf="selectedSection === 'class'">
                <app-admin-classes></app-admin-classes>
            </div>
            <div *ngIf="selectedSection === 'equivalence'">
                <app-admin-equivalences></app-admin-equivalences>
            </div>
        </div>