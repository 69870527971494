<loading *ngIf="loading"></loading>
<div *ngIf="!loading" class="classes-crud">
    <p class="title-crud" *ngIf="!isModify">Añadir nueva clase</p>
    <p class="title-crud" *ngIf="isModify">Modificar clase #{{classe.id}}</p>
    <div class="classes-form">
        <p class="label-school">Colegio <span>(*obligatorio)</span>
        </p>
        <div class="input-school">
            <div class="input-school-text">
                <select [(ngModel)]="school">
                    <option [ngValue]=undefined disabled selected>Seleccione un colegio</option>
                    <option *ngFor="let s of schools" [ngValue]="s">{{s.name}}</option>
                </select>
            </div>
        </div>
        <div class="container">
            <div class="left-box">
                <p class="label-course">Curso <span>(*obligatorio)</span>
                </p>
                <div class="input-course">
                    <div class="input-course-text">
                        <input [(ngModel)]="course" type="text" name="course" autocomplete="off"
                            placeholder="Escriba aquí el curso..." maxlength="25" />
                    </div>
                </div>
            </div>
            <div class="right-box">
                <p class="label-class">Clase </p>
                <div class="input-class">
                    <div class="input-class-text">
                        <input [(ngModel)]="class" type="text" name="class" autocomplete="off"
                            placeholder="Escriba aquí la clase..." maxlength="25" />
                    </div>
                </div>
            </div>
        </div>


        <div class="add-classes" [ngClass]="{'disabled': !school || !course || savingClass}">
            <div *ngIf="!isModify" class="submit" (click)="createClass()">
                <loading *ngIf="savingClass" [isButton]="true"></loading>
                <p class="text-button">Añadir clase</p>
            </div>
            <div *ngIf="isModify" class="submit" (click)="modifyClass()">
                <loading *ngIf="savingClass" [isButton]="true"></loading>
                <p class="text-button">Modificar clase</p>
            </div>
        </div>
    </div>
</div>