import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { environment}  from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private url: string = "api/Landing/GetLandingInfo";
  constructor(private http: HttpClient, private config: ConfigurationService) { }

  getTextsFromSql(): any {
    var configuration = this.config.getConfiguration();
    return this.http.get(environment.api + this.url + "?groupIdentity=" + configuration.group);
  }
}
