<div class="challenges-manage">
    <div class="evalutation">
        <div class="evalutation-header">
            <p>Evaluación</p>
            <div class="evalutation-header-selector" *ngIf="currentClass">
                <i class="evalutation-header-selector-icon material-icons">school</i>
                <div class="evalutation-header-selector-value" (click)="switchClassDropdown()">
                    <span class="evalutation-header-selector-value-name">{{currentClass.description}}</span>
                    <i class="material-icons evalutation-header-selector-value-i">keyboard_arrow_down</i>
                </div>
                <div [hidden]="!dropdownClassOpened" class="class_select-dropdown">
                    <div class="class_select-dropdown-element" (click)="loadClassData(class)"
                        *ngFor="let class of classes">
                        <span class="class-name-span">{{class.description}}</span></div>
                </div>
            </div>
        </div>
        <loading *ngIf="loadingForm"></loading>
        <div class="evalutation-content" *ngIf="form && !loadingForm">
            <app-admin-challenges-manage-evaluation [challenge]="challenge" [form]="form">
            </app-admin-challenges-manage-evaluation>
        </div>
    </div>
    <div class="right-column">
        <div class="challenge-status">
            <div class="challenge-status-title">
                <p>Estado del reto</p>
            </div>
            <div class="challenge-status-info">
                <span>Indicar el % de alumnos que lo han completado</span>
            </div>
            <div class="challenge-status-description">
                <div class="challenge-icon">
                    <img src="{{challenge.imageDone}}" onerror="this.src='../../../../../assets/img/placeholder.png'">
                </div>
                <loading *ngIf="loadingForm" style="width: 100%;"></loading>
                <div class="challenge-percentage" *ngIf="form && !loadingForm">
                    <div class="number">
                        <p>{{percentage}}%</p>
                    </div>
                    <div class="percentage-indicator">
                        <div class="control__wrapper">
                            <input type="range" min="0" max="100" step="25" value="{{form.percentage}}" class="slider"
                                (input)="sliderValueChanged($event.target.value)"
                                (change)="sliderValueSet($event.target.value)" [disabled]="!sliderEnabled" />
                        </div>
                        <div class="bar-values">
                            <span>&nbsp;0%&nbsp;</span>
                            <span>&nbsp;&nbsp;|&nbsp;</span>
                            <span>&nbsp;50%</span>
                            <span>&nbsp;&nbsp;&nbsp;|</span>
                            <span>100%</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="challenge-access">
            <div class="challenge-access-title">
                <p>Cambiar de reto</p>
            </div>
            <div class="challenge-access-content">
                <challenges-board *ngIf="challenges" [isAdmin]="true" [challenges]="challenges"></challenges-board>
            </div>
        </div>
    </div>
</div>