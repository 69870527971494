<div class="initiatives-ranking-list">
    <div class="initiatives-ranking-list-position">
        <span class="initiative-order">{{initiative.position}}</span>
    </div>
    <div class="initiatives-ranking-list-content">
        <div class="content-information">
            <img class="content-information-picture" src="{{initiative.icon}}"
                onerror="this.src='../../../../../../assets/img/placeholder.png'">
            <div class="content-information-name">
                <p class="name">{{initiative.tittle}}</p>
                <div class="chart">
                    <div class="bar" [style.width.%]="(initiative.piggyBank * 100) / initiative.amount"></div>
                </div>
            </div>
            <div class="content-information-values">
                <span class="value-pink">{{initiative.piggyBank}}</span>
                <span class="value-black">/{{initiative.amount}}€</span>
            </div>
        </div>
        <div class="content-likes">
            <img src="../../../../../../assets/img/icon/heart-blue.svg">
            <p>{{initiative.votes}}</p>
        </div>
    </div>
</div>