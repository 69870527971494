<div class="senda">
    <div class="senda_header">
        <p class="senda_header-title">Bienvenido a Senda School</p>
    </div>
    <loading *ngIf="!data"></loading>
    <div class="senda_body" *ngIf="data">
        <div class="senda_body-what">
            <div class="what-picture">
                <img src="../../../../assets/img/bulb-world.png">
            </div>
            <div class="what-description">
                <p class="senda_body-title-section">¿Qué es?</p>
                <p class="what-description-text">{{data.whatIs}}</p>
            </div>
        </div>
        <div class="senda_body-how">
            <p class="senda_body-title-section">¿Cómo funciona?</p>
            <p class="senda_body-how-description">{{data.howWork}}
            </p>
            <div class="senda_body-how-instructions">
                <div class="steps">
                    <div class="box-icon">
                        <i class="wicon-panel"></i>
                    </div>
                    <p class="steps-title">Instalamos</p>
                    <p class="steps-text">{{data.instalation}}</p>
                </div>
                <div class="steps">
                    <div class="box-icon">
                        <i class="wicon-exam"></i>
                    </div>
                    <p class="steps-title">Exploramos</p>
                    <p class="steps-text">{{data.start}}</p>
                </div>
                <div class="steps">
                    <div class="box-icon">
                        <i class="wicon-energy"></i>
                    </div>
                    <p class="steps-title">Ahorramos</p>
                    <p class="steps-text">{{data.saveEnergy}}</p>
                </div>
                <div class="steps">
                    <div class="box-icon">
                        <i class="wicon-piggy"></i>
                    </div>
                    <p class="steps-title">Disfrutamos</p>
                    <p class="steps-text">{{data.enjoy}}</p>
                </div>
            </div>
            <div class="senda_body-how-link-discover" (click)="goToDiscover()">
                <div class="discover_button">
                    <span>
                        descubre
                    </span>
                </div>
            </div>
            <br>
            <a href="https://youtu.be/QuQnYGRY3Ds" target="blank" style="text-decoration: none;">
                <div class="senda_body-how-link-discover">
                    <div class="discover_button">
                        <span>
                            video presentación
                        </span>
                    </div>
                </div>
            </a>
        </div>
        <div class="senda_body-who">
            <p class="senda_body-title-section">¿Quién participa?</p>
            <div class="senda_body-who-carousel">
                <div class="carousel-button" (click)="prevPhotoFunc()">
                    <i class="material-icons">keyboard_arrow_left</i>
                </div>
                <div class="carousel-picture" *ngIf="data.photos">
                    <div class="carousel-picture-img" *ngFor="let photo of data.photos">
                        <img src="{{photo.archive}}" onerror="this.src='../../../../../assets/img/placeholder.png'">
                        <p>{{photo.name}}</p>
                    </div>
                </div>
                <div class="carousel-button" (click)="nextPhotoFunc()">
                    <i class="material-icons">keyboard_arrow_right</i>
                </div>
            </div>
        </div>
        <div class="senda_body-collaboration">
            <p class="senda_body-title-section">En colaboración con...</p>
            <div class="collaboration-logo">
                <img class="senda-logo" src="../../../../assets/img/icon/logo-senda.svg">
                <img class="agenda-logo" src="../../../../assets/img/agenda2030.png">
                <img class="tierra-logo" src="../../../../assets/img/tierradeaguas.svg">
                <img class="cuerva-logo" src="../../../../assets/img/cuerva.png">
            </div>
        </div>

    </div>
</div>