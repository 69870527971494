import { Component, Input, OnInit } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { EventBusService } from 'src/app/services/eventBus.service';

@Component({
  selector: 'website-initiatives-modal',
  templateUrl: './initiatives-modal.component.html',
  styleUrls: ['./initiatives-modal.component.scss']
})
export class InitiativesModalComponent implements OnInit {
  @Input() type: string;
  @Input() initiative: any;
  constructor(private eventbus: EventBusService, private router: Router) { }

  ngOnInit(): void { }

  closeModal(): void {
    this.eventbus.emit({ name: "closeModal" })
  }

  goToLogin(): void {
    this.router.navigate(['/login'], { queryParams: { returnUrl: '/website/iniciativas' } });
  }

  goToRegister(): void {
    this.router.navigate(['/registro'], { queryParams: { returnUrl: '/website/iniciativas' } });
  }

  voteModal(): void {
    this.eventbus.emit({ name: "voteModal", value: this.initiative })
  }
}
