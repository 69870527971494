import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventBusService } from 'src/app/services/eventBus.service';
import { AuthService } from '../../login/authentication.service';
import { DataService } from './data.service';

@Component({
  selector: 'app-admin-advices',
  templateUrl: './admin-advices.component.html',
  styleUrls: ['./admin-advices.component.scss']
})
export class AdminAdvicesComponent implements OnInit {
  section: string;
  adviceToModify: any;
  listeners: Array<any> = [];

  constructor(private eventbus: EventBusService, private router: Router, private route: ActivatedRoute, private data: DataService, private authenticationService: AuthService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      var id = params.get('id') || "";
      if (this.router.url.indexOf('admin/advices/modify/') > -1) {
        this.goToSection('modify');
        this.data.getAdvice(id).subscribe((res: any) => {
          this.adviceToModify = res;
        });
      } else if (this.router.url.indexOf('admin/advices/add') > -1) {
        this.goToSection('add');
      }
      else {
        this.section = "list";
      }
    });
    this.listeners.push(this.eventbus.on("modifyadvicesEmitter", (data) => {
      this.modifyAdvice(data);
    }));
    this.listeners.push(this.eventbus.on("backAndReloadAdvicesList", (data) => {
      this.router.navigate(['/admin/advices']);
      this.goToSection(data);
    }));
    this.listeners.push(this.eventbus.on("goToAdvicesList", (data) => {
      this.router.navigate(['/admin/advices']);
      this.goToSection(data);
    }));
  }

  hasRole(role) {
    const currentUser = this.authenticationService.currentUserValue;
    var dict = currentUser.permissionsDictionary;
    return (dict["Advices"] || []).includes(role);
  }

  modifyAdvice(advice): void {
    this.router.navigate(['/admin/advices/modify/' + advice.id]);
  }

  backToList() {
    this.router.navigate(['/admin/advices']);
    this.goToSection('list');
  }

  goToNew() {
    this.router.navigate(['/admin/advices/add']);
  }

  goToSection(section: string) {
    this.section = section;
  }

  ngOnDestroy() {
    this.listeners.forEach(x => x.unsubscribe());
  }
}
