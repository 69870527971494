<div class="energy" [ngClass]="{'componentInWebsite': !isPresentation}">
    <div class="grid_energy">
        <div class="left-top">
            <energy-generated [generated]="energy.generated" [isPresentation]="isPresentation" [isWidget]="false"></energy-generated>
        </div>
        <div class="right-top">
            <energy-comparative [comparative]="energy.comparative" [isPresentation]="isPresentation">
            </energy-comparative>
        </div>
        <div class="left-bottom">
            <energy-consumption [consumption]="energy.consumption" [isPresentation]="isPresentation">
            </energy-consumption>
        </div>
        <div class="right-bottom">
            <energy-piggy-bank [piggyBank]="energy.piggyBank" [isPresentation]="isPresentation" [isWidget]="false"></energy-piggy-bank>
        </div>
    </div>
</div>