import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class PdfService {
    constructor() { }

    convertToBase64(fileInput) {
        return new Promise((resolve, reject) => {
            if (fileInput.target.files && fileInput.target.files[0]) {
                // Select the very first file from list
                var fileToLoad = fileInput.target.files[0];
                // FileReader function for read the file.
                var fileReader = new FileReader();
                var base64;
                // Onload of file read the file content
                fileReader.onload = function (fileLoadedEvent) {
                    base64 = fileLoadedEvent.target.result;
                    // Print data in console
                    resolve({ name: fileToLoad.name, archive: base64, archiveId: 0 });
                };
                // Convert data to base64
                fileReader.readAsDataURL(fileToLoad);
            }
        });
    }
}