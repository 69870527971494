<div class="initiatives">
    <div *ngIf="section === 'list'">
        <div class="initiatives_header">
            <p class="initiatives_header-title">Iniciativas</p>
            <div class="initiatives_header-add" (click)="goToNew()">
                <span>Subir iniciativa</span>
            </div>
        </div>
        <div class="initiatives_section">
            <app-admin-initiatives-list></app-admin-initiatives-list>
        </div>
    </div>
    <div *ngIf="section === 'add' || section === 'modify'">
        <div class="initiatives_header">
            <p class="initiatives_header-title">
                <span class="initiatives_header-breadcrumb" (click)="backToList()">
                    Iniciativas
                </span>
                <span class="initiatives_header-breadcrumb-slash"> | </span>
                <span *ngIf="section === 'add'">Nueva Iniciativa</span>
                <span *ngIf="section === 'modify'">Modificar Iniciativa</span>
            </p>
        </div>
        <div class="initiatives_section">
            <app-admin-initiatives-crud *ngIf="section === 'add'"></app-admin-initiatives-crud>
            <app-admin-initiatives-crud *ngIf="section === 'modify'" [isModify]="true"
                [initiative]="initiativeToModify"></app-admin-initiatives-crud>
        </div>
    </div>
</div>