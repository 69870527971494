<div class="initiatives-countdown">
  <div class="initiatives-countdown-title">
    <p >Tiempo restante</p>
  </div>

  <div class="flex-wrapper">
    <div class="single-chart">
      <svg viewBox="0 0 36 36" class="circular-chart white">
        <path class="circle-bg" d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831" />
        <path id="daysPath" class="circle" stroke-dasharray="0, 100" d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831" />
        <text x="18" y="22" class="percentage">{{days}}</text>
      </svg>
      <p>{{dDisplay}}</p>
    </div>

    <div class="single-chart">
      <svg viewBox="0 0 36 36" class="circular-chart white">
        <path class="circle-bg" d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831" />
        <path id="hoursPath" class="circle" stroke-dasharray="0, 100" d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831" />
        <text x="18" y="22" class="percentage">{{hours}}</text>
      </svg>
      <p>{{hDisplay}}</p>
    </div>

    <div class="single-chart">
      <svg viewBox="0 0 36 36" class="circular-chart white">
        <path class="circle-bg" d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831" />
        <path id="minutesPath" class="circle" stroke-dasharray="0, 100" d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831" />
        <text x="18" y="22" class="percentage">{{minutes}}</text>
      </svg>
      <p>{{mDisplay}}</p>
    </div>
  </div>
</div>