<div class="schools">
    <div *ngIf="section === 'list'">
        <div class="schools_header">
            <p class="schools_header-title">Colegios</p>
            <div class="schools_header-add" (click)="goToNew()" *ngIf="hasRole('write')">
                <span>Añadir colegio</span>
            </div>
        </div>
        <div class="schools_section">
            <app-admin-schools-list></app-admin-schools-list>
        </div>
    </div>
    <div *ngIf="section === 'add' || section === 'modify'">
        <div class="schools_header">
            <p class="schools_header-title">
                <span class="schools_header-breadcrumb" (click)="backToList()">
                    Colegios
                </span>
                <span class="schools_header-breadcrumb-slash"> | </span>
                <span *ngIf="section === 'add'">Nuevo Colegio</span>
                <span *ngIf="section === 'modify'">Modificar Colegio</span>
            </p>
        </div>
        <div class="schools_section">
            <app-admin-schools-crud *ngIf="section === 'add'"></app-admin-schools-crud>
            <app-admin-schools-crud *ngIf="section === 'modify'" [isModify]="true" [school]="schoolToModify">
            </app-admin-schools-crud>
        </div>
    </div>
</div>