<div class="advices">
    <div *ngIf="section === 'list'">
        <div class="advices_header">
            <p class="advices_header-title">Consejos</p>
            <div class="advices_header-add" (click)="goToNew()" *ngIf="hasRole('write')">
                <span>Subir consejo</span>
            </div>
        </div>
        <div class="advices_section">
            <app-admin-advices-list></app-admin-advices-list>
        </div>
    </div>
    <div *ngIf="section === 'add' || section === 'modify'">
        <div class="advices_header">
            <p class="advices_header-title">
                <span class="advices_header-breadcrumb" (click)="backToList()">
                    Consejos
                </span>
                <span class="advices_header-breadcrumb-slash"> | </span>
                <span *ngIf="section === 'add'">Nuevo Consejo</span>
                <span *ngIf="section === 'modify'">Modificar Consejo</span>
            </p>
        </div>
        <div class="advices_section">
            <app-admin-advices-crud *ngIf="section === 'add'"></app-admin-advices-crud>
            <app-admin-advices-crud *ngIf="section === 'modify'" [isModify]="true" [advice]="adviceToModify">
            </app-admin-advices-crud>
        </div>
    </div>
</div>