import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private urlGet: string = "api/School/getschool";
  private urlList: string = "api/School/getschoolcompletelist";
  private urlCreate: string = "api/School/createschool";
  private urlUpdate: string = "api/School/updateschool";
  private urlDelete: string = "api/School/deleteschool";
  private urlDeleteMultiple: string = "api/School/deleteschoollist";
  private locationsUrl: string = "api/School/getlocationlist";
  constructor(private http: HttpClient) { }

  getLocations(): any {
    return this.http.get(environment.api + this.locationsUrl);
  }

  getSchool(id): any {
    return this.http.get(environment.api + this.urlGet + '?schoolId=' + id);
  }

  getSchoolsList(): any {
    return this.http.get(environment.api + this.urlList);
  }

  createSchool(name, description, icon, students, refClient, instalationSize, location): any {
    return this.http.post(environment.api + this.urlCreate,
      {
        name,
        description,
        icon,
        "students": parseInt(students),
        "refClient": parseInt(refClient),
        instalationSize,
        "locationId": parseInt(location.schoolId),
        "location": location.name
      }
    )
  }

  modifySchool(school): any {
    if (school.students)
      school.students = parseInt(school.students);
    if (school.refClient)
      school.refClient = parseInt(school.refClient);
    if (school.locationId)
      school.locationId = parseInt(school.locationId);
    return this.http.post(environment.api + this.urlUpdate,
      school
    )
  }

  deleteSchool(id: string): any {
    return this.http.post(environment.api + this.urlDelete + "?schoolId=" + id,
      {}
    )
  }

  deleteSchools(ids: Array<string>): any {
    var deleteString = "";
    ids.forEach(id => { deleteString += (!deleteString ? "?" : "&") + "schoolsId=" + id });
    return this.http.post(environment.api + this.urlDeleteMultiple + deleteString,
      {}
    )
  }
}
