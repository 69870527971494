<div class="table_list">
  <div class="table_list-header">
    <p class="title-list">{{title}}</p>
    <div *ngIf="hasSearch" class="search-list">
      <input [(ngModel)]="search" (ngModelChange)="doSearch($event)" type="text" autocomplete="off"
        placeholder="Buscar..." />
      <img src="../../../assets/img/icon/search.svg">
    </div>
  </div>
  <div class="table_list-content">
    <div *ngIf="paginatedData.length > 0">
      <div class="headline">
        <input *ngIf="hasSelection" [(ngModel)]="allSelected" (ngModelChange)="selectAll($event)" class="check"
          type="checkbox" />
        <p class="{{column.class}}" [ngStyle]="{'width.%': column.width}" *ngFor="let column of columns">{{column.name}}
        </p>
      </div>
      <div class="rows" *ngIf="paginatedData[0]">
        <div class="table_row" *ngFor="let item of paginatedData; let i = index">
          <input *ngIf="hasSelection" class="check" [(ngModel)]="item.selected" type="checkbox" />
          <ng-container *ngFor="let column of columns">
            <img *ngIf="column.type === 'image'" class="{{column.class}}" src="{{item[column.value]}}"
              onerror="this.src='../../../assets/img/placeholder.png'" [ngStyle]="{'width.%': column.width}" />
            <p *ngIf="column.type !== 'image' && column.value !== 'action'" class="{{column.class}}"
              [ngStyle]="{'width.%': column.width}">
              {{column.type === 'date' ? (item[column.value] | date: 'dd/MM/yyyy') : item[column.value]}}</p>
            <div *ngIf="column.value === 'action'" class="action-buttons">
              <i *ngIf="hasRole('update')" class="material-icons edit" (click)="modifyAction(item)">edit</i>
              <i *ngIf="type === 'challenges' && hasRole('manage')" class="material-icons manage"
                (click)="manageAction(item)">people_alt</i>
              <i *ngIf="hasRole('delete')" class="material-icons delete" (click)="deleteAction(item)">delete</i>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div *ngIf="paginatedData.length === 0">
      no hay resultados
    </div>
    <div class="list-foot">
      <div class="table-delete">
        <span (click)="deleteSelected()" *ngIf="areSelected() > 0 && hasRole('delete')"> <i
            class="material-icons delete">delete</i> &nbsp;
          Eliminar {{areSelected()}} seleccionados</span>
      </div>
      <pagination *ngIf="paginatedData" [searched]="searchedData.length" [items]="paginatedData.length"
        [itemsPerPage]="itemsPerPage"></pagination>
    </div>
  </div>
</div>