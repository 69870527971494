import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventBusService } from 'src/app/services/eventBus.service';
import { AuthService } from '../../login/authentication.service';
import { DataService } from './data.service';

@Component({
  selector: 'app-admin-challenges',
  templateUrl: './admin-challenges.component.html',
  styleUrls: ['./admin-challenges.component.scss']
})
export class AdminChallengesComponent implements OnInit {
  section: string;
  challengeToModify: any;
  challengeToManage: any;
  loadingChallenge: boolean;
  listeners: Array<any> = [];

  constructor(private eventbus: EventBusService, private router: Router, private route: ActivatedRoute, private data: DataService, private authenticationService: AuthService) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      var id = params.get('id') || "";
      if (this.router.url.indexOf('admin/challenges/modify/') > -1) {
        this.goToSection('modify');
        this.data.getChallenge(id).subscribe((res: any) => {
          this.challengeToModify = res;
        });
      } else if (this.router.url.indexOf('admin/challenges/manage/') > -1) {
        this.loadingChallenge = true;
        this.goToSection('manage');
        this.data.getChallenge(id).subscribe((res: any) => {
          this.challengeToManage = res;
          this.loadingChallenge = false;
        });
      } else if (this.router.url.indexOf('admin/challenges/add') > -1) {
        this.goToSection('add');
      }
      else {
        this.section = "list";
      }
    });
    this.listeners.push(this.eventbus.on("modifychallengesEmitter", (data) => {
      this.modifyChallenge(data);
    }));
    this.listeners.push(this.eventbus.on("manageChallengeEmitter", (data) => {
      this.manageChallenge(data);
    }));
    this.listeners.push(this.eventbus.on("backAndReloadChallengesList", (data) => {
      this.router.navigate(['/admin/challenges']);
      this.goToSection(data);
    }));
    this.listeners.push(this.eventbus.on("goToChallengesList", (data) => {
      this.router.navigate(['/admin/challenges']);
      this.goToSection(data);
    }));
  }

  hasRole(role) {
    const currentUser = this.authenticationService.currentUserValue;
    var dict = currentUser.permissionsDictionary;
    return (dict["Challenges"] || []).includes(role);
  }

  modifyChallenge(challenge): void {
    this.router.navigate(['/admin/challenges/modify/' + challenge.challengeId]);
  }

  backToList() {
    this.router.navigate(['/admin/challenges']);
    this.goToSection('list');
  }

  goToNew() {
    this.router.navigate(['/admin/challenges/add']);
  }

  manageChallenge(data): void {
    this.router.navigate(['/admin/challenges/manage/' + data.challenge.challengeId]);
  }

  goToSection(section: string) {
    this.section = section;
  }

  ngOnDestroy() {
    this.listeners.forEach(x => x.unsubscribe());
  }
}
