<loading *ngIf="loading"></loading>
<div *ngIf="!loading" class="schools-crud">
    <p class="title-crud" *ngIf="!isModify">Añadir nuevo colegio</p>
    <p class="title-crud" *ngIf="isModify">Modificar colegio #{{school.schoolId}}</p>
    <div class="schools-form">
        <p class="label-text">Nombre del colegio <span>(*obligatorio)</span>
        </p>
        <div class="input-name">
            <div class="input-name-icon">
                <i class="material-icons"> account_balance</i>
            </div>
            <div class="input-name-text">
                <input [(ngModel)]="name" type="text" name="tittle" autocomplete="off"
                    placeholder="Escriba aquí el nombre del colegio..." maxlength="80" />
            </div>
        </div>
        <p class="label-text">Descripción <span>(*obligatorio)</span></p>
        <div class="input-description">
            <textarea [(ngModel)]="description" name="description"
                placeholder="Haga una descripción para que los usuarios puedan entender de qué se trata..."
                maxlength="300"></textarea>
        </div>

        <div class="container-horizontal">
            <div class="left-box">
                <p class="label-icon">Icono del colegio <span>(*obligatorio)</span></p>
                <div class="img-space">
                    <ng-container *ngIf="isImageSaved; else elseTemplate">
                        <img [src]="icon" />
                    </ng-container>
                    <ng-template #elseTemplate>
                        <div class="input-icon">
                            <input type="file" (change)="uploadIcon($event)" />
                            <i class="material-icons">highlight_alt</i>
                            <span>Seleccionar imagen</span>
                        </div>
                    </ng-template>
                    <a class="btn-delete" (click)="removeImage()" *ngIf="isImageSaved">Eliminar imagen</a>
                    <span class="error-msg">{{imageError}}</span>
                </div>
            </div>
            <div class="right-box">
                <div class="first-row">
                    <div class="number-students">
                        <p class="label-text">Número de alumnos del colegio <span>(*obligatorio)</span><span
                                class="subtitle"><br>Indique la cantidad de alumnos del colegio </span></p>
                        <div class="input">
                            <div class="input-icon">
                                <i class="wicon-group"></i>
                            </div>
                            <div class="input-text">
                                <input [(ngModel)]="students" type="number" autocomplete="off"
                                    placeholder="Escriba aquí la cantidad..." maxlength="80" />
                            </div>
                        </div>
                    </div>
                    <div class="number-installation">
                        <p class="label-text">Tamaño de la instalación <span class="subtitle"> <br>Indique el tamaño de
                                la instalación fotovoltaica, que será en kw o kwh </span></p>
                        <div class="input">
                            <div class="input-icon">
                                <i class="wicon-panel"></i>
                            </div>
                            <div class="input-text">
                                <input [(ngModel)]="instalationSize" type="text" Installation="tittle"
                                    autocomplete="off" placeholder="Escriba aquí la cantidad..." maxlength="80" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="second-row">
                    <div class="location">
                        <p class="label-text">Localización <span>(*obligatorio)</span><span class="subtitle"><br>Indique
                                la localidad en la que se encuentra el colegio</span></p>
                        <div class="input-location">
                            <div class="input-location-icon">
                                <i class="wicon-meeting"></i>
                            </div>
                            <div class="input-location-text">
                                <select [(ngModel)]="location">
                                    <option [ngValue]=undefined disabled selected>Seleccione una localidad</option>
                                    <option *ngFor="let l of locations" [ngValue]="l">{{l.name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="reference">
                        <p class="label-text">Ref. cliente fotovoltaica <span>(*obligatorio)</span><span
                                class="subtitle"> <br>Referencia a la id del cliente en fotovoltaica</span></p>
                        <div class="input">
                            <div class="input-icon">
                                <i>nº</i>
                            </div>
                            <div class="input-text">
                                <input [(ngModel)]="refClient" type="number" Installation="tittle" autocomplete="off"
                                    placeholder="Escriba aquí la referencia..." maxlength="80" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="add-schools"
            [ngClass]="{'disabled': !name || !description || (!students && students != 0) || !icon || !refClient || !location || savingSchool}">
            <div *ngIf="!isModify" class="submit" (click)="createSchool()">
                <loading *ngIf="savingSchool" [isButton]="true"></loading>
                <p class="text-button">Añadir colegio</p>
            </div>
            <div *ngIf="isModify" class="submit" (click)="modifySchool()">
                <loading *ngIf="savingSchool" [isButton]="true"></loading>
                <p class="text-button">Modificar colegio</p>
            </div>
        </div>
    </div>
</div>