import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SvgUtils {
  constructor() { }
  public getCartesian(cx, cy, radius, angle) {
    var rad = angle * Math.PI / 180;
    return {
      x: Math.round((cx + radius * Math.cos(rad)) * 1000) / 1000,
      y: Math.round((cy + radius * Math.sin(rad)) * 1000) / 1000
    };
  }

  public getDialCoords(radius, startAngle, endAngle) {
    var cx = 50,
      cy = 50;
    return {
      end: this.getCartesian(cx, cy, radius, endAngle),
      start: this.getCartesian(cx, cy, radius, startAngle)
    };
  }

  public pathString(radius, startAngle, endAngle, largeArc) {
    var coords = this.getDialCoords(radius, startAngle, endAngle),
      start = coords.start,
      end = coords.end,
      largeArcFlag = typeof (largeArc) === "undefined" ? 1 : largeArc;

    return [
      "M", start.x, start.y,
      "A", radius, radius, 0, largeArcFlag, 1, end.x, end.y
    ].join(" ");
  }

  public getValueInPercentage(value) {
    var newMax = 100 - 0, newVal = value - 0;
    return 100 * newVal / newMax;
  }

  public getAngle(percentage, gaugeSpanAngle) {
    return percentage * gaugeSpanAngle / 100;
  }

  public normalize(value, min, limit) {
    var val = Number(value);
    if (val > limit) return limit;
    if (val < min) return min;
    return val;
  }
}
