import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from 'src/app/services/eventBus.service';
import { DataService } from '../data.service';
import { ImagesService } from 'src/app/services/images.service';
import { PdfService } from 'src/app/services/pdf.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-challenges-crud',
  templateUrl: './admin-challenges-crud.component.html',
  styleUrls: ['./admin-challenges-crud.component.scss']
})
export class AdminChallengesCrudComponent implements OnInit {
  @Input() challenge: any;
  @Input() isModify: boolean;
  loading: boolean = true;
  tittle: string;
  description: string;
  startDate: string;
  endDate: string;
  imageDone: string;
  imageDoneUpdated: boolean = false;
  imageUndone: string;
  imageUndoneUpdated: boolean = false;
  imageDoneError: string;
  imageUndoneError: string;
  isImageDoneSaved: boolean;
  isImageUndoneSaved: boolean;
  videoLink: string;
  fileUpdated: boolean = false;
  pdfpath: any;
  pdf: any;
  pdfSplitted: string;
  savingChallenge: boolean = false;
  basepath: string;
  constructor(private data: DataService, private eventbus: EventBusService, private images: ImagesService, private pdfService: PdfService, private toastr: ToastrService) { }

  ngOnInit(): void {
    if (!this.isModify) {
      this.loading = false;
    }
    if (this.challenge) {
      this.isModify = true;
      this.tittle = this.challenge.tittle;
      this.description = this.challenge.description;
      this.startDate = this.challenge.startDate;
      this.endDate = this.challenge.endDate;
      this.imageDone = this.challenge.imageDone;
      if (this.imageDone) {
        this.isImageDoneSaved = true;
      }
      this.imageUndone = this.challenge.imageUndone;
      if (this.imageUndone) {
        this.isImageUndoneSaved = true;
      }
      this.videoLink = this.challenge.videoLink;
      this.pdf = this.challenge.pdf;
      this.pdfSplitted = this.challenge.pdf.split("\\").pop();
      this.loading = false;
    }
  }

  ngOnChanges(changes) {
    if (((changes || {}).challenge || {}).currentValue) {
      this.tittle = changes.challenge.currentValue.tittle;
      this.description = changes.challenge.currentValue.description;
      this.startDate = changes.challenge.currentValue.startDate;
      this.endDate = changes.challenge.currentValue.endDate;
      this.imageDone = changes.challenge.currentValue.imageDone;
      if (this.imageDone) {
        this.isImageDoneSaved = true;
      }
      this.imageUndone = changes.challenge.currentValue.imageUndone;
      if (this.imageUndone) {
        this.isImageUndoneSaved = true;
      }
      this.videoLink = changes.challenge.currentValue.videoLink;
      this.pdf = changes.challenge.currentValue.pdf;
      this.pdfSplitted = changes.challenge.currentValue.pdf.split("\\").pop();
      this.loading = false;
    }
  }

  createChallenge(): void {
    if (this.tittle && this.description && this.startDate && this.endDate && this.isImageUndoneSaved && this.isImageDoneSaved && !this.savingChallenge) {
      this.savingChallenge = true;
      this.data.createChallenge(this.tittle, this.description, this.startDate, this.endDate,
        this.imageDone['changingThisBreaksApplicationSecurity'], this.imageUndone['changingThisBreaksApplicationSecurity'],
        this.videoLink, this.pdfpath).subscribe(
          (res: any) => {
            this.toastr.success('El reto se ha creado correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
            this.savingChallenge = false;
            this.backAndReloadChallengesList();
          }, (error) => {
            this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' });
            this.savingChallenge = false;
          });
    }
  }

  modifyChallenge(): void {
    if (this.tittle && this.description && this.startDate && this.endDate && this.isImageUndoneSaved && this.isImageDoneSaved && !this.savingChallenge) {
      this.savingChallenge = true;
      this.challenge.tittle = this.tittle;
      this.challenge.description = this.description;
      this.challenge.startDate = this.startDate;
      this.challenge.endDate = this.endDate;
      this.challenge.imageDone = this.imageDone['changingThisBreaksApplicationSecurity'];
      this.challenge.imageDoneUpdated = this.imageDoneUpdated;
      this.challenge.imageUndone = this.imageUndone['changingThisBreaksApplicationSecurity'];
      this.challenge.imageUndoneUpdated = this.imageUndoneUpdated;
      this.challenge.videoLink = this.videoLink;
      this.challenge.pdf = (this.pdfpath || {}).archive || this.pdf;
      this.challenge.fileUpdated = this.fileUpdated;
      this.data.modifyChallenge(this.challenge).subscribe(
        (res: any) => {
          this.toastr.success('El reto se ha modificado correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
          this.savingChallenge = false;
          this.backAndReloadChallengesList();
        }, (error) => {
          this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' });
          this.savingChallenge = false;
        });
    }
  }

  backAndReloadChallengesList(): void {
    this.eventbus.emit({ name: "backAndReloadChallengesList", value: "list" })
  }

  uploadFile(ev) {
    this.pdfService.convertToBase64(ev).then((res: string) => {
      this.pdfpath = res;
      this.fileUpdated = true;
    });
  }

  deletePdfFile() {
    this.fileUpdated = true;
    this.pdfpath = null;
    this.pdf = null;
  }

  uploadDoneImage(fileInput: any) {
    this.images.imageLoad(fileInput).then(res => {
      this.imageDoneError = res['error'];
      this.isImageDoneSaved = res['saved'];
      this.imageDone = res['data'];
      this.imageDoneUpdated = true;
    });
  }

  async uploadUndoneImage(fileInput: any) {
    this.images.imageLoad(fileInput).then(res => {
      this.imageUndoneError = res['error'];
      this.isImageUndoneSaved = res['saved'];
      this.imageUndone = res['data'];
      this.imageUndoneUpdated = true;
    });
  }

  removeDoneImage() {
    this.imageDoneUpdated = true;
    this.imageDone = null;
    this.isImageDoneSaved = false;
  }

  removeUndoneImage() {
    this.imageUndoneUpdated = true;
    this.imageUndone = null;
    this.isImageUndoneSaved = false;
  }
}
