import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'challenge-special',
  templateUrl: './challenge-special.component.html',
  styleUrls: ['./challenge-special.component.scss']
})
export class ChellengeSpecialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
