import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from 'src/app/services/eventBus.service';
import { AuthService } from '../../login/authentication.service';
import { DataService } from './data.service';

@Component({
  selector: 'website-initiatives',
  templateUrl: './initiatives.component.html',
  styleUrls: ['./initiatives.component.scss']
})
export class InitiativesComponent implements OnInit {
  @Input() schools: any;
  loading: boolean;
  initiativesLoginModalOpened: boolean;
  initiativesVoteModalOpened: boolean;
  initiativesOkModalOpened: boolean;
  loadingSchool: boolean;
  dropdownOpened: boolean;
  initiativesData: any;
  initiativeToVote: any;
  currentUser: any;
  listeners: Array<any> = [];

  constructor(private data: DataService, private eventbus: EventBusService, private authService: AuthService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.loading = true;
    this.loadingSchool = true;
    this.dropdownOpened = false;
    this.listeners.push(this.eventbus.on("closeModal", () => {
      this.initiativesLoginModalOpened = false;
      this.initiativesVoteModalOpened = false;
      this.initiativesOkModalOpened = false;
    }));
    this.listeners.push(this.eventbus.on("voteModal", (initiative) => {
      this.performVote(initiative)
    }));
    this.currentUser = this.authService.currentUserValue;
    this.data.getData((this.currentUser || {}).id || false).subscribe((res: any) => {
      this.initiativesData = res;
      this.loading = false;
    });
  }

  vote(initiative) {
    this.initiativeToVote = initiative;
    const isLoggedIn = this.currentUser && this.currentUser.token;
    if (!isLoggedIn) {
      this.switchInitiativesModal('login');
    } else {
      this.switchInitiativesModal('vote');
    }
  }

  performVote(initiative) {
    initiative.voted = true;
    this.data.vote(initiative.id).subscribe((res: any) => {
      initiative.votes++;
      this.initiativesVoteModalOpened = false;
      this.initiativesOkModalOpened = true;
    });
  }

  hasRole(role) {
    if (!this.currentUser) {
      return true;
    } else {
      var dict = this.currentUser.permissionsDictionary;
      return (dict["Initiatives"] || []).includes(role);
    }
  }

  switchDropdown(): void {
    this.dropdownOpened = !this.dropdownOpened;
  }

  switchInitiativesModal(type: string): void {
    if (type === 'login') {
      this.initiativesLoginModalOpened = true;
    } else if (type === 'vote') {
      this.initiativesVoteModalOpened = true;
    }
  }

  ngOnDestroy() {
    this.listeners.forEach(x => x.unsubscribe());
  }
}
