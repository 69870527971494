import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EventBusService } from 'src/app/services/eventBus.service';
import { DataService } from '../data.service';

@Component({
  selector: 'app-admin-advices-list',
  templateUrl: './admin-advices-list.component.html',
  styleUrls: ['./admin-advices-list.component.scss']
})
export class AdminAdvicesListComponent implements OnInit {
  public advices: Array<any>;
  public paginatedAdvices: Array<any>;
  public loading: boolean;
  listeners: Array<any> = [];

  constructor(private data: DataService, private eventbus: EventBusService, private toastr: ToastrService) {
    this.loading = true;
    this.advices = undefined;
  }

  ngOnInit(): void {
    this.getAdvices();
    this.listeners.push(this.eventbus.on("deleteadvicesEmitter", (data) => {
      this.deleteAdvice(data);
    }));
    this.listeners.push(this.eventbus.on("deleteSelectedadvicesEmitter", (data) => {
      var ids = data.map(a => a.id);
      this.deleteAdvices(ids);
    }));
  }

  deleteAdvice(advice): void {
    this.data.deleteAdvice(advice.id).subscribe((res: any) => {
      this.toastr.success('Consejo eliminado correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
      this.getAdvices();
    },
      (error) => {
        this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' })
      });
  }

  deleteAdvices(advices): void {
    this.data.deleteAdvices(advices).subscribe((res: any) => {
      this.toastr.success('Consejos eliminados correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
      this.getAdvices();
    },
      (error) => {
        this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' })
      });
  }

  getAdvices(): void {
    this.data.getAdvicesList().subscribe((res: any) => {
      this.advices = res;
      this.loading = false;
    },
      (error) => {
        this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' })
      });
  }

  ngOnDestroy() {
    this.listeners.forEach(x => x.unsubscribe());
  }
}
