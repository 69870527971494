<div class="initiatives-description">
    <div class="slider">
        <ng-container *ngFor="let initiative of initiatives;let i=index">
            <div *ngIf="num === i" class="slide" @carouselAnimation>
                <card-title> {{initiative.tittle}}</card-title>
                <card-content>
                    <p class="initiatives-description-text">
                        {{initiative.description}}
                    </p>
                    <div class="initiatives-description-picture">
                        <img src="{{initiative.icon}}" onerror="this.src='../../../../../assets/img/placeholder.png'">
                    </div>
                </card-content>
            </div>
        </ng-container>
        <div class="page-points">
            <span (click)="goToSlide(i)" [ngClass]="{'selected': num === i }"
                *ngFor="let initiative of initiatives;let i=index"></span>
        </div>
    </div>
</div>