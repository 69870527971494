import { Injectable, SecurityContext } from '@angular/core';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
@Injectable({ providedIn: 'root' })
export class ImagesService {
    constructor(private domSanitizer: DomSanitizer) { }

    imageLoad(fileInput: any) {
        return new Promise((resolve, reject) => {
            var returnObj = {
                error: null,
                saved: false,
                data: undefined
            };
            if (fileInput.target.files && fileInput.target.files[0]) {
                // Size Filter Bytes
                const max_size = 20971520;
                const allowed_types = ['image/png', 'image/jpeg', 'image/svg+xml'];
                const max_height = 1500;
                const max_width = 1500;
                if (fileInput.target.files[0].size > max_size) {
                    returnObj.error = 'El máximo tamaño permitido es ' + max_size / 1000 + 'Mb';
                    resolve(returnObj);
                }
                if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
                    returnObj.error = 'Solo se permiten ficheros con formato de imagen ( JPG | PNG | SVG )';
                    resolve(returnObj);
                }
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    const image = new Image();
                    image.src = e.target.result;
                    image.onload = rs => {
                        const img_height = rs.currentTarget['height'];
                        const img_width = rs.currentTarget['width'];
                        if (img_height > max_height && img_width > max_width) {
                            returnObj.error = 'Dimensiones máximas permitidas ' + max_height + ' x ' + max_width + ' px';
                            resolve(returnObj);
                        } else {
                            returnObj.data = this.domSanitizer.bypassSecurityTrustUrl(e.target.result);
                            returnObj.saved = true;
                            resolve(returnObj);
                        }
                    };
                };
                reader.readAsDataURL(fileInput.target.files[0]);
            }
        });
    }
}