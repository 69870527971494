import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from './services/configuration.service';
import { environment}  from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AppProvider {
    group: string = "ProyectoSenda";
    urlConfig: string = "api/Config/getconfig";
    constructor(private http: HttpClient, private config: ConfigurationService) { }

    getConfiguration() {
        return this.http.get(environment.api + this.urlConfig).toPromise().then((res: any) => {
            var config = {};
            config["group"] = this.group;
            if (Array.isArray(res)) {
                res.forEach(x => {
                    config[x["keyword"]] = x["value"]
                });
                this.config.setConfiguration(config);
            }
        });
    }
}