import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from 'src/app/services/eventBus.service';
import { DataService } from './data.service';

@Component({
  selector: 'website-challenges',
  templateUrl: './challenges.component.html',
  styleUrls: ['./challenges.component.scss']
})
export class ChallengesComponent implements OnInit {
  @Input() schools: any;
  loadingSchool: boolean = true;
  challengeModalOpened: boolean;
  dropdownSchoolOpened: boolean;
  dropdownClassOpened: boolean;
  challengesData: any;
  challengeDetails: any;
  currentSchool: any;
  currentClass: any;
  listeners: Array<any> = [];

  constructor(private eventbus: EventBusService, private data: DataService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.challengeModalOpened = false;
    this.currentSchool = {};
    this.listeners.push(this.eventbus.on("closeModal", () => {
      this.showChallengeModal(0);
    }));
    this.listeners.push(this.eventbus.on("showChallengeModal", (data) => {
      this.showChallengeModal(data);
    }));
    this.data.getData().subscribe((res: any) => {
      this.challengesData = res;
      this.loadSchoolData(this.schools[0], this.schools[0]['class'][0]);
    });

    window.onclick = (e) => {
      var buttonBoxSelector = document.getElementsByClassName('school_select-box')[0];
      var buttonSelector = document.getElementsByClassName('school-name')[0];
      var buttonSpanSelector = document.getElementsByClassName('school-name-span')[0];
      var buttonImgSelector = document.getElementsByClassName('school-name-img')[0];
      var buttonISelector = document.getElementsByClassName('school-name-i')[0];
      var dropdownSelector = document.getElementsByClassName('school_select-dropdown')[0];
      var dropdownElementSelector = document.getElementsByClassName('school_select-dropdown-element');

      var buttonBoxSelectorClass = document.getElementsByClassName('class_select-box')[0];
      var buttonSelectorClass = document.getElementsByClassName('class-name')[0];
      var buttonSpanSelectorClass = document.getElementsByClassName('class-name-span')[0];
      var buttonISelectorClass = document.getElementsByClassName('class-name-i')[0];
      var dropdownSelectorClass = document.getElementsByClassName('class_select-dropdown')[0];
      var dropdownElementSelectorClass = document.getElementsByClassName('class_select-dropdown-element');

      if (e.target != buttonBoxSelector &&
        e.target != buttonSelector &&
        e.target != buttonSpanSelector &&
        e.target != buttonImgSelector &&
        e.target != buttonISelector &&
        e.target != dropdownSelector &&
        e.target != dropdownElementSelector) {
        this.dropdownSchoolOpened = false;
      }
      if (e.target != buttonBoxSelectorClass &&
        e.target != buttonSelectorClass &&
        e.target != buttonSpanSelectorClass &&
        e.target != buttonISelectorClass &&
        e.target != dropdownSelectorClass &&
        e.target != dropdownElementSelectorClass) {
        this.dropdownClassOpened = false;
      }
    }
  }

  loadSchoolData(school: any, clase: any): void {
    this.dropdownSchoolOpened = false;
    this.dropdownClassOpened = false;
    if (school.schoolId === this.currentSchool.schoolId && clase.classId === (this.currentClass || {}).classId) {
      return;
    }
    this.loadingSchool = true;
    this.currentSchool = school;
    this.currentClass = clase;
    this.data.getDataBySchoolClass(clase.classId).subscribe((res: any) => {
      this.loadingSchool = false;
      (this.challengesData || {}).challengeList = res;
    });
  }

  switchSchoolDropdown(): void {
    this.dropdownSchoolOpened = !this.dropdownSchoolOpened;
  }

  switchClassDropdown(): void {
    this.dropdownClassOpened = !this.dropdownClassOpened;
  }

  showChallengeModal(id: number): void {
    if (id === 0) {
      this.challengeModalOpened = false;
      this.challengeDetails = null;
    } else {
      this.challengeModalOpened = !this.challengeModalOpened;
      this.data.getDetails(id).subscribe((res: any) => {
        this.challengeDetails = res;
      });
    }
  }

  ngOnDestroy() {
    this.listeners.forEach(x => x.unsubscribe());
  }
}
