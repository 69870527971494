import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EventBusService } from 'src/app/services/eventBus.service';
import { DataService } from '../data.service';

@Component({
  selector: 'app-admin-challenges-list',
  templateUrl: './admin-challenges-list.component.html',
  styleUrls: ['./admin-challenges-list.component.scss']
})
export class AdminChallengesListComponent implements OnInit {
  public challenges: Array<any>;
  public loading: boolean;
  listeners: Array<any> = [];

  constructor(private data: DataService, private eventbus: EventBusService, private toastr: ToastrService) {
    this.loading = true;
    this.challenges = undefined;
  }

  ngOnInit(): void {
    this.getChallenges();
    this.listeners.push(this.eventbus.on("deletechallengesEmitter", (data) => {
      this.deleteChallenge(data);
    }));
  }

  deleteChallenge(challenge): void {
    this.data.deleteChallenge(challenge.challengeId).subscribe((res: any) => {
      this.toastr.success('Reto eliminado correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
      this.getChallenges()
    },
      (error) => {
        this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' })
      });
  }

  getChallenges(): void {
    this.data.getChallengesList().subscribe((res: any) => {
      this.challenges = res;
      this.loading = false;
    });
  }

  ngOnDestroy() {
    this.listeners.forEach(x => x.unsubscribe());
  }
}
