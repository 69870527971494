import { Component } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';
import { NavigationEnd, Router } from '@angular/router';

declare let gtag: (property: string, value: any, configs: any) => {};
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  returnUrl = '';
  isConnected = true;

  constructor(private connectionService: ConnectionService, private router: Router) {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.router.navigate(['/' + this.returnUrl]);
      }
      else {
        this.returnUrl = this.router.routerState.snapshot.url || "";
        this.router.navigate(['/reconnect']);
      }
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-199117765-4', {
          page_path: event.urlAfterRedirects
        });
      }
    });
  }

  async ngOnInit() { }
}
