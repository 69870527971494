import { Component, Input, OnInit } from '@angular/core';
import { SvgUtils } from 'src/app/components/common/svg/svg.utils';

@Component({
  selector: 'app-energy-consumption-chart',
  templateUrl: './energy-consumption-chart.component.html',
  styleUrls: ['./energy-consumption-chart.component.scss']
})
export class EnergyConsumptionChartComponent implements OnInit {
  @Input() identifier: number;
  @Input() isPresentation: boolean;
  @Input() consumption: any;

  constructor(private svgUtils : SvgUtils) { }

  ngOnInit(): void {
    var theValue = this.consumption.systemPercentage > 100 ? 100 : this.consumption.systemPercentage;
    var val = this.svgUtils.getValueInPercentage(theValue),
      angle = this.svgUtils.getAngle(val, 360 - Math.abs(135 - 45)),
      flag = angle <= 180 ? 0 : 1;
      var gaugeValuePath = document.getElementById("consumptionValue");
      gaugeValuePath.setAttribute("d", this.svgUtils.pathString(40, 135, angle + 135, flag));
  }
}
