<div class="users">
    <div *ngIf="section === 'list'">
        <div class="users_header">
            <p class="users_header-title">Usuarios</p>
            <div class="users_header-add" (click)="goToNew()" *ngIf="hasRole('write')">
                <span>Añadir usuario</span>
            </div>
        </div>
        <div class="users_section">
            <app-admin-users-list></app-admin-users-list>
        </div>
    </div>
    <div *ngIf="section === 'add' || section === 'modify'">
        <div class="users_header">
            <p class="users_header-title">
                <span class="users_header-breadcrumb" (click)="backToList()">
                    Usuarios
                </span>
                <span class="users_header-breadcrumb-slash"> | </span>
                <span *ngIf="section === 'add'">Nuevo Usuario</span>
                <span *ngIf="section === 'modify'">Modificar Usuario</span>
            </p>
        </div>
        <div class="users_section">
            <app-admin-users-crud *ngIf="section === 'add'"></app-admin-users-crud>
            <app-admin-users-crud *ngIf="section === 'modify'" [isModify]="true" [user]="userToModify">
            </app-admin-users-crud>
        </div>
    </div>
</div>