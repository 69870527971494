<modal [size]="'m'" *ngIf="type === 'login'">
    <div class="votes-modal">
        <div class="votes-modal-alert">
            <p>Sólo podrás votar si estas registrado e inicias sesión</p>
        </div>
        <div class="votes-modal-text">
            <p>Selecciona una de las siguientes opciones para poder votar por esta iniciativa</p>
        </div>
        <div class="votes-modal-buttons">
            <div (click)="goToRegister()" class="register">
                <span>Registrarme</span>
            </div>
            <div (click)="goToLogin()" class="login">
                <span>Iniciar sesión</span>
            </div>
        </div>
    </div>
</modal>
<modal [size]="'m'" *ngIf="type === 'vote'">
    <div class="votes-modal">
        <div class="votes-modal-alert">
            <p>¿Estás seguro que quieres votar por {{initiative.tittle}}?</p>
        </div>
        <div class="votes-modal-text">
            <p>Una vez que pinches en Votar, no podrás volver a votar o cambiar tu voto.</p>
        </div>
        <div class="votes-modal-buttons">
            <div (click)="voteModal()" class="vote">
                <span>Votar</span>
            </div>
        </div>
    </div>
</modal>
<modal [size]="'m'" *ngIf="type === 'ok'">
    <div class="votes-modal">
        <div class="votes-modal-alert">
            <p>¡Enhorabuena! Tu voto ha sido enviado</p>
        </div>
        <div class="votes-modal-text">
            <p>Permanece atento. Pronto sabremos la iniciativa ganadora</p>
        </div>
        <div (click)="closeModal()" class="votes-modal-buttons">
            <div class="understand">
                <span>Entendido</span>
            </div>
        </div>
    </div>
</modal>