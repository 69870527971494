import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'energy-comparative',
  templateUrl: './energy-comparative.component.html',
  styleUrls: ['./energy-comparative.component.scss']
})
export class EnergyComparativeComponent implements OnInit {
  @Input() comparative: any;
  @Input() isPresentation: boolean;
  @Input() isWidget: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
