import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private urlCreate: string = "api/User/createuserregister";
  private schoolsUrl: string = "api/InitialLoad/getinitialload";
  private textsUrl: string = "api/User/getregister";
  public configuration: any;
  constructor(private http: HttpClient, private config: ConfigurationService) { }

  getSchools(): any {
    this.configuration = this.config.getConfiguration();
    return this.http.get(environment.api + this.schoolsUrl + "?groupIdentity=" + this.configuration.group);
  }

  getTexts(): any {
    return this.http.get(environment.api + this.textsUrl + "?groupIdentity=" + this.configuration.group);
  }

  createUser(lastName, userName, login, password, email, school, children): any {
    return this.http.post(environment.api + this.urlCreate,
      {
        lastName,
        userName,
        login,
        password,
        email,
        schoolId: (school || {}).schoolId ? parseInt(school.schoolId) : null,
        rolId: 4,
        children: children ? parseInt(children) : 0,
        group: this.configuration.group
      }
    )
  }
}
