<div class="website-menu-content">
    <img src="../../../assets/img/icon/logo-senda-white.svg" class="logo-senda" (click)="goToLanding()">
    <div class="menu-buttons">
        <div class="senda-button" [ngClass]="{'selected': currentSection == 'senda'}" (click)="goToSection('senda')">
            <i class="icon-letter-s"></i>
            <span>Senda</span>
        </div>
        <div class="discover-button" [ngClass]="{'selected': currentSection == 'descubre'}"
            (click)="goToSection('descubre')">
            <i class="icon-search"></i>
            <span>Descubre</span>
        </div>
        <div class="challenge-button" [ngClass]="{'selected': currentSection == 'retos'}"
            (click)="goToSection('retos')">
            <i class="icon-ribbon"></i>
            <span>Retos</span>
        </div>
        <!--<div class="initiative-button" [ngClass]="{'selected': currentSection == 'iniciativas'}"
            (click)="goToSection('iniciativas')">
            <i class="icon-bulb"></i>
            <span>Iniciativas</span>
        </div>-->
        <!--<div class="presentation-button" [ngClass]="{'selected': currentSection == 'presentacion'}"
            (click)="goToSection('presentacion')">
            <i class="icon-television"></i>
            <span>Presentación</span>
        </div>-->
        <profile></profile>
    </div>
</div>