<div class="header">
    <div class="project_name">
        <img src="../assets/img/icon/four_points.png">
        <span>PROYECTO SENDA</span>
    </div>
    <profile *ngIf="!isPresentation"></profile>
    <div *ngIf="isPresentation" class="school_name" (click)="goToAdmin()">
        <span>{{name}}</span>
        <div class="profile_avatar">
            <img src="{{icon}}" class="avatar" onerror="this.src='../assets/img/placeholder.png'">
        </div>
    </div>
</div>