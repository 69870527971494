import { Component, OnInit } from '@angular/core';
import { AuthService } from './authentication.service'
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  username: string;
  password: string;
  error: string;
  returnUrl: string;
  doingLogin: boolean;

  constructor(private router: Router, private auth: AuthService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    if (this.auth.currentUserValue) {
      // already logged in so redirect
      this.router.navigate(['/']);
    } else {
      this.username = "";
      this.password = "";
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }
  }

  login(): void {
    if (!this.doingLogin) {
      this.doingLogin = true;
      this.auth.login(this.username, this.password)
        .pipe(first())
        .subscribe(
          data => {
            this.doingLogin = false;
            this.router.navigate([this.returnUrl]);
          },
          error => {
            this.error = error;
            this.username = "";
            this.password = "";
            this.doingLogin = false;
          });
    }
  }
}
