import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-initiatives-ranking-list',
  templateUrl: './initiatives-ranking-list.component.html',
  styleUrls: ['./initiatives-ranking-list.component.scss']
})
export class InitiativesRankingListComponent implements OnInit {
  @Input() initiative: any;
  constructor() { }

  ngOnInit(): void {
  }
}
