import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'energy-generated',
  templateUrl: './energy-generated.component.html',
  styleUrls: ['./energy-generated.component.scss']
})
export class EnergyGeneratedComponent implements OnInit {
  @Input() generated: any;
  @Input() isPresentation: boolean;
  @Input() isWidget: boolean;
  
  constructor() { }

  ngOnInit(): void {
  }

}
