import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { environment}  from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private dataUrl: string = "api/Challenge/getchallenge";
  private dataSchoolUrl: string = "api/Challenge/getchallengeclass";
  private detailsUrl: string = "api/Challenge/getchallengedetail";
  constructor(private http: HttpClient, private config: ConfigurationService) { }

  getData(): any {
    var configuration = this.config.getConfiguration();
    return this.http.get(environment.api + this.dataUrl + "?groupIdentity=" + configuration.group);
  }

  getDetails(id: number): any {
    return this.http.get(environment.api + this.detailsUrl + "?challengeId=" + id);
  }

  getDataBySchoolClass(classId: number): any {
    return this.http.get(environment.api + this.dataSchoolUrl + "?classId=" + classId);
  }
}
