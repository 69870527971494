<div class="background" [ngClass]="{'inPresentation': isPresentation}">
    <img *ngIf="!isPresentation" class="sun" src="../../../../../assets/img/icon/sun.svg">
    <img class="bg-back-grey" src="../../../../../assets/img/background/bg-back-grey.svg"
        [@opacityInvChanged]=currentState [@leftChanged]="{value :
        slide, params:{slider: (ratioBack * (slide || 0)) + 'em'}}">
    <img class="bg-front-grey" src="../../../../../assets/img/background/bg-front-grey.svg"
        [@opacityInvChanged]=currentState [@leftChanged]="{value :
        slide, params:{slider: (ratio * (slide || 0)) + 'em'}}">
    <img class="bg-back-green" src="../../../../../assets/img/background/bg-back-green.svg"
        [@opacityChanged]=currentState [@leftChanged]="{value :
            slide, params:{slider: (ratioBack * (slide || 0)) + 'em'}}">
    <img class="e-1" src="../../../../../assets/img/background/ellipse-1.svg" [@opacityChanged]=currentState
        [@ellipseRotateChanged]=currentState [@ellipseLeftChanged]="{value :
        slide, params:{slider: (ratio * (slide || 0)) + 'em'}}">
    <img class="e-2" src="../../../../../assets/img/background/ellipse-2.svg" [@opacityChanged]=currentState
        [@ellipseRotateChanged]=currentState [@ellipseLeftChanged]="{value :
        slide, params:{slider: (ratio * (slide || 0)) + 'em'}}">
    <img class="e-3" src="../../../../../assets/img/background/ellipse-3.svg" [@opacityChanged]=currentState
        [@ellipseRotateChanged]=currentState [@ellipseLeftChanged]="{value :
        slide, params:{slider: (ratio * (slide || 0)) + 'em'}}">
    <img class="e-4" src="../../../../../assets/img/background/ellipse-4.svg" [@opacityChanged]=currentState
        [@ellipseRotateChanged]=currentState [@ellipseLeftChanged]="{value :
        slide, params:{slider: (ratio * (slide || 0)) + 'em'}}">
    <img class="e-5" src="../../../../../assets/img/background/ellipse-5.svg" [@opacityChanged]=currentState
        [@ellipseRotateChanged]=currentState [@ellipseLeftChanged]="{value :
        slide, params:{slider: (ratio * (slide || 0)) + 'em'}}">
    <img class="e-6" src="../../../../../assets/img/background/ellipse-6.svg" [@opacityChanged]=currentState
        [@ellipseRotateChanged]=currentState [@ellipseLeftChanged]="{value :
        slide, params:{slider: (ratio * (slide || 0)) + 'em'}}">
    <img class="e-7" src="../../../../../assets/img/background/ellipse-7.svg" [@opacityChanged]=currentState
        [@ellipseRotateChanged]=currentState [@ellipseLeftChanged]="{value :
        slide, params:{slider: (ratio * (slide || 0)) + 'em'}}">
    <img class="bg-front-green" src="../../../../../assets/img/background/bg-front-green.svg"
        [@opacityChanged]=currentState [@leftChanged]="{value :
            slide, params:{slider: (ratio * (slide || 0)) + 'em'}}">
</div>