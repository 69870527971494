<div class="weather">
    <div class="grid_weather">
        <div class="left-top-weather">
            <weather-today [today]="weather.today"></weather-today>
        </div>
        <div class="left-bottom-weather">
            <weather-next-week [nextWeek]="weather.nextWeek"></weather-next-week>
        </div>
        <div class="right-weather">
            <card>
                <card-title> comparativa generación energía </card-title>
                <card-content>
                       <weather-comparative [comparative]="weather.weatherComparative"></weather-comparative>
                </card-content>
            </card>
        </div>
    </div>
</div>