<loading *ngIf="loading"></loading>
<div *ngIf="!loading" class="initiatives-crud">
    <p class="title-crud" *ngIf="!isModify">Subir nueva iniciativa</p>
    <p class="title-crud" *ngIf="isModify">Modificar iniciativa #{{initiative.id}}</p>
    <div class="initiatives-form">
        <p class="label-name">Nombre de la iniciativa <span>(*obligatorio)</span>
        </p>
        <div class="input-name">
            <div class="input-name-icon">
                <i class="material-icons">article</i>
            </div>
            <div class="input-name-text">
                <input [(ngModel)]="tittle" type="text" name="tittle" autocomplete="off"
                    placeholder="Escribe aquí el nombre de la iniciativa..." maxlength="80" />
            </div>
        </div>
        <p class="label-description">Descripción <span>(*obligatorio)</span></p>
        <div class="input-description">
            <textarea [(ngModel)]="description" name="description"
                placeholder="Haz una descripción para que los usuarios puedan entender de qué se trata..."
                maxlength="300"></textarea>
        </div>
        <div class="date">
            <!--<div class="date-select">
                <p class="label-icon">Fecha fin <span>(*obligatorio)</span></p>
                <div class="date-select-input">
                    <input type="date" [ngModel]="endDate" (ngModelChange)="endDate = $event">
                    <div class="date-select-input-icon">
                        <i class="material-icons">date_range</i>
                    </div>
                    <div class="date-select-input-value">
                        <span>{{!endDate ? 'dd/mm/aaaa' : endDate | date:'dd/MM/yyyy'}}</span>
                        <i class="material-icons">keyboard_arrow_down</i>
                    </div>
                </div>
            </div>-->
            <div class="date-select">
                <p class="label-icon">Coste <span>(*obligatorio)</span></p>
                <div class="input-name">
                    <div class="input-name-icon">
                        <i class="material-icons">euro</i>
                    </div>
                    <div class="input-name-text">
                        <input [(ngModel)]="amount" type="number" name="amount" autocomplete="off"
                            placeholder="Coste en euros..." />
                    </div>
                </div>
            </div>
        </div>
        <p class="label-icon">Imagen <span>(*obligatorio)</span></p>
        <div class="img-space">
            <ng-container *ngIf="isImageSaved; else elseTemplate">
                <img [src]="icon" />
            </ng-container>
            <ng-template #elseTemplate>
                <div class="input-icon">
                    <input type="file" (change)="uploadIcon($event)" />
                    <i class="material-icons">highlight_alt</i>
                    <span>Seleccionar imagen</span>
                </div>
            </ng-template>
            <a class="btn-delete" (click)="removeImage()" *ngIf="isImageSaved">Eliminar icono</a>
            <span class="error-msg">{{imageError}}</span>
        </div>
        <div class="add-initiatives"
            [ngClass]="{'disabled': !tittle || !description || !isImageSaved || !amount || savingInititative}">
            <div *ngIf="!isModify" class="submit" (click)="createInitiative()">
                <loading *ngIf="savingInititative" [isButton]="true"></loading>
                <p>Subir iniciativa</p>
            </div>
            <div *ngIf="isModify" class="submit" (click)="modifyInitiative()">
                <loading *ngIf="savingInititative" [isButton]="true"></loading>
                <p>Modificar iniciativa</p>
            </div>
        </div>
    </div>
</div>