import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'slide-energy',
  templateUrl: './energy.component.html',
  styleUrls: ['./energy.component.scss']
})
export class EnergyComponent implements OnInit {
  @Input() energy: {
    comparative: {},
    consumption: {},
    generated: {},
    piggyBank: {},
  }
  @Input() isPresentation: boolean;
  constructor() { }

  ngOnInit(): void { }

}
