<div class="login">
    <div class="login-content">
        <p class="login-title">Bienvenido al portal <br> del proyecto <span>senda</span></p>
        <div class="login-box">
            <div class="header-logo">
                <div class="alert alert-danger" role="alert" *ngIf="error">
                    {{error}}
                </div>
                <img src="../../../assets/img/icon/logo-senda.svg">
            </div>

            <div class="login-form">
                <div class="email" [ngClass]="{'validation-error': error}">
                    <input [(ngModel)]="username" autocomplete="off" type="text" name="email" placeholder="Usuario"
                        (keyup.enter)="login()" />
                    <i class="material-icons"> person</i>
                </div>
                <div class="password" [ngClass]="{'validation-error': error}">
                    <input [(ngModel)]="password" type="password" name="password" placeholder="Contraseña"
                        (keyup.enter)="login()" />
                    <i class="material-icons">
                        vpn_key</i>
                </div>
                <div class="remember">
                    <!-- <div class="check"></div>
                    <span>Recordar mis datos</span>-->
                </div>

                <div class="submit" (click)="login()" [ngClass]="{'disabled': !username || !password || doingLogin}">
                    <loading *ngIf="doingLogin" [isButton]="true"></loading>
                    <p>Iniciar Sesión</p>
                </div>
                <!--<div class="remember-password">
                    <span>¿Olvidaste tu contraseña?</span>
                </div>-->
            </div>
        </div>
    </div>
</div>