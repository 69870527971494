import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from 'src/app/services/eventBus.service';
import { DataService } from '../data.service';
import * as _ from 'lodash';
import { ImagesService } from 'src/app/services/images.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-equivalences-crud',
  templateUrl: './admin-equivalences-crud.component.html',
  styleUrls: ['./admin-equivalences-crud.component.scss']
})
export class AdminEquivalencesCrudComponent implements OnInit {
  @Input() equivalence: any;
  @Input() isModify: boolean;
  loading: boolean = true;
  tittle: string;
  description: string;
  unitPower: number;
  icon: string;
  imageError: string;
  isImageSaved: boolean;
  iconUpdated: boolean = false;
  savingEquivalence: boolean = false;
  constructor(private data: DataService, private eventbus: EventBusService, private images: ImagesService, private toastr: ToastrService) { }

  ngOnInit(): void {
    if (!this.isModify) {
      this.loading = false;
    }
    if (this.equivalence) {
      this.tittle = this.equivalence.tittle;
      this.description = this.equivalence.description;
      this.unitPower = this.equivalence.unitPower;
      this.icon = this.equivalence.icon;
      if (this.icon) {
        this.isImageSaved = true;
      }
    }
  }

  ngOnChanges(changes) {
    if (((changes || {}).equivalence || {}).currentValue) {
      this.tittle = changes.equivalence.currentValue.tittle;
      this.description = changes.equivalence.currentValue.description;
      this.unitPower = changes.equivalence.currentValue.unitPower;
      this.icon = changes.equivalence.currentValue.icon;
      if (this.icon) {
        this.isImageSaved = true;
      }
      this.loading = false;
    }
  }

  createEquivalence(): void {
    if (this.tittle && this.description && this.unitPower && this.isImageSaved && !this.savingEquivalence) {
      this.savingEquivalence = true;
      this.data.createEquivalence(this.tittle, this.description, this.unitPower, this.icon['changingThisBreaksApplicationSecurity']).subscribe(
        (response) => {
          this.toastr.success('La clase se ha creado correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
          this.savingEquivalence = false;
          this.backAndReloadEquivalencesList();
        },
        (error) => {
          this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' });
          this.savingEquivalence = false;
        });
    }
  }

  modifyEquivalence(): void {
    if (this.tittle && this.description && this.unitPower && this.isImageSaved && !this.savingEquivalence) {
      this.savingEquivalence = true;
      this.equivalence.tittle = this.tittle;
      this.equivalence.description = this.description;
      this.equivalence.unitPower = this.unitPower;
      this.equivalence.icon = this.icon['changingThisBreaksApplicationSecurity'];
      this.equivalence.iconUpdated = this.iconUpdated;
      this.data.modifyEquivalence(this.equivalence).subscribe((res: any) => {
        this.toastr.success('La clase se ha modificado correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
        this.savingEquivalence = false;
        this.backAndReloadEquivalencesList();
      },
        (error) => {
          this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' });
          this.savingEquivalence = false;
        });
    }
  }

  backAndReloadEquivalencesList(): void {
    this.eventbus.emit({ name: "backAndReloadEquivalencesList", value: "list" })
  }

  uploadIcon(fileInput: any) {
    this.images.imageLoad(fileInput).then(res => {
      this.imageError = res['error'];
      this.isImageSaved = res['saved'];
      this.icon = res['data'];
      this.iconUpdated = true;
    });
  }

  removeImage() {
    this.iconUpdated = true;
    this.icon = null;
    this.isImageSaved = false;
  }
}
