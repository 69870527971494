import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ConfigurationService {

    private configuration: any;
    constructor() { }

    setConfiguration(configuration: any) {
        this.configuration = configuration;
    }

    getConfiguration() {
        return this.configuration;
    }
}