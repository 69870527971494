<div class="discover">
    <div class="discover_header">

        <img src="../../../../assets/img/website-icon/Content/cloud.svg" class="cloud">

        <div class="discover_header-title">
            <i class="icon-search"></i>
            <div class="title-text">
                <p>Descubre</p>
                <span>Descubre cuánta energía se está generando en los diferentes centros gracias a las
                    placas solares.</span>
            </div>
        </div>
    </div>
    <loading *ngIf="!discoverData"></loading>
    <div class="discover_body" *ngIf="discoverData">
        <div class="discover_energy-summary">
            <p class="discover_body-title">En total, todos juntos estamos consiguiendo esto:</p>
            <div class="discover_energy-summary-content">
                <div class="generated-summary">
                    <energy-generated [generated]="discoverData.generated" [isPresentation]="false" [isWidget]="true">
                    </energy-generated>
                </div>
                <div class="saved-summary">
                    <energy-comparative [comparative]="discoverData.comparative" [isPresentation]="false"
                        [isWidget]="true">
                    </energy-comparative>
                </div>
                <div class="piggy-summary">
                    <energy-piggy-bank [piggyBank]="discoverData.piggyBank" [isPresentation]="false" [isWidget]="true">
                    </energy-piggy-bank>
                </div>
            </div>
        </div>

        <div class="discover_school">
            <p class="discover_body-title">¿Qué colegio quieres ver?</p>
            <div class="school_select">
                <i class="wicon-school"></i>
                <div class="school_select-box" (click)="switchDropdown()">
                    <div class="school-name">
                        <img class="school-name-img" src="{{currentSchool.icon}}"
                            onerror="this.src='../../../../assets/img/placeholder.png'">
                        <span class="school-name-span">{{currentSchool.name}}</span>
                    </div>
                    <i class="material-icons school-name-i">expand_more</i>
                </div>
                <div [hidden]="!dropdownOpened" class="school_select-dropdown">
                    <div class="school_select-dropdown-element" (click)="loadSchoolData(school)"
                        *ngFor="let school of schools">
                        <img src="{{school.icon}}" onerror="this.src='../../../../assets/img/placeholder.png'">
                        <span>{{school.name}}</span>
                    </div>
                </div>
            </div>
            <div class="school_data">
                <loading *ngIf="loadingSchool"></loading>
                <div *ngIf="!loadingSchool">
                    <div class="school_data-title">
                        <img src="{{discoverSchoolData.icon}}"
                            onerror="this.src='../../../../assets/img/placeholder.png'">
                        <span>{{discoverSchoolData.schoolName}}</span>
                    </div>
                    <p class="school_data-description">{{discoverSchoolData.schoolDescription}}</p>
                    <div class="school_data-main">
                        <div class="school_data-main-students">
                            <i class="wicon-group"></i>
                            <span>{{discoverSchoolData.studentNumber}}</span>
                        </div>
                        <div class="school_data-main-installation">
                            <i class="wicon-panel"></i>
                            <span>{{discoverSchoolData.instalationSize}}</span>
                        </div>
                        <div class="school_data-main-location">
                            <i class="wicon-meeting"></i>
                            <span>{{discoverSchoolData.location}}</span>
                        </div>
                    </div>
                    <div class="school_data-widgets-chart">
                        <slide-energy [energy]="discoverSchoolData.energy" [isPresentation]="false">
                        </slide-energy>
                    </div>
                </div>
            </div>

            <div class="school_board">
                <div class="school_board-ranking">
                    <p class="board-title">¿Quién está aprovechando mejor la energía del Sol?</p>
                    <div class="ranking-board">
                        <ranking-list [type]="'saving'" [ranking]="discoverData.saving.ranking"></ranking-list>
                    </div>
                </div>

                <div class="school_board-advices">
                    <p class="board-title">Consejo del día</p>
                    <div class="advices-board">
                        <saving-advices [advices]="discoverData.saving.advices"></saving-advices>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>