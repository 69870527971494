import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'slide-challenges',
  templateUrl: './challenges.component.html',
  styleUrls: ['./challenges.component.scss']
})
export class ChallengesSlideComponent implements OnInit {
  @Input() challenge: {
    rankingChallenges: {},
    challengeList: {}
  };

  constructor() {
  }

  ngOnInit(): void {

  }
}
