<app-background [isPresentation]="true"></app-background>
<div class="presentation">
    <app-header *ngIf="slides && !loadingSlides" [isPresentation]="true" [name]="slides.name" [icon]="slides.icon"></app-header>
    <loading *ngIf="loadingSlides" class="presentation-loading"></loading>
    <div class="slider" *ngIf="!loadingSlides">
        <div *ngIf="slides.energy && num === 0" class="slide" @carouselAnimation>
            <slide-energy [energy]="slides.energy" [isPresentation]="true"></slide-energy>
        </div>
        <div *ngIf="slides.weather && num === 1" class="slide" @carouselAnimation>
            <slide-weather [weather]="slides.weather"></slide-weather>
        </div>
        <div *ngIf="slides.challenge && num === 2" class="slide" @carouselAnimation>
            <slide-challenges [challenge]="slides.challenge"></slide-challenges>
        </div>
        <!--<div *ngIf="slides.initiative && num === 3" class="slide" @carouselAnimation>
            <slide-initiatives [initiative]="slides.initiative"></slide-initiatives>
        </div>-->
        <div *ngIf="slides.saving && num === 3" class="slide" @carouselAnimation>
            <slide-saving [saving]="slides.saving" [icon]="slides.icon"></slide-saving>
        </div>
        <!--<div *ngIf="num === 5">

        </div>-->
    </div>
    <div class="page-points" *ngIf="num !== null && !loadingSlides">
        <span (click)="goToSlide(0)" [ngClass]="{'selected': num === 0}"></span>
        <span (click)="goToSlide(1)" [ngClass]="{'selected': num === 1}"></span>
        <span (click)="goToSlide(2)" [ngClass]="{'selected': num === 2}"></span>
        <!--<span (click)="goToSlide(3)" [ngClass]="{'selected': num === 3}"></span>-->
        <span (click)="goToSlide(3)" [ngClass]="{'selected': num === 3}"></span>
        <!--<span (click)="goToSlide(5)" [ngClass]="{'selected': num === 5}"></span>-->
    </div>
</div>