import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from 'src/app/services/eventBus.service';

@Component({
  selector: 'pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() items: number;
  @Input() itemsPerPage: number;
  @Input() page: number = 1;
  @Input() totalPages: number;
  @Input() searched: number;

  constructor(private eventBus: EventBusService) { }
  ngOnInit() {
  }

  ngOnChanges(changes) {
    if (((changes || {}).searched || {}).currentValue) {
      this.totalPages = Math.ceil(changes.searched.currentValue / this.itemsPerPage);
      this.goToPage(this.page > this.totalPages ? this.page - 1 : this.page);
    }
  }
  next() {
    this.page++;
    this.moveTo();
  }
  back() {
    this.page--;
    this.moveTo();
  }
  goToPage(page) {
    this.page = page;
    this.moveTo();
  }
  moveTo() {
    this.eventBus.emit({ name: "paginateResults", value: { page: this.page } });
  }
}