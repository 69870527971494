import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'weather-comparative-day',
  templateUrl: './weather-comparative-day.component.html',
  styleUrls: ['./weather-comparative-day.component.scss']
})
export class WeatherComparativeDayComponent implements OnInit {
  @Input() day: any;
  @Input() img: any;

  constructor() { }

  ngOnInit(): void {
  }

}
