import { Component, Input, OnInit } from '@angular/core';
import { DataService } from './data.service';

@Component({
  selector: 'website-discover',
  templateUrl: './discover.component.html',
  styleUrls: ['./discover.component.scss']
})
export class DiscoverComponent implements OnInit {
  @Input() schools: any;
  loading: boolean;
  loadingSchool: boolean;
  dropdownOpened: boolean;
  discoverData: any;
  discoverSchoolData: any;
  currentSchool: any;
  constructor(private data: DataService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.loading = true;
    this.loadingSchool = true;
    this.dropdownOpened = false;
    this.currentSchool = {};
    this.data.getData().subscribe((res: any) => {
      this.discoverData = res;
      this.loading = false;
    });
    this.loadSchoolData(this.schools[0]);

    window.onclick = (e) => {
      var buttonBoxSelector = document.getElementsByClassName('school_select-box')[0];
      var buttonSelector = document.getElementsByClassName('school-name')[0];
      var buttonSpanSelector = document.getElementsByClassName('school-name-span')[0];
      var buttonImgSelector = document.getElementsByClassName('school-name-img')[0];
      var buttonISelector = document.getElementsByClassName('school-name-i')[0];
      var dropdownSelector = document.getElementsByClassName('school_select-dropdown')[0];
      var dropdownElementSelector = document.getElementsByClassName('school_select-dropdown-element');
      if (
        e.target != buttonBoxSelector && 
        e.target != buttonSelector && 
        e.target != buttonSpanSelector && 
        e.target != buttonImgSelector && 
        e.target != buttonISelector && 
        e.target != dropdownSelector && 
        e.target != dropdownElementSelector) {
        this.dropdownOpened = false;
      }
    }
  }

  loadSchoolData(school: any): void {
    this.dropdownOpened = false;
    if (school.schoolId === this.currentSchool.schoolId) {
      return;
    }
    this.loadingSchool = true;
    this.currentSchool = school;
    this.data.getDataBySchool(school.schoolId).subscribe((res: any) => {
      this.loadingSchool = false;
      this.discoverSchoolData = res;
    });
  }

  switchDropdown(): void {
    this.dropdownOpened = !this.dropdownOpened;
  }
}
