import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from 'src/app/services/eventBus.service';
import { DataService } from '../data.service';
import * as _ from 'lodash';
import { ImagesService } from 'src/app/services/images.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-advices-crud',
  templateUrl: './admin-advices-crud.component.html',
  styleUrls: ['./admin-advices-crud.component.scss']
})
export class AdminAdvicesCrudComponent implements OnInit {
  @Input() advice: any;
  @Input() isModify: boolean;
  loading: boolean = true;
  tittle: string;
  description: string;
  icon: string;
  imageError: string;
  isImageSaved: boolean;
  iconUpdated: boolean = false;
  savingAdvice: boolean = false;
  constructor(private data: DataService, private eventbus: EventBusService, private images: ImagesService, private toastr: ToastrService) { }

  ngOnInit(): void {
    if (!this.isModify) {
      this.loading = false;
    }
    if (this.advice) {
      this.tittle = this.advice.tittle;
      this.description = this.advice.description;
      this.icon = this.advice.icon;
      if (this.icon) {
        this.isImageSaved = true;
      }
      this.loading = false;
    }
  }

  ngOnChanges(changes) {
    if (((changes || {}).advice || {}).currentValue) {
      this.tittle = changes.advice.currentValue.tittle;
      this.description = changes.advice.currentValue.description;
      this.icon = changes.advice.currentValue.icon;
      if (this.icon) {
        this.isImageSaved = true;
      }
      this.loading = false;
    }
  }

  createAdvice(): void {
    if (this.tittle && this.description && this.isImageSaved && !this.savingAdvice) {
      this.savingAdvice = true;
      this.data.createAdvice(this.tittle, this.description, this.icon['changingThisBreaksApplicationSecurity']).subscribe(
        (response) => {
          this.toastr.success('El consejo se ha creado correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
          this.savingAdvice = false;
          this.backAndReloadAdvicesList();
        },
        (error) => {
          this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' });
          this.savingAdvice = false;
        });
    }
  }

  modifyAdvice(): void {
    if (this.tittle && this.description && this.isImageSaved && !this.savingAdvice) {
      this.savingAdvice = true;
      this.advice.tittle = this.tittle;
      this.advice.description = this.description;
      this.advice.icon = this.icon['changingThisBreaksApplicationSecurity'];
      this.advice.iconUpdated = this.iconUpdated;
      this.data.modifyAdvice(this.advice).subscribe((res: any) => {
        this.toastr.success('El consejo se ha modificado correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
        this.savingAdvice = false;
        this.backAndReloadAdvicesList();
      },
        (error) => {
          this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' });
          this.savingAdvice = false;
        });
    }
  }

  backAndReloadAdvicesList(): void {
    this.eventbus.emit({ name: "backAndReloadAdvicesList", value: "list" })
  }

  uploadIcon(fileInput: any) {
    this.images.imageLoad(fileInput).then(res => {
      this.imageError = res['error'];
      this.isImageSaved = res['saved'];
      this.icon = res['data'];
      this.iconUpdated = true;
    });
  }

  removeImage() {
    this.iconUpdated = true;
    this.icon = null;
    this.isImageSaved = false;
  }
}
