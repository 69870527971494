import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/components/login/authentication.service';

@Component({
  selector: 'profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {
  dropdownOpened: boolean = false;
  isLoggedIn: boolean;
  username: string;
  userimage: string;
  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit(): void {
    const currentUser = this.authService.currentUserValue;
    this.isLoggedIn = currentUser !== null;
    if (this.isLoggedIn) {
      this.username = (currentUser || {}).firstName;
      this.userimage = (currentUser || {}).icon || '';
    }
    // Add the listener:
    window.addEventListener('click', this.onClickListener, false);
    this.router.events.subscribe((val) => {
      // Remove it:
      window.removeEventListener('click', this.onClickListener, false);
      window.addEventListener('click', this.onClickListener, false);
    });
  }

  onClickListener = (e) => {
    var buttonProfile = document.getElementsByClassName('profile-button')[0];
    var buttonSpanSelector = document.getElementsByClassName('profile-button-name')[0];
    var buttonImgSelector = document.getElementsByClassName('profile-button-img')[0];
    var buttonISelector = document.getElementsByClassName('profile-button-i')[0];
    var dropdownSelector = document.getElementsByClassName('profile-dropdown')[0];
    var dropdownElementSelector = document.getElementsByClassName('profile-dropdown-element');
    if (
      e.target != buttonProfile &&
      e.target != buttonSpanSelector &&
      e.target != buttonImgSelector &&
      e.target != buttonISelector &&
      e.target != dropdownSelector &&
      e.target != dropdownElementSelector) {
      this.dropdownOpened = false;
    }
  }

  switchDropdown(): void {
    this.dropdownOpened = !this.dropdownOpened;
  }

  goToAdmin(): void {
    this.router.navigate(['/admin']);
  }

  goToLogin(): void {
    this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url } });
  }

  hasRole() {
    const currentUser = this.authService.currentUserValue;
    return this.authService.adminRoles.includes((currentUser || {}).rolId + "");
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
