import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from 'src/app/services/eventBus.service';
import { AuthService } from '../../login/authentication.service';

@Component({
  selector: 'table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  @Input() type: string;
  @Input() data: any;
  @Input() title: string;
  @Input() columns: any;
  @Input() itemsPerPage: number;
  @Input() hasSelection: boolean;
  @Input() hasSearch: boolean;
  public paginatedData: Array<any>;
  public searchedData: Array<any>;
  public allSelected: boolean;
  public search: string;
  listeners: Array<any> = [];

  constructor(private eventBus: EventBusService, private authenticationService: AuthService) { }
  ngOnInit() {
    this.searchedData = this.data;
    this.doPagination(this.data, 1);
    this.listeners.push(this.eventBus.on("paginateResults", (data) => {
      this.doPagination(this.data, data.page);
    }));
  }

  ngOnChanges(changes) {
    if (((changes || {}).data || {}).currentValue) {
      this.searchedData = changes.data.currentValue;
      this.doPagination(changes.data.currentValue, 1);
    }
  }

  doPagination(arr, page) {
    this.paginatedData = this.paginate(arr, this.itemsPerPage, page);
    if (this.paginatedData.length) {
      this.allSelected = false;
      this.selectAll(false);
    }
  }

  doSearch(ev) {
    this.searchedData = this.data.filter((item) => {
      return (
        (item.tittle || "").toLowerCase().includes(ev.toLowerCase()) ||
        (item.description || "").toLowerCase().includes(ev.toLowerCase()) ||
        (item.school || "").toLowerCase().includes(ev.toLowerCase())) ||
        (item.lastName || "").toLowerCase().includes(ev.toLowerCase()) ||
        (item.userName || "").toLowerCase().includes(ev.toLowerCase()) ||
        (item.email || "").toLowerCase().includes(ev.toLowerCase()) ||
        (item.login || "").toLowerCase().includes(ev.toLowerCase())
    });
    this.doPagination(this.searchedData, 1);
  }

  hasRole(role) {
    const currentUser = this.authenticationService.currentUserValue;
    var dict = currentUser.permissionsDictionary;
    return dict[this.type.charAt(0).toUpperCase() + this.type.slice(1)].includes(role);
  }

  selectAll(flag) {
    if (this.paginatedData[0])
      this.paginatedData.map(x => x.selected = flag);
  }

  areSelected(): number {
    return this.paginatedData[0] ? this.paginatedData.filter(x => x.selected).length : 0;
  }

  paginate(array, page_size, page_number) {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  modifyAction(item: any): void {
    this.eventBus.emit({ name: "modify" + this.type + "Emitter", value: item })
  }

  manageAction(challenge: any): void {
    this.eventBus.emit({ name: "manageChallengeEmitter", value: { challenge } })
  }

  deleteAction(item: any): void {
    var title = "";
    var targets = {
      'challenges': "el reto",
      'advices': "el consejo",
      'initiatives': "la iniciativa",
      'users': 'el usuario',
      'school': "el colegio",
      'class': "la clase",
      'equivalence': "la equivalencia",
    }
    if (this.type === 'users') {
      title = item.login;
    }
    if (this.type === 'school') {
      title = item.name;
    }
    if (this.type === 'class') {
      title = item.course + " " + item.class + " de " + item.school;
    }
    var response = confirm("¿Eliminar " + targets[this.type] + " " + (title || item.tittle) + "?");
    if (response === true) {
      this.eventBus.emit({ name: "delete" + this.type + "Emitter", value: item })
    }
  }

  deleteSelected() {
    var response = confirm("¿Eliminar todos los elementos seleccionados?");
    if (response === true) {
      this.eventBus.emit({ name: "deleteSelected" + this.type + "Emitter", value: this.paginatedData.filter(x => x.selected) })
    }
  }

  ngOnDestroy() {
    this.listeners.forEach(x => x.unsubscribe());
  }
}