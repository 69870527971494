<div class="equivalences">
    <div *ngIf="section === 'list'">
        <div class="equivalences_header">
            <p class="equivalences_header-title">Equivalencias</p>
            <div class="equivalences_header-add" (click)="goToNew()" *ngIf="hasRole('write')">
                <span>Añadir equivalencia</span>
            </div>
        </div>
        <div class="equivalences_section">
            <app-admin-equivalences-list></app-admin-equivalences-list>
        </div>
    </div>
    <div *ngIf="section === 'add' || section === 'modify'">
        <div class="equivalences_header">
            <p class="equivalences_header-title">
                <span class="equivalences_header-breadcrumb" (click)="backToList()">
                    Equivalencias
                </span>
                <span class="equivalences_header-breadcrumb-slash"> | </span>
                <span *ngIf="section === 'add'">Nuevo Equivalencia</span>
                <span *ngIf="section === 'modify'">Modificar Equivalencia</span>
            </p>
        </div>
        <div class="equivalences_section">
            <app-admin-equivalences-crud *ngIf="section === 'add'"></app-admin-equivalences-crud>
            <app-admin-equivalences-crud *ngIf="section === 'modify'" [isModify]="true" [equivalence]="equivalenceToModify">
            </app-admin-equivalences-crud>
        </div>
    </div>
</div>