import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from 'src/app/services/eventBus.service';
import { state, style, animate, trigger, transition } from "@angular/animations";

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss'],
  animations: [
    trigger('opacityChanged', [
      state('final', style({ opacity: 0, })),
      state('initial', style({ opacity: 1 })),
      transition('initial => final', animate('1000ms')),
      transition('final => initial', animate('500ms')),
    ]),
    trigger('opacityInvChanged', [
      state('initial', style({ opacity: 0, })),
      state('final', style({ opacity: 1 })),
      transition('initial => final', animate('1000ms')),
      transition('final => initial', animate('500ms')),
    ]),
    trigger('leftChanged', [
      state('*', style({ left: "{{slider}}", }), { params: { slider: '0%' } }),
      transition('* => *', animate('2500ms')),
    ]),
    trigger('ellipseLeftChanged', [
      state('*', style({ marginLeft: "{{slider}}", }), { params: { slider: '0%' } }),
      transition('* => *', animate('2500ms')),
    ]),
    trigger('ellipseRotateChanged', [
      state('final', style({ transform: 'rotate(0deg)' })),
      state('initial', style({ transform: 'rotate(360deg)' })),
      transition('final => initial', [animate('500ms'), animate('2500ms')]),
    ])
  ]
})
export class BackgroundComponent implements OnInit {
  @Input() isPresentation: boolean;
  listeners: Array<any> = [];
  slide: number;
  currentState: string = "final";
  ratio: number;
  ratioBack: number;
  constructor(private eventbus: EventBusService) { }

  ngOnInit(): void {
    var width = screen.width;
    this.ratio = width === 1280 ? -29.8 : width > 1920 ? -6.8 : -20.6;
    this.ratioBack = width === 1280 ? -20.6 : width > 1920 ? -2.2 : -13.2;
    this.listeners.push(this.eventbus.on("nextSlide",
      (data) => {
        this.changeState();
        this.sleep(500).then(() => {
          this.slide = data;
        });
        this.sleep(3000).then(() => {
          this.changeState();
        });
      }));
  }

  sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  changeState() {
    this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
  }

  ngOnDestroy() {
    this.listeners.forEach(x => x.unsubscribe());
  }
}
