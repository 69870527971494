import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'slide-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss']
})
export class WeatherComponent implements OnInit {
  @Input() weather: {
    today: {},
    nextWeek: {},
    weatherComparative: {}
  };
  constructor() { }

  ngOnInit(): void { }

}
