import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'slide-saving',
  templateUrl: './saving.component.html',
  styleUrls: ['./saving.component.scss']
})
export class SavingComponent implements OnInit {
  @Input() saving: {
    ranking: {},
    advices: {}
  };
  @Input() icon: string;

  constructor() {
  }

  ngOnInit(): void { }
}
