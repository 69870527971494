import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventBusService } from 'src/app/services/eventBus.service';
import { AuthService } from '../../login/authentication.service';
import { DataService } from './data.service';

@Component({
  selector: 'app-admin-equivalences',
  templateUrl: './admin-equivalences.component.html',
  styleUrls: ['./admin-equivalences.component.scss']
})
export class AdminEquivalencesComponent implements OnInit {
  section: string;
  equivalenceToModify: any;
  listeners: Array<any> = [];

  constructor(private eventbus: EventBusService, private router: Router, private route: ActivatedRoute, private data: DataService, private authenticationService: AuthService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      var id = params.get('id') || "";
      if (this.router.url.indexOf('admin/equivalence/modify/') > -1) {
        this.goToSection('modify');
        this.data.getEquivalence(id).subscribe((res: any) => {
          this.equivalenceToModify = res;
        });
      } else if (this.router.url.indexOf('admin/equivalence/add') > -1) {
        this.goToSection('add');
      }
      else {
        this.section = "list";
      }
    });
    this.listeners.push(this.eventbus.on("modifyequivalenceEmitter", (data) => {
      this.modifyEquivalence(data);
    }));
    this.listeners.push(this.eventbus.on("backAndReloadEquivalencesList", (data) => {
      this.router.navigate(['/admin/equivalence']);
      this.goToSection(data);
    }));
    this.listeners.push(this.eventbus.on("goToEquivalencesList", (data) => {
      this.router.navigate(['/admin/equivalence']);
      this.goToSection(data);
    }));
  }

  hasRole(role) {
    const currentUser = this.authenticationService.currentUserValue;
    var dict = currentUser.permissionsDictionary;
    return (dict["Equivalence"] || []).includes(role);
  }

  modifyEquivalence(equivalence): void {
    this.router.navigate(['/admin/equivalence/modify/' + equivalence.equivalenceId]);
  }

  backToList() {
    this.router.navigate(['/admin/equivalence']);
    this.goToSection('list');
  }

  goToNew() {
    this.router.navigate(['/admin/equivalence/add']);
  }

  goToSection(section: string) {
    this.section = section;
  }

  ngOnDestroy() {
    this.listeners.forEach(x => x.unsubscribe());
  }
}
