<div class="weather-next-week">
    <p class="weather-next-week-title">Próxima semana</p>
    <div class="weather-next-week-content">
        <div class="day-week" *ngFor="let day of nextWeek.days;let i=index">
            <p class="day-name">{{day.day}}</p>
            <i class="icon-{{day.icon}}"></i>
            <p class="day-temperature">{{day.maxTemperature}}º/{{day.minTemperature}}º</p>
        </div>
    </div>
</div>
