<div class="consumption" [ngClass]="{'componentInWebsite': !isPresentation}">
    <div class="chart">
        <app-energy-consumption-chart [consumption]="consumption" style="width: 100%;height: 100%;">
        </app-energy-consumption-chart>
    </div>
    <div class="description">
        <p class="chart-title">energía consumida</p>
        <div class="legend">
            <p class="sun-energy">De la planta (energía del sol)</p>
            <p class="electricity">De la red (electricidad)</p>
        </div>
    </div>
</div>