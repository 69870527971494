import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EventBusService } from 'src/app/services/eventBus.service';
import { DataService } from '../data.service';

@Component({
  selector: 'app-admin-equivalences-list',
  templateUrl: './admin-equivalences-list.component.html',
  styleUrls: ['./admin-equivalences-list.component.scss']
})
export class AdminEquivalencesListComponent implements OnInit {
  public equivalences: Array<any>;
  public paginatedEquivalences: Array<any>;
  public loading: boolean;
  listeners: Array<any> = [];

  constructor(private data: DataService, private eventbus: EventBusService, private toastr: ToastrService) {
    this.loading = true;
    this.equivalences = undefined;
  }

  ngOnInit(): void {
    this.getEquivalences();
    this.listeners.push(this.eventbus.on("deleteequivalenceEmitter", (data) => {
      this.deleteEquivalence(data);
    }));
    this.listeners.push(this.eventbus.on("deleteSelectedequivalenceEmitter", (data) => {
      var ids = data.map(a => a.equivalenceId);
      this.deleteEquivalences(ids);
    }));
  }

  deleteEquivalence(equivalence): void {
    this.data.deleteEquivalence(equivalence.equivalenceId).subscribe((res: any) => {
      this.toastr.success('Clase eliminada correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
      this.getEquivalences();
    },
      (error) => {
        this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' })
      });
  }

  deleteEquivalences(equivalences): void {
    this.data.deleteEquivalences(equivalences).subscribe((res: any) => {
      this.toastr.success('Clases eliminadas correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
      this.getEquivalences();
    },
      (error) => {
        this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' })
      });
  }

  getEquivalences(): void {
    this.data.getEquivalencesList().subscribe((res: any) => {
      this.equivalences = res;
      this.loading = false;
    },
      (error) => {
        this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' })
      });
  }

  ngOnDestroy() {
    this.listeners.forEach(x => x.unsubscribe());
  }
}
