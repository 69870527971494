<div class="widget">
    <div *ngIf="type === 'initiatives'">
        <i class="icon-bulb"> </i>
        <p>Iniciativas </p>
    </div>
    <div *ngIf="type === 'advices'">
        <i class="icon-tips"> </i>
        <p>Consejos </p>
    </div>
    <div *ngIf="type === 'challenges'">
        <i class="icon-ribbon"> </i>
        <p>Retos </p>
    </div>
    <div *ngIf="type === 'users'">
        <i class="material-icons">
            admin_panel_settings</i>
        <p>Usuarios </p>
    </div>
    <div *ngIf="type === 'school'">
        <i class="material-icons">
            account_balance</i>
        <p>Colegios </p>
    </div>
    <div *ngIf="type === 'class'">
        <i class="material-icons">
            supervised_user_circle</i>
        <p>Clases </p>
    </div>
    <div *ngIf="type === 'equivalence'">
        <i class="material-icons">
            published_with_changes</i>
        <p>Equivalencias </p>
    </div>
</div>