import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventBusService } from 'src/app/services/eventBus.service';
import { AuthService } from '../login/authentication.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  selectedSection: string;
  body: any;
  constructor(private router: Router, private authenticationService: AuthService, private route: ActivatedRoute, private eventbus: EventBusService) { }

  ngOnInit(): void {
    this.body = document.querySelectorAll("body")[0];
    this.route.paramMap.subscribe(params => {
      this.selectedSection = params.get('section') || "";
    });
    this.body.classList.add('no-media');
  }

  goToSection(section: string) {
    this.selectedSection = section;
    this.router.navigate(['/admin/' + section]);
    if (section === 'advices') {
      this.eventbus.emit({ name: "goToAdvicesList", value: "list" })
    } else if (section === 'challenges') {
      this.eventbus.emit({ name: "goToChallengesList", value: "list" })
    }
  }

  hasRole(section, role) {
    const currentUser = this.authenticationService.currentUserValue;
    var dict = currentUser.permissionsDictionary;
    return (dict[section] || []).includes(role);
  }

  goToHome() {
    this.router.navigate(['/website/senda']);
  }

  ngOnDestroy(): void {
    this.body.classList.remove('no-media');
  }
}
