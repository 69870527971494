<div class="weather-today">
    <div class="city-header">
        <p class="city-name">{{today.province}}</p>
        <p class="city-date">{{today.dayWeek}} <span>·</span> {{today.date}}</p>
    </div>
    <div class="city-content">
        <i class="icon-{{today.icon}}"></i>
        <div class="weather-info">
            <p class="temperature">{{today.temperature}} <span>ºc</span></p>
            <p class="status">{{today.weather}}</p>
            <div class="max-min">
                <p class="max">Max: {{today.maxTemperature}} <span>ºc</span></p>
                <p class="min">Min: {{today.minTemperature}} <span>ºc</span></p>
            </div>
        </div>
    </div>
    <div class="weather-chart">
        <img src="../../../../../assets/weather-icons/solar-panel.svg">
        <div class="horizontal-bar">
            <div class="percentage-bar" [style.width.%]="today.percentage" [style.maxWidth.%]="100"></div>
        </div>
        <p class="kw">{{today.power}} kWh</p>
    </div>
</div>
