import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule } from '@angular/forms';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { ReconectComponent } from './shared/reconnect/reconnect.component';
import { CardComponent } from './components/common/card/card.component';
import { CardTitleComponent } from './components/common/card-title/card-title.component';
import { CardContentComponent } from './components/common/card-content/card-content.component';

import { TableComponent } from './components/common/table/table.component';
import { PaginationComponent } from './components/common/table/pagination/pagination.component';

import { LoadingComponent } from './components/common/loading/loading.component';

import { LandingComponent } from './components/landing/landing.component'

import { LoginComponent } from './components/login/login.component'
import { AutologinComponent } from './components/login/autologin.component'

import { AdminComponent } from './components/admin/admin.component';
import { AdminDashboardComponent } from './components/admin/admin-dashboard/admin-dashboard.component';

import { PresentationComponent } from './components/presentation/presentation.component';

import { SavingComponent } from './components/presentation/saving/saving.component';
import { SavingAdvicesComponent } from './components/presentation/saving/saving-advices/saving-advices.component'
import { RankingListComponent } from './components/presentation/saving/saving-ranking/ranking-list.component';

import { EnergyComponent } from './components/presentation/energy/energy.component';
import { EnergyGeneratedComponent } from './components/presentation/energy/energy-generated/energy-generated.component';
import { EnergyGeneratedChartComponent } from './components/presentation/energy/energy-generated/energy-generated-chart/energy-generated-chart.component';
import { EnergyComparativeComponent } from './components/presentation/energy/energy-comparative/energy-comparative.component';
import { EnergyConsumptionComponent } from './components/presentation/energy/energy-consumption/energy-consumption.component';
import { EnergyPiggyBankComponent } from './components/presentation/energy/energy-piggy-bank/energy-piggy-bank.component';
import { EnergyConsumptionChartComponent } from './components/presentation/energy/energy-consumption/energy-consumption-chart/energy-consumption-chart.component';

import { ChallengesSlideComponent } from './components/presentation/challenges/challenges.component';

import { WeatherComponent } from './components/presentation/weather/weather.component';
import { WeatherTodayComponent } from './components/presentation/weather/weather-today/weather-today.component';
import { WeatherNextWeekComponent } from './components/presentation/weather/weather-next-week/weather-next-week.component';
import { WeatherComparativeComponent } from './components/presentation/weather/weather-comparative/weather-comparative.component';
import { WeatherComparativeDayComponent } from './components/presentation/weather/weather-comparative/weather-comparative-day/weather-comparative-day.component';
import { AdminAdvicesComponent } from './components/admin/admin-advices/admin-advices.component';
import { AdminDashboardWidgetComponent } from './components/admin/admin-dashboard/admin-dashboard-widget/admin-dashboard-widget.component';
import { AdminAdvicesListComponent } from './components/admin/admin-advices/admin-advices-list/admin-advices-list.component';
import { AdminAdvicesCrudComponent } from './components/admin/admin-advices/admin-advices-crud/admin-advices-crud.component';

import { WebsiteComponent } from './components/website/website.component';
import { DiscoverComponent } from './components/website/discover/discover.component';
import { WebsiteMenuComponent } from './components/website/website-menu/website-menu.component';
import { ChallengesComponent } from './components/website/challenges/challenges.component';
import { ChallengeModalComponent } from './components/website/challenges/challenge-modal/challenge-modal.component';
import { ChallengesBoardComponent } from './components/presentation/challenges/challenges-board/challenges-board.component';
import { AdminChallengesComponent } from './components/admin/admin-challenges/admin-challenges.component';
import { AdminChallengesCrudComponent } from './components/admin/admin-challenges/admin-challenges-crud/admin-challenges-crud.component';
import { AdminChallengesListComponent } from './components/admin/admin-challenges/admin-challenges-list/admin-challenges-list.component';
import { AppProvider } from './app.provider';
import { AdminChallengesManageComponent } from './components/admin/admin-challenges/admin-challenges-manage/admin-challenges-manage.component';
import { ChellengeSpecialComponent } from './components/presentation/challenges/challenges-board/challenge-special/challenge-special.component';
import { AdminChallengesManageEvaluationComponent } from './components/admin/admin-challenges/admin-challenges-manage/admin-challenges-manage-evaluation/admin-challenges-manage-evaluation.component';
import { ProfileComponent } from './shared/profile/profile.component';
import { InitiativesSlideComponent } from './components/presentation/initiatives/initiatives.component';
import { AdminInitiativesComponent } from './components/admin/admin-initiatives/admin-initiatives.component';
import { AdminInitiativesListComponent } from './components/admin/admin-initiatives/admin-initiatives-list/admin-initiatives-list.component';
import { AdminInitiativesCrudComponent } from './components/admin/admin-initiatives/admin-initiatives-crud/admin-initiatives-crud.component';
import { InitiativesComponent } from './components/website/initiatives/initiatives.component';
import { InitiativesDescriptionComponent } from './components/presentation/initiatives/initiatives-description/initiatives-description.component';
import { InitiativesRankingComponent } from './components/presentation/initiatives/initiatives-ranking/initiatives-ranking.component';
import { InitiativesCountdownComponent } from './components/presentation/initiatives/initiatives-countdown/initiatives-countdown.component';
import { InitiativesRankingListComponent } from './components/presentation/initiatives/initiatives-ranking/initiatives-ranking-list/initiatives-ranking-list.component';
import { ModalComponent } from './components/common/modal/modal.component';
import { InitiativesModalComponent } from './components/website/initiatives/initiatives-modal/initiatives-modal.component';
import { BackgroundComponent } from './components/presentation/background/background.component';
import { SendaComponent } from './components/website/senda/senda.component';
import { HeaderComponent } from './shared/header/header.component';
import { AdminClassesComponent } from './components/admin/admin-classes/admin-classes.component';
import { AdminClassesListComponent } from './components/admin/admin-classes/admin-classes-list/admin-classes-list.component';
import { AdminClassesCrudComponent } from './components/admin/admin-classes/admin-classes-crud/admin-classes-crud.component';
import { AdminSchoolsComponent } from './components/admin/admin-schools/admin-schools.component';
import { AdminSchoolsListComponent } from './components/admin/admin-schools/admin-schools-list/admin-schools-list.component';
import { AdminSchoolsCrudComponent } from './components/admin/admin-schools/admin-schools-crud/admin-schools-crud.component';
import { AdminEquivalencesComponent } from './components/admin/admin-equivalences/admin-equivalences.component';
import { AdminEquivalencesListComponent } from './components/admin/admin-equivalences/admin-equivalences-list/admin-equivalences-list.component';
import { AdminEquivalencesCrudComponent } from './components/admin/admin-equivalences/admin-equivalences-crud/admin-equivalences-crud.component';
import { AdminUsersComponent } from './components/admin/admin-users/admin-users.component';
import { AdminUsersListComponent } from './components/admin/admin-users/admin-users-list/admin-users-list.component';
import { AdminUsersCrudComponent } from './components/admin/admin-users/admin-users-crud/admin-users-crud.component';
import { RegisterComponent } from './components/register/register.component';
import { ValidateComponent } from './components/login/validate.component';

export function appProviderFactory(provider: AppProvider) {
  return () => provider.getConfiguration();
}
@NgModule({
  declarations: [
    AppComponent,
    ReconectComponent,
    CardComponent,
    CardTitleComponent,
    CardContentComponent,
    ModalComponent,
    PaginationComponent,
    TableComponent,
    ProfileComponent,
    HeaderComponent,
    LoadingComponent,
    LandingComponent,
    LoginComponent,
    AutologinComponent,
    AdminComponent,
    AdminDashboardComponent,
    AdminAdvicesComponent,
    PresentationComponent,
    SavingComponent,
    SavingAdvicesComponent,
    RankingListComponent,
    EnergyComponent,
    EnergyGeneratedComponent,
    EnergyGeneratedChartComponent,
    EnergyComparativeComponent,
    EnergyConsumptionComponent,
    EnergyPiggyBankComponent,
    EnergyConsumptionChartComponent,
    WeatherComponent,
    WeatherTodayComponent,
    WeatherNextWeekComponent,
    WeatherComparativeComponent,
    WeatherComparativeDayComponent,
    ChallengesSlideComponent,
    AdminDashboardWidgetComponent,
    AdminAdvicesListComponent,
    AdminAdvicesCrudComponent,
    AdminChallengesComponent,
    AdminChallengesCrudComponent,
    AdminChallengesManageComponent,
    AdminChallengesManageEvaluationComponent,
    AdminChallengesListComponent,
    WebsiteComponent,
    DiscoverComponent,
    WebsiteMenuComponent,
    DiscoverComponent,
    ChallengesComponent,
    ChallengeModalComponent,
    ChallengesBoardComponent,
    ChellengeSpecialComponent,
    InitiativesSlideComponent,
    AdminInitiativesComponent,
    AdminInitiativesListComponent,
    AdminInitiativesCrudComponent,
    InitiativesComponent,
    InitiativesDescriptionComponent,
    InitiativesRankingComponent,
    InitiativesCountdownComponent,
    InitiativesRankingListComponent,
    InitiativesModalComponent,
    BackgroundComponent,
    SendaComponent,
    AdminClassesComponent,
    AdminClassesListComponent,
    AdminClassesCrudComponent,
    AdminSchoolsComponent,
    AdminSchoolsListComponent,
    AdminSchoolsCrudComponent,
    AdminEquivalencesComponent,
    AdminEquivalencesListComponent,
    AdminEquivalencesCrudComponent,
    AdminUsersComponent,
    AdminUsersListComponent,
    AdminUsersCrudComponent,
    RegisterComponent,
    ValidateComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    FormsModule,
    AppRoutingModule
  ],
  exports: [
    HeaderComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: appProviderFactory, deps: [AppProvider], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
