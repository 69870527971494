import { Component, Output, OnInit, EventEmitter } from '@angular/core';
import { AuthService } from '../../login/authentication.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {
  @Output() goToSectionEmitter = new EventEmitter<string>();
  constructor(private authenticationService: AuthService) { }

  ngOnInit(): void {
  }

  hasRole(section, role) {
    const currentUser = this.authenticationService.currentUserValue;
    var dict = currentUser.permissionsDictionary;
    return (dict[section] || []).includes(role);
  }

  goToSection(section: string) {
    this.goToSectionEmitter.emit(section);
  }
}
