<div class="challenges">
    <div class="challenges_grid">
        <div class="challenges-left">
            <card>
                <card-title> Nuestras Insignias </card-title>
                <card-content>
                    <challenges-board [isPresentation]="true" [challenges]="challenge.challengeList"></challenges-board>
                </card-content>
            </card>
        </div>
        <div class="challenges-right">
            <card>
                <card-title> Ranking colegios </card-title>
                <card-content>
                    <ranking-list [type]="'challenges'" [ranking]="challenge.rankingChallenges"></ranking-list>
                </card-content>
            </card>
        </div>
    </div>
</div>