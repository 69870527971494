import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'energy-piggy-bank',
  templateUrl: './energy-piggy-bank.component.html',
  styleUrls: ['./energy-piggy-bank.component.scss']
})
export class EnergyPiggyBankComponent implements OnInit {
  @Input() piggyBank: any;
  @Input() isPresentation: boolean;
  @Input() isWidget: boolean;

  constructor() { }

  ngOnInit(): void {
  }
}
