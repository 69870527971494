<div class="comparative-day">
    <div class="bar-graph-container">
        <p class="value">{{day.power}}<span>kWh</span></p>
        <div class="bar" [style.height.%]="day.powerPercentage"></div>
    </div>
    <div class="week-day">
        <p class="day-name">{{day.day}}</p>
        <i class="icon-{{day.icon}}"></i>
        <p class="day-temperature">{{day.maxTemperature}}º/{{day.minTemperature}}º</p>
    </div>
</div>
