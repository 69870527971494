<div class="initiatives">
    <div class="initiatives_header">

        <img src="../../../../assets/img/website-icon/Content/cloud.svg" class="cloud">

        <div class="initiatives_header-title">
            <i class="icon-bulb"></i>
            <div class="title-text">
                <p>Iniciativas</p>
                <span>Descubre qué está pasando en los coles del proyecto Senda</span>
            </div>
        </div>
    </div>
    <loading *ngIf="!initiativesData"></loading>
    <div class="initiatives_body" *ngIf="initiativesData">
        <div class="initiatives-vote" *ngFor="let initiative of initiativesData;let i=index">
            <div class="initiatives-vote-description">
                <div class="picture-section">
                    <img class="picture" src="{{initiative.icon}}"
                        onerror="this.src='../../../../../../assets/img/placeholder.png'">
                </div>
                <div class="content-section">
                    <p class="title">{{initiative.tittle}}</p>
                    <p class="text">{{initiative.description}}</p>
                </div>
            </div>
            <div class="initiatives-vote-likes">
                <div class="number">
                    <img src="../../../../../assets/img/icon/heart-blue.svg">
                    <p>{{initiative.votes}}</p>
                </div>
                <span *ngIf="!initiative.voted && hasRole('vote')" class="vote-button" (click)="vote(initiative)">Votar</span>
                <span *ngIf="initiative.voted" class="vote-alert">Ya has votado por esta iniciativa</span>
            </div>
        </div>
    </div>
</div>
<website-initiatives-modal *ngIf="initiativesLoginModalOpened" [type]="'login'"></website-initiatives-modal>
<website-initiatives-modal *ngIf="initiativesVoteModalOpened" [type]="'vote'" [initiative]="initiativeToVote">
</website-initiatives-modal>
<website-initiatives-modal *ngIf="initiativesOkModalOpened" [type]="'ok'"></website-initiatives-modal>