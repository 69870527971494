import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventBusService } from 'src/app/services/eventBus.service';
import { AuthService } from '../../login/authentication.service';
import { DataService } from './data.service';

@Component({
  selector: 'app-admin-classes',
  templateUrl: './admin-classes.component.html',
  styleUrls: ['./admin-classes.component.scss']
})
export class AdminClassesComponent implements OnInit {
  section: string;
  classeToModify: any;
  listeners: Array<any> = [];

  constructor(private eventbus: EventBusService, private router: Router, private route: ActivatedRoute, private data: DataService, private authenticationService: AuthService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      var id = params.get('id') || "";
      if (this.router.url.indexOf('admin/class/modify/') > -1) {
        this.goToSection('modify');
        this.data.getClass(id).subscribe((res: any) => {
          this.classeToModify = res;
        });
      } else if (this.router.url.indexOf('admin/class/add') > -1) {
        this.goToSection('add');
      }
      else {
        this.section = "list";
      }
    });
    this.listeners.push(this.eventbus.on("modifyclassEmitter", (data) => {
      this.modifyClass(data);
    }));
    this.listeners.push(this.eventbus.on("backAndReloadClassesList", (data) => {
      this.router.navigate(['/admin/class']);
      this.goToSection(data);
    }));
    this.listeners.push(this.eventbus.on("goToClassesList", (data) => {
      this.router.navigate(['/admin/class']);
      this.goToSection(data);
    }));
  }

  hasRole(role) {
    const currentUser = this.authenticationService.currentUserValue;
    var dict = currentUser.permissionsDictionary;
    return (dict["Class"] || []).includes(role);
  }

  modifyClass(classe): void {
    this.router.navigate(['/admin/class/modify/' + classe.id]);
  }

  backToList() {
    this.router.navigate(['/admin/class']);
    this.goToSection('list');
  }

  goToNew() {
    this.router.navigate(['/admin/class/add']);
  }

  goToSection(section: string) {
    this.section = section;
  }

  ngOnDestroy() {
    this.listeners.forEach(x => x.unsubscribe());
  }
}
