import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private urlGet: string = "api/User/getuser";
  private urlList: string = "api/User/getuserlist";
  private urlCreate: string = "api/User/createuser";
  private urlUpdate: string = "api/User/updateuser";
  private urlDelete: string = "api/User/deleteuser";
  private urlDeleteMultiple: string = "api/User/deleteuserlist";
  private schoolsUrl: string = "api/ClassSchool/getschoollist";
  private rolesUrl: string = "api/User/getrollist";
  constructor(private http: HttpClient, private config: ConfigurationService) { }

  getSchools(): any {
    var configuration = this.config.getConfiguration();
    return this.http.get(environment.api + this.schoolsUrl + "?groupIdentity=" + configuration.group);
  }

  getRoles(): any {
    return this.http.get(environment.api + this.rolesUrl);
  }

  getUser(id): any {
    return this.http.get(environment.api + this.urlGet + '?userId=' + id);
  }

  getUsersList(): any {
    return this.http.get(environment.api + this.urlList);
  }

  createUser(lastName, userName, login, password, email, school, rol, children, keyIdentity): any {
    return this.http.post(environment.api + this.urlCreate,
      {
        lastName,
        userName,
        login,
        password,
        email,
        schoolId: (school || {}).schoolId ? parseInt(school.schoolId) : null,
        rolId: parseInt(rol.rolId),
        children: children ? parseInt(children) : 0,
        keyIdentity
      }
    )
  }

  modifyUser(user): any {
    user.schoolId = user.schoolId ? parseInt(user.schoolId) : null;
    user.rolId = parseInt(user.rolId);
    user.children = user.children ? parseInt(user.children) : 0;
    return this.http.post(environment.api + this.urlUpdate,
      user
    )
  }

  deleteUser(id: string): any {
    return this.http.post(environment.api + this.urlDelete + "?userid=" + id,
      {}
    )
  }

  deleteUsers(ids: Array<string>): any {
    var deleteString = "";
    ids.forEach(id => { deleteString += (!deleteString ? "?" : "&") + "usersId=" + id });
    return this.http.post(environment.api + this.urlDeleteMultiple + deleteString,
      {}
    )
  }
}
