import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { environment}  from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private dataUrl: string = "api/Inititative/getinitiativeslist";
  private voteUrl: string = "api/Inititative/vote";
  constructor(private http: HttpClient, private config: ConfigurationService) { }

  getData(userId): any {
    var configuration = this.config.getConfiguration();
    var args = "?groupIdentity=" + configuration.group

    if (userId) {
      args = args + "&userId=" + userId;
    }
    return this.http.get(environment.api + this.dataUrl + args);
  }

  vote(initiativeId) {
    return this.http.post(environment.api + this.voteUrl + "?initiativeId=" + initiativeId,
      {}
    )
  }
}
