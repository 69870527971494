import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EventBusService } from 'src/app/services/eventBus.service';
import { DataService } from '../data.service';

@Component({
  selector: 'app-admin-initiatives-list',
  templateUrl: './admin-initiatives-list.component.html',
  styleUrls: ['./admin-initiatives-list.component.scss']
})
export class AdminInitiativesListComponent implements OnInit {
  public initiatives: Array<any>;
  public paginatedInitiatives: Array<any>;
  public loading: boolean;
  listeners: Array<any> = [];

  constructor(private data: DataService, private eventbus: EventBusService, private toastr: ToastrService) {
    this.loading = true;
    this.initiatives = undefined;
  }

  ngOnInit(): void {
    this.getInitiatives();
    this.listeners.push(this.eventbus.on("deleteinitiativesEmitter", (data) => {
      this.deleteInitiative(data);
    }));
    this.listeners.push(this.eventbus.on("deleteSelectedinitiativesEmitter", (data) => {
      var ids = data.map(a => a.id);
      this.deleteInitiatives(ids);
    }));
  }

  deleteInitiative(initiative): void {
    this.data.deleteInitiative(initiative.id).subscribe((res: any) => {
      this.toastr.success('Iniciativa eliminada correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
      this.getInitiatives();
    },
      (error) => {
        this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' })
      });
  }

  deleteInitiatives(initiatives): void {
    this.data.deleteInitiatives(initiatives).subscribe((res: any) => {
      this.toastr.success('Iniciativas eliminadas correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
      this.getInitiatives();
    },
      (error) => {
        this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' })
      });
  }

  getInitiatives(): void {
    this.data.getInitiativesList().subscribe((res: any) => {
      this.initiatives = res;
      this.loading = false;
    });
  }

  ngOnDestroy() {
    this.listeners.forEach(x => x.unsubscribe());
  }
}
