import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { EventBusService } from 'src/app/services/eventBus.service';
import { DataService } from '../data.service';

@Component({
  selector: 'app-admin-users-list',
  templateUrl: './admin-users-list.component.html',
  styleUrls: ['./admin-users-list.component.scss']
})
export class AdminUsersListComponent implements OnInit {
  public users: Array<any>;
  public paginatedUsers: Array<any>;
  public loading: boolean;
  listeners: Array<any> = [];

  constructor(private data: DataService, private eventbus: EventBusService, private toastr: ToastrService) {
    this.loading = true;
    this.users = undefined;
  }

  ngOnInit(): void {
    this.getUsers();
    this.listeners.push(this.eventbus.on("deleteusersEmitter", (data) => {
      this.deleteUser(data);
    }));
    this.listeners.push(this.eventbus.on("deleteSelectedusersEmitter", (data) => {
      var ids = data.map(a => a.userId);
      this.deleteUsers(ids);
    }));
  }

  deleteUser(user): void {
    this.data.deleteUser(user.userId).subscribe((res: any) => {
      this.toastr.success('Usuario eliminado correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
      this.getUsers();
    },
      (error) => {
        this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' })
      });
  }

  deleteUsers(users): void {
    this.data.deleteUsers(users).subscribe((res: any) => {
      this.toastr.success('Usuarios eliminados correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
      this.getUsers();
    },
      (error) => {
        this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' })
      });
  }

  getUsers(): void {
    this.data.getUsersList().subscribe((res: any) => {
      this.users = res;
      this.loading = false;
    },
      (error) => {
        this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' })
      });
  }

  ngOnDestroy() {
    this.listeners.forEach(x => x.unsubscribe());
  }
}
