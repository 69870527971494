import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { User } from '../../models/user';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public adminRoles: Array<string>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.adminRoles = ["1", "2"];
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(username: string, password: string) {
    return this.http.post<any>(
      environment.api +
      'api/login/authenticate',
      { username, password },
      {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
      })
      .pipe(map(user => {
        this.setUser(user);
      }));
  }

  autologin(key: string) {
    return this.http.get<any>(
      environment.api +
      `api/login/autologin?key=${key}`
    ).pipe(map(user => {
      this.setUser(user);
    }));
  }

  validate(key: string) {
    return this.http.post<any>(
      environment.api +
      `api/User/validateuser?keyValidation=${key}`,
      {})
      .pipe(map(user => {
        this.setUser(user);
      }));
  }

  setUser(user: any) {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
    return user;
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}