import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private urlGet: string = "api/Challenge/getchallengebyid";
  private urlList: string = "api/Challenge/getchallenges";
  private urlCreate: string = "api/Challenge/createchallenge";
  private urlUpdate: string = "api/Challenge/updatechallenge";
  private urlDelete: string = "api/Challenge/deletechallenge";
  private urlGetClasses: string = "api/Challenge/getclassuser";
  private urlGetForm: string = "api/Challenge/getform";
  private urlSaveForm: string = "api/Challenge/saveform";
  private urlUpdatePercentage: string = "api/Challenge/updatechallengepercentage";

  constructor(private http: HttpClient) { }

  getChallenge(id): any {
    return this.http.get(environment.api + this.urlGet + '?challengeId=' + id);
  }

  getChallengesList(): any {
    return this.http.get(environment.api + this.urlList);
  }

  getClasses(): any {
    return this.http.get(environment.api + this.urlGetClasses);
  }

  createChallenge(tittle, description, startDate, endDate, imageDone, imageUndone, videoLink, pdfPath): any {
    var sD = new Date(startDate).toISOString().split('T')[0];
    var eD = new Date(endDate).toISOString().split('T')[0];
    return this.http.post(environment.api + this.urlCreate,
      {
        "tittle": tittle,
        "description": description,
        "startDate": sD,
        "endDate": eD,
        "imageDone": imageDone,
        "imageUndone": imageUndone,
        "videoLink": videoLink,
        "pdfpath": pdfPath || "",
        "userId": 0,
        "groupId": 0
      }
    )
  }

  getForm(clase, challenge): any {
    return this.http.get(environment.api + this.urlGetForm + "?classId=" + clase + "&challengeId=" + challenge)
  }

  saveForm(clase, form, challenge): any {
    return this.http.post(environment.api + this.urlSaveForm,
      {
        "answers": form.answers,
        "archives": form.archives,
        "percentage": form.percentage,
        "classId": clase,
        "challengeId": challenge
      }
    )
  }

  updatePercentage(clase, challenge, ev): any {
    
    return this.http.post(environment.api + this.urlUpdatePercentage,
      {
        "classId": clase,
        "challengeId": challenge,
        "percentage": parseInt(ev)
      }
    )
  }

  modifyChallenge(challenge): any {
    if (!challenge.imageUndoneUpdated) {
      challenge.imageUndone = "";
    }
    if (!challenge.imageDoneUpdated) {
      challenge.imageDone = "";
    }
    return this.http.post(environment.api + this.urlUpdate,
      challenge
    )
  }

  deleteChallenge(id: string): any {
    return this.http.post(environment.api + this.urlDelete + "?challengeid=" + id,
      {}
    )
  }
}
