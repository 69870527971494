import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { environment}  from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private dataUrl: string = "api/Discover/getdiscover";
  private dataSchoolUrl: string = "api/Discover/getdiscoverschool";
  private schoolsUrl: string = "api/InitialLoad/getinitialload";
  constructor(private http: HttpClient, private config: ConfigurationService) { }

  getData(): any {
    var configuration = this.config.getConfiguration();
    return this.http.get(environment.api + this.dataUrl + "?groupIdentity=" + configuration.group);
  }

  getDataBySchool(id: number): any {
    return this.http.get(environment.api + this.dataSchoolUrl + "?schoolId=" + id);
  }

  getSchools(): any {
    var configuration = this.config.getConfiguration();
    return this.http.get(environment.api + this.schoolsUrl + "?groupIdentity=" + configuration.group);
  }
}
