import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from 'src/app/services/eventBus.service';

@Component({
  selector: 'website-challenge-modal',
  templateUrl: './challenge-modal.component.html',
  styleUrls: ['./challenge-modal.component.scss']
})
export class ChallengeModalComponent implements OnInit {
  @Input() challenge: any;
  constructor(private eventbus: EventBusService) { }

  ngOnInit(): void { }

  closeModal(): void {
    this.eventbus.emit({ name: "closeModal" })
  }
}
