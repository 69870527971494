<loading *ngIf="loading"></loading>
<div *ngIf="!loading" class="users-crud">
    <p class="title-crud" *ngIf="!isModify">Añadir nuevo usuario</p>
    <p class="title-crud" *ngIf="isModify">Modificar usuario #{{user.userId}}</p>
    <div class="users-form">
        <div class="container-horizontal-top">
            <div class="left-top">
                <p class="label-text">Tipo de usuario <span>(*obligatorio)</span></p>
                <div class="input-select">
                    <div class="input-select-text">
                        <select [(ngModel)]="rol">
                            <option [ngValue]=undefined disabled selected>Seleccione un tipo de usuario</option>
                            <option *ngFor="let t of roles" [ngValue]="t">{{t.rolDesc}}</option>
                        </select>
                    </div>
                </div>
                <p class="label-text">Nombre <span>(*obligatorio)</span></p>
                <div class="input-user">
                    <div class="input-user-icon">
                        <i class="material-icons">
                            admin_panel_settings</i>
                    </div>
                    <div class="input-user-text">
                        <input [(ngModel)]="userName" type="text" autocomplete="off"
                            placeholder="Escriba aquí su nombre... " maxlength="80" />
                    </div>
                </div>
                <p class="label-text">Apellidos</p>
                <div class="input-user">
                    <div class="input-user-icon">
                        <i class="material-icons">
                            admin_panel_settings</i>
                    </div>
                    <div class="input-user-text">
                        <input [(ngModel)]="lastName" type="text" autocomplete="off"
                            placeholder="Escriba aquí sus apellidos... " maxlength="80" />
                    </div>
                </div>
            </div>

            <div class="right-top">
                <p class="label-text">Usuario <span>(*obligatorio)</span></p>
                <div class="input-user">
                    <div class="input-user-icon">
                        <i class="material-icons">
                            person</i>
                    </div>
                    <div class="input-user-text">
                        <input [(ngModel)]="login" type="text" autocomplete="off"
                            placeholder="Escriba aquí su nombre de usario... " maxlength="80" />
                    </div>
                </div>
                <p class="label-text">Contraseña <span>(*obligatorio)</span></p>
                <div class="input-user">
                    <div class="input-user-icon">
                        <i class="material-icons">
                            vpn_key</i>
                    </div>
                    <div class="input-user-text">
                        <input [(ngModel)]="password" type="text" autocomplete="off"
                            placeholder="Escriba aquí su contraseña... " maxlength="80" />
                    </div>
                </div>
                <p class="label-text">E-mail <span>(*obligatorio)</span></p>
                <div class="input-user">
                    <div class="input-user-icon">
                        <i class="material-icons">
                            email</i>
                    </div>
                    <div class="input-user-text">
                        <input [(ngModel)]="email" type="text" autocomplete="off"
                            placeholder="Escriba aquí su dirección de correo electrónico... " maxlength="80" />
                    </div>
                </div>
            </div>
        </div>

        <div class="container-horizontal-bottom">
            <div class="box" *ngIf="rol && (rol.rolId == '2' || rol.rolId == '4')">
                <p class="label-text">Colegio <span>(*obligatorio)</span></p>
                <div class="input-school">
                    <div class="input-school-text">
                        <select [(ngModel)]="school">
                            <option [ngValue]=undefined disabled selected>Seleccione un colegio</option>
                            <option *ngFor="let s of schools" [ngValue]="s">{{s.name}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="box" *ngIf="rol && rol.rolId == '4'">
                <p class="label-text">Hijos <span>(*obligatorio)</span></p>
                <div class="input-school">
                    <div class="input-school-text">
                        <select [(ngModel)]="children">
                            <option [ngValue]=undefined disabled selected>Indique el número de hijos</option>
                            <option *ngFor="let h of [0,1,2,3,4,5]" [ngValue]="h">{{h}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="box" *ngIf="rol && rol.rolId == '3'">
                <p class="label-text">Clave kiosco <span>(*obligatorio)</span></p>
                <div class="input-user">
                    <div class="input-user-icon">
                        <i class="material-icons">
                            enhanced_encryption</i>
                    </div>
                    <div class="input-user-text">
                        <input [(ngModel)]="keyIdentity" type="text" autocomplete="off"
                            placeholder="Escriba la clave del kiosco... " maxlength="80" />
                    </div>
                </div>
            </div>

        </div>

        <div class="add-users" [ngClass]="{
            'disabled': !userName || !login || !password || !rol || !email || savingUser ||
            (rol && rol.rolId == '3' && !keyIdentity) ||
            (rol && rol.rolId == '4' && !children && children != 0) ||
            (rol && (rol.rolId == '2' || rol.rolId == '4') && !school )
        }">
            <div *ngIf="!isModify" class="submit" (click)="createUser()">
                <loading *ngIf="savingUser" [isButton]="true"></loading>
                <p class="text-button">Añadir usuario</p>
            </div>
            <div *ngIf="isModify" class="submit" (click)="modifyUser()">
                <loading *ngIf="savingUser" [isButton]="true"></loading>
                <p class="text-button">Modificar usaurio</p>
            </div>
        </div>
    </div>
</div>