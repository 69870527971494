import { Component, OnInit } from '@angular/core';
import { AuthService } from './authentication.service'
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  template: '<span>{{error}}</span>',
})

export class AutologinComponent implements OnInit {
  key: string;
  error: string;
  returnUrl: string;

  constructor(private router: Router, private auth: AuthService, private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.key = this.route.snapshot.queryParamMap.get("key");
    if (!this.key) {
      this.error = "No se ha indicado el parámetro de inicio de sesión."
    } else {
      if (this.auth.currentUserValue) {
        // already logged so logout
        this.auth.logout();
      }
      this.returnUrl = '/presentacion';
      this.autologin();
    }
  }

  autologin(): void {
    this.auth.autologin(this.key)
      .pipe(first())
      .subscribe(
        data => {
          this.router.navigate([this.returnUrl]);
        },
        error => {
          this.error = error;
          this.key = "";
        });
  }
}
