<modal [size]="'l'">
    <loading *ngIf="!challenge"></loading>
    <ng-container *ngIf="challenge">
        <div class="modal-header">
            <img src="{{challenge.image}}" onerror="this.src='../../../../../assets/img/placeholder.png'">
            <div class="modal-header-title">
                <p class="order">Reto {{challenge.challengeId}}</p>
                <p class="name">{{challenge.tittle}}</p>
            </div>
        </div>
        <div class="modal-content">
            <p class="modal-content-description">{{challenge.description}}</p>
            <div class="modal-content-links">
                <div class="download-link" *ngIf="challenge.pdfPath">
                    <i class="material-icons">get_app</i>
                    <span><a href="{{challenge.pdfPath}}" target="_blank">Descargar Documento</a></span>
                </div>
                <div class="play-link" *ngIf="challenge.videoLink">
                    <i class="material-icons">play_arrow</i>
                    <span><a href="{{challenge.videoLink}}" target="_blank">Visualizar video</a></span>
                </div>
            </div>
            <div class="modal-content-button" (click)="closeModal()">
                <span>Entendido</span>
            </div>
        </div>
    </ng-container>
</modal>