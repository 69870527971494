import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventBusService } from 'src/app/services/eventBus.service';
import { DataService } from './data.service';

@Component({
  selector: 'app-admin-initiatives',
  templateUrl: './admin-initiatives.component.html',
  styleUrls: ['./admin-initiatives.component.scss']
})
export class AdminInitiativesComponent implements OnInit {
  section: string;
  initiativeToModify: any;
  listeners: Array<any> = [];

  constructor(private eventbus: EventBusService, private router: Router, private route: ActivatedRoute, private data: DataService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      var id = params.get('id') || "";
      if (this.router.url.indexOf('admin/initiatives/modify/') > -1) {
        this.goToSection('modify');
        this.data.getInitiative(id).subscribe((res: any) => {
          this.initiativeToModify = res;
        });
      } else if (this.router.url.indexOf('admin/initiatives/add') > -1) {
        this.goToSection('add');
      }
      else {
        this.section = "list";
      }
    });
    this.listeners.push(this.eventbus.on("modifyinitiativesEmitter", (data) => {
      this.modifyInitiative(data);
    }));
    this.listeners.push(this.eventbus.on("backAndReloadInitiativesList", (data) => {
      this.goToSection(data);
    }));
    this.listeners.push(this.eventbus.on("goToInitiativesList", (data) => {
      this.goToSection(data);
    }));
  }

  modifyInitiative(initiative): void {
    this.router.navigate(['/admin/initiatives/modify/' + initiative.id]);
  }

  backToList() {
    this.router.navigate(['/admin/initiatives']);
    this.goToSection('list');
  }

  goToNew() {
    this.router.navigate(['/admin/initiatives/add']);
  }

  goToSection(section: string) {
    this.section = section;
  }

  ngOnDestroy() {
    this.listeners.forEach(x => x.unsubscribe());
  }
}
