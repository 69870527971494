import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'energy-consumption',
  templateUrl: './energy-consumption.component.html',
  styleUrls: ['./energy-consumption.component.scss']
})
export class EnergyConsumptionComponent implements OnInit {
  @Input() consumption: any;
  @Input() isPresentation: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
