import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'weather-comparative',
  templateUrl: './weather-comparative.component.html',
  styleUrls: ['./weather-comparative.component.scss']
})
export class WeatherComparativeComponent implements OnInit {
  @Input() comparative: any;
  imgarray: Array<string>;
  constructor() {
    this.imgarray = [
      "../../../../../assets/weather-icons/022-sun-blue.svg",
      "../../../../../assets/weather-icons/049-rain-blue.svg",
      "../../../../../assets/weather-icons/001-cloudy-blue.svg",
      "../../../../../assets/weather-icons/049-rain-blue.svg",
      "../../../../../assets/weather-icons/004-storm-blue.svg",
      "../../../../../assets/weather-icons/036-snowflake-blue.svg",
      "../../../../../assets/weather-icons/017-wind-blue.svg"
    ]
  }

  ngOnInit(): void {
    if (this.comparative && this.comparative.days && this.comparative.days.length && this.comparative.days[0].day && this.comparative.days[0].day.includes("(hoy)")) {
      (this.comparative || {}).days = ((this.comparative || {}).days || []).reverse();
    }
  }
}
