<div class="challenges">
    <div class="challenges_header">
        <img src="../../../../assets/img/website-icon/Content/cloud.svg" class="cloud">
        <div class="challenges_header-title">
            <i class="icon-ribbon"></i>
            <div class="title-text">
                <p>Retos</p>
                <span>Te presentamos los retos conseguidos y la clasificación en el ranking</span>
            </div>
        </div>
    </div>
    <loading *ngIf="!challengesData"></loading>
    <div class="challenges_body" *ngIf="challengesData">
        <div class="challenges-badges-selection">
            <p class="challenges_body-title">¿Qué clase quieres ver?</p>
            <div class="challenges_school">
                <div class="school_select">
                    <i class="wicon-school"></i>
                    <div class="school_select-box" (click)="switchSchoolDropdown()">
                        <div class="school-name">
                            <img class="school-name-img" src="{{currentSchool.icon}}"
                                onerror="this.src='../../../../assets/img/placeholder.png'">
                            <span class="school-name-span">{{currentSchool.name}}</span>
                        </div>
                        <i class="material-icons school-name-i">expand_more</i>
                    </div>
                    <div [hidden]="!dropdownSchoolOpened" class="school_select-dropdown">
                        <div class="school_select-dropdown-element" (click)="loadSchoolData(school, school['class'][0])"
                            *ngFor="let school of schools">
                            <img src="{{school.icon}}" onerror="this.src='../../../../assets/img/placeholder.png'">
                            <span class="school-name-span">{{school.name}}</span>
                        </div>
                    </div>
                </div>

                <div class="class_select">
                    <i class="wicon-group"></i>
                    <div class="class_select-box" (click)="switchClassDropdown()">
                        <span *ngIf="currentClass" class="class-name-span">{{currentClass.description}}</span>
                        <i class="material-icons class-name-i">expand_more</i>
                    </div>
                    <div [hidden]="!dropdownClassOpened" class="class_select-dropdown">
                        <div class="class_select-dropdown-element" (click)="loadSchoolData(currentSchool, class)"
                            *ngFor="let class of currentSchool['class']">
                            <span>{{class.description}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="challenges_badges">
            <loading *ngIf="loadingSchool"></loading>
            <div *ngIf="!loadingSchool && challengesData">
                <challenges-board [challenges]="challengesData.challengeList"></challenges-board>
            </div>
        </div>

        <loading *ngIf="!challengesData"></loading>
        <div class="challenges-ranking-section" *ngIf="challengesData">
            <p class="challenges_body-title">Ranking de colegios</p>
            <div class="ranking-board">
                <ranking-list [type]="'challenges'" [ranking]="challengesData.rankingChallenges"></ranking-list>
            </div>
        </div>
    </div>

    <website-challenge-modal *ngIf="challengeModalOpened" [challenge]="challengeDetails"></website-challenge-modal>