<div class="saving">
    <div class="saving_grid">
        <div class="saving-left">
            <card>
                <card-title> ranking colegios ahorradores </card-title>
                <card-content>
                    <ranking-list [type]="'saving'" [ranking]="saving.ranking"></ranking-list>
                </card-content>
            </card>
        </div>
        <div class="saving-right">
            <card>
                <card-title> consejos para ahorrar energía </card-title>
                <card-content>
                    <saving-advices [advices]="saving.advices" [isPresentation]="true" [icon]="icon"></saving-advices>
                </card-content>
            </card>
        </div>
    </div>
</div>