<svg viewBox="0 0 100 100" class="gauge" [ngClass]="{'componentInWebsite': !isPresentation && !isWidget, 'componentInWidget': isWidget}" style="height: inherit; width: inherit">
  <path class="dial" stroke="#e7e7e8" stroke-width="7" fill="none" d="M 21.716 78.284 A 40 40 0 1 1 78.284 78.284"
    stroke-linecap="round"></path>
  <path class='generatedValue' stroke="url(#gradient)" fill="none" stroke-width="8"
    d="M 21.716 78.284 A 40 40 0 0 1 24.796 18.94" stroke-linecap="round"></path>
  <text x="50" y="50" class="gauge-value" font-weight="normal">{{generated.powerGenerated.toFixed(0)}}</text>
  <text x="50" y="65" class="gauge-unit">kWh</text>
  <text x="20" y="95" class="gauge-legend">0</text>
  <text x="75" y="95" class="gauge-legend">{{generated.maxQuantity}}</text>
  <defs>
    <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
      <stop offset="0%" stop-color="#20D8DE" />
      <stop offset="100%" stop-color="#0EC0C6" />
    </linearGradient>
  </defs>
</svg>