<div class="website" *ngIf="schools">
    <div class="website-menu" [ngClass]="{'scrolled': isScrolled}">
        <website-menu></website-menu>
    </div>
    <div class="website-content">
        <div *ngIf="websiteSection === 'iniciativas'">
            <website-initiatives [schools]="schools"></website-initiatives>
        </div>
        <div *ngIf="websiteSection === 'descubre'">
            <website-discover [schools]="schools"></website-discover>
        </div>
        <div *ngIf="websiteSection === 'retos'">
            <website-challenges [schools]="schools"></website-challenges>
        </div>
        <div *ngIf="websiteSection === 'senda'">
            <app-senda></app-senda>
        </div>
    </div>
    <div class="website_footer">
        <div class="website_footer-content">
            <img src="../../../../assets/img/icon/logo-senda-white.svg">
            <div class="social">
                <!-- <i class="icon-facebook"></i>
                <i class="icon-twitter"></i>
                <i class="icon-instagram"></i>-->
            </div>

            <span><i class="material-icons">copyright</i>2022 SENDA. Todos los derechos reservados · Aviso legal ·
                Política de privacidad · Política de
                cookies</span>
        </div>
    </div>
</div>