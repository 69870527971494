import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from 'src/app/services/eventBus.service';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input() size: string;
  body: any;
  constructor(private eventbus: EventBusService) {
    this.body = document.querySelectorAll("body")[0];
  }

  ngOnInit(): void {
    this.body.setAttribute("style", "overflow:hidden");
  }

  closeModal(): void {
    this.eventbus.emit({ name: "closeModal" })
  }

  ngOnDestroy(): void {
    this.body.setAttribute("style", "overflow:auto");
  }

}
