import { Component, Input, OnInit } from '@angular/core';
import { EventBusService } from 'src/app/services/eventBus.service';
import * as _ from 'lodash';
import { PdfService } from 'src/app/services/pdf.service';
import { DataService } from '../../data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-challenges-manage-evaluation',
  templateUrl: './admin-challenges-manage-evaluation.component.html',
  styleUrls: ['./admin-challenges-manage-evaluation.component.scss']
})
export class AdminChallengesManageEvaluationComponent implements OnInit {
  @Input() challenge: any;
  @Input() form: any;
  evaluationPage: number = 0;
  description: string;
  pdfFiles: Array<any> = [];
  savingForm: boolean = false;
  listeners: Array<any> = [];

  constructor(private eventbus: EventBusService, private pdf: PdfService, private data: DataService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.listeners.push(this.eventbus.on("manageChallengeEmitter", (data) => {
      this.goToEvaluationPage(0);
    }));
  }

  setValue(question: number, points: number) {
    this.form.answers[question] = points + "";
  }

  saveForm() {
    if (!this.savingForm) {
      this.savingForm = true;
      this.data.saveForm(1, this.form, this.challenge.challengeId).subscribe(
        (res: any) => {
          this.savingForm = false;
          this.toastr.success('Formulario guardado correctamente!', '', { timeOut: 2000, positionClass: 'toast-bottom-right' });
        }, (error) => {
          this.savingForm = false;
          this.toastr.error(error, 'Error!', { timeOut: 3000, positionClass: 'toast-bottom-right' })
        });
    }
  }

  uploadFile(ev) {
    this.pdf.convertToBase64(ev).then(res => {
      this.form.archives.push(res);
    });
  }

  deletePdfFile(index: number) {
    this.form.archives.splice(index, 1);
  }

  goToEvaluationPage(num) {
    this.evaluationPage = num;
  }

  nextEvaluationPage() {
    this.evaluationPage = this.evaluationPage + 1;
  }

  prevEvaluationPage() {
    this.evaluationPage = this.evaluationPage - 1;
  }

  ngOnDestroy() {
    this.listeners.forEach(x => x.unsubscribe());
  }
}
