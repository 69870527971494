<div class="classes">
    <div *ngIf="section === 'list'">
        <div class="classes_header">
            <p class="classes_header-title">Clases</p>
            <div class="classes_header-add" (click)="goToNew()" *ngIf="hasRole('write')">
                <span>Añadir clase</span>
            </div>
        </div>
        <div class="classes_section">
            <app-admin-classes-list></app-admin-classes-list>
        </div>
    </div>
    <div *ngIf="section === 'add' || section === 'modify'">
        <div class="classes_header">
            <p class="classes_header-title">
                <span class="classes_header-breadcrumb" (click)="backToList()">
                    Clases
                </span>
                <span class="classes_header-breadcrumb-slash"> | </span>
                <span *ngIf="section === 'add'">Nueva Clase</span>
                <span *ngIf="section === 'modify'">Modificar Clase</span>
            </p>
        </div>
        <div class="classes_section">
            <app-admin-classes-crud *ngIf="section === 'add'"></app-admin-classes-crud>
            <app-admin-classes-crud *ngIf="section === 'modify'" [isModify]="true" [classe]="classeToModify">
            </app-admin-classes-crud>
        </div>
    </div>
</div>