<div class="initiatives">
    <div class="grid_initiatives">
        <div class="left-initiatives">
            <card>
                <app-initiatives-description [initiatives]="initiative.initiatives"></app-initiatives-description>
            </card>
        </div>
        <div class="right-top-initiatives">
            <card>
                <card-title> Ranking iniciativas</card-title>
                <card-content>
                    <app-initiatives-ranking [ranking]="initiative.rankingInitiatives"></app-initiatives-ranking>
                </card-content>
            </card>
        </div>
        <div class="right-bottom-initiatives">
            <app-initiatives-countdown></app-initiatives-countdown>
        </div>
    </div>
</div>