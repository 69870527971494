import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { DataService } from './data.service';

@Component({
  selector: 'app-senda',
  templateUrl: './senda.component.html',
  styleUrls: ['./senda.component.scss']
})

export class SendaComponent implements OnInit {
  data: any;
  prevPhoto: number;
  currentPhoto: number;
  nextPhoto: number;
  pictures: any;
  carouselInterval: number;
  timerInterval: any;

  constructor(private dataService: DataService, private config: ConfigurationService, private router: Router) {
    var configuration = this.config.getConfiguration();
    this.carouselInterval = configuration.CarruselTime;
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.dataService.getData().subscribe((res: any) => {
      this.currentPhoto = 0;
      this.data = res;
      this.pictures = document.getElementsByClassName('carousel-picture-img');
      this.setTimer();
    });
  }

  prevPhotoFunc() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
      this.setTimer();
    }
    if (this.currentPhoto > 0) {
      this.currentPhoto--;
    } else {
      this.currentPhoto = this.pictures.length - 3;
    }
    for (var i = 0; i < this.pictures.length; i++) {
      this.pictures[i].setAttribute("style", "transform:translate(" + ((-40.6 * 3) * this.currentPhoto) + "%, 0px)");
    }
  }

  nextPhotoFunc() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
      this.setTimer();
    }
    if (this.currentPhoto < this.pictures.length - 3) {
      this.currentPhoto++;
    } else {
      this.currentPhoto = 0;
    }
    for (var i = 0; i < this.pictures.length; i++) {
      this.pictures[i].setAttribute("style", "transform:translate(" + ((-40.6 * 3) * this.currentPhoto) + "%, 0px)");
    }
  }

  setTimer() {
    this.timerInterval = setInterval(() => { this.nextPhotoFunc() }, this.carouselInterval * 1000);
  }

  goToDiscover(): void {
    this.router.navigate(['/website/descubre']);
  }
}
