import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private urlGet: string = "api/Equivalence/getequivalence";
  private urlList: string = "api/Equivalence/getequivalencelist";
  private urlCreate: string = "api/Equivalence/createequivalence";
  private urlUpdate: string = "api/Equivalence/updateequivalence";
  private urlDelete: string = "api/Equivalence/deleteequivalence";
  private urlDeleteMultiple: string = "api/Equivalence/deleteequivalencelist";
  constructor(private http: HttpClient, private config: ConfigurationService) { }

  getEquivalence(id): any {
    return this.http.get(environment.api + this.urlGet + '?equivalenceId=' + id);
  }

  getEquivalencesList(): any {
    return this.http.get(environment.api + this.urlList);
  }

  createEquivalence(tittle, description, unitPower, icon): any {
    return this.http.post(environment.api + this.urlCreate,
      {
        tittle,
        description,
        "unitPower": parseFloat((unitPower || 0).toString().replace(",", ".")),
        icon
      }
    )
  }

  modifyEquivalence(equivalence): any {
    equivalence.unitPower = parseFloat((equivalence.unitPower || 0).toString().replace(",", "."));
    return this.http.post(environment.api + this.urlUpdate,
      equivalence
    )
  }

  deleteEquivalence(id: string): any {
    return this.http.post(environment.api + this.urlDelete + "?equivalenceid=" + id,
      {}
    )
  }

  deleteEquivalences(ids: Array<string>): any {
    var deleteString = "";
    ids.forEach(id => { deleteString += (!deleteString ? "?" : "&") + "equivalencesId=" + id });
    return this.http.post(environment.api + this.urlDeleteMultiple + deleteString,
      {}
    )
  }
}
